// @flow

import { combineReducers } from 'redux'
import { i18nReducer as i18n } from 'react-redux-i18n'
import { reducer as form } from 'redux-form'
import { reducers } from 'common-docdok/src'
import PersistStore from 'common-docdok/src/configuration/persistStore'

// -- APPEND IMPORTS HERE --
import infoPanel from '../common/InfoPanel/reducers'
import alert from '../common/AlertDialog/reducers'
import billing from '../common/Billing/reducers'
import entityTable from '../common/EntityTable/reducers'
import conversationEditor from '../common/ConversationEditor/reducers'
import header from '../common/Header/reducers'
import security from '../common/Security/reducers'
import intro from '../common/Intro/reducers'
import selectedPatient from '../common/SelectedPatient/reducers'
import chat from '../common/Chat/reducers'
import createPatientButton from '../common/CreatePatientButton/reducers'
import createCaseButton from '../common/CreateCaseButton/reducers'
import addToStudyDialog from '../common/AddToStudyDialog/reducers'
import exportStudyDialog from '../common/ExportStudyDialog/reducers'
import createUserDialog from '../common/CreateUserDialog/reducers'
import updateUserDialog from '../common/UpdateUserDialog/reducers'
import uploadSurveyDialog from '../common/UploadSurveyDialog/reducers'
import eventHandlerDialog from '../common/EventDialog/reducers'
import scheduleSurveyDialog from '../common/ScheduleSurveyDialog/reducers'
import ambulancequeueitems from '../common/AmbulanceQueue/reducers'
import messages from '../routes/private/app/Messages/reducers'
import patients from '../routes/private/app/Patients/reducers'
import profilePage from '../routes/private/app/Profile/reducers'
import professionals from '../routes/private/app/Professionals/reducers'
import studies from '../routes/private/app/Studies/reducers'
import clinics from '../routes/private/app/Clinics/reducers'
import surveys from '../routes/private/app/Surveys/reducers/surveys'
import coreLayout from '../layouts/CoreLayout/reducers'
import catalog from '../routes/private/app/Catalog/reducers'

import location from './location'

export default function makeRootReducer(asyncReducers: Object) {
  return PersistStore.persistReducer(
    combineReducers({
      // -- APPEND REDUCERS HERE --
      ...asyncReducers,
      ...reducers,
      eventHandlerDialog,
      uploadSurveyDialog,
      scheduleSurveyDialog,
      updateUserDialog,
      createUserDialog,
      exportStudyDialog,
      addToStudyDialog,
      createPatientButton,
      createCaseButton,
      chat,
      selectedPatient,
      conversationEditor,
      clinics,
      entityTable,
      professionals,
      alert,
      profilePage,
      billing,
      security,
      messages,
      surveys,
      i18n,
      location,
      form,
      header,
      infoPanel,
      patients,
      intro,
      studies,
      coreLayout,
      ambulancequeueitems,
      catalog
    })
  )
}

export const injectReducer = (store: any, { key, reducer }: Object) => {
  if (store.asyncReducers[key]) return

  store.asyncReducers[key] = reducer // eslint-disable-line
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}
