import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import invariant from 'invariant'
import { Translate } from 'react-redux-i18n'
import Snackbar from '@mui/material/Snackbar'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import { green, amber } from '@mui/material/colors'
import WarningIcon from '@mui/icons-material/Warning'
import { withStyles } from '@mui/styles'
import { Alert } from '@mui/material'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  hint: InfoIcon
}

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  hint: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
})

type ContentPropsType = {
  classes: Object,
  className: string,
  message: any,
  onClose: Function,
  variant: string
}

function MySnackbarContent(props: ContentPropsType) {
  const { classes, className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]
  invariant(Icon, `no icon for ${variant} found`)
  return (
    <Alert
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      data-test={`info-panel-${variant}`}
      onClose={onClose}
      severity={variant}
      variant="filled"
      sx={{ width: '100%' }}
      {...other}
    >
      <span id="client-snackbar" className={classes.message}>
        {message}
      </span>
    </Alert>
  )
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent)

export const MessageTypes = {
  success: 'success',
  error: 'error',
  hint: 'hint'
}

const InfoPanel = (props) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [props.message?.key, props.count])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const { message } = props

  if (!message) return null

  const { key, params, type } = message

  let messageText
  if (key instanceof Error) {
    messageText = <Translate value="infos.errorOccurred" />
  } else {
    messageText = key ? <Translate value={key} {...params} /> : ''
  }

  return (
    <Snackbar
      key={props.message?.key}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'infopanel'
      }}
    >
      <div>
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={type}
          message={<span id="infopanel">{messageText}</span>}
        />
      </div>
    </Snackbar>
  )
}

export default InfoPanel
