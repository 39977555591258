import surveyTranslations from 'common-docdok/src/domain/survey/translations/surveyTranslations'
import studies from './studies'

export default {
  en: {
    table: {
      title: 'Surveys'
    },
    notifications: {
      surveyCompleted: '%{user} has completed the survey %{survey}',
      surveyNotCompleted:
        '%{user} has still not responded to the survey, %{survey} was sent on %{creationDate}',
      surveyInvitation: 'You have an invitation for the survey %{survey}',
      lastProtocolSurveySent:
        '%{user} has received the final survey in the survey protocol / %{survey}. ' +
        'Click here to see more details.',
      surveyReminder:
        'Please fill out the survey %{survey} which you received on %{creationDate}.'
    },
    addToStudy: { title: 'Add Patient To Study' },
    studyReport: {
      title: 'Export Study Data',
      fileName: 'study_export'
    },
    surveyCSV: {
      title: 'Export Survey Data',
      fileName: 'survey_export'
    },
    expireSurveyDesc: 'After the survey expires',
    expireSurveyDesc2: 'the survey will display in “Past” surveys in the survey groups view.',
    expireSurveyDesc3: 'The patient will still have the option to fill out and',
    expireSurveyDesc4: 'submit the survey.',
    uploadSurvey: {
      file: 'JSON File',
      name: 'Name',
      restrictedAccess: 'Restricted Access',
      allowedClinicIds: 'Clinics (Restricted Access)',
      remindAfterHours: 'Remind after hours',
      alertAfterHours: 'Alert after hours',
      themeId: 'Theme',
      expireAfter: 'Example 1h/1d/1m',
      canExpire: 'Survey Expiration',
      ...studies.en.scheduleStudySurvey,
      ...surveyTranslations.en.fields
    },
    actions: {
      actions: 'Actions',
      sendSurvey: {
        title: 'Send a Survey',
        notActive: 'Select only active surveys',
        success:
          'The survey(s) %{surveyNames} were sent to %{users} or are planned',
        schedulingHint:
          'The survey has been scheduled. After the survey is sent it will display in the list of sent surveys.'
      },
      downloadSurvey: {
        title: 'Download CSV',
        downloadJSON: 'Download Answers',
        downloadJSONDef: 'Download JSON'
      },
      activateSurvey: {
        title: 'Activate Survey',
        active: 'Select only not active surveys',
        success: '%{number} survey(s) were activated'
      },
      export: {
        title: 'Export answers',
        filename: 'SurveyAnswers'
      },
      importSurvey: {
        title: 'Import',
        success: '%{number} survey(s) were imported'
      },
      uploadSurvey: {
        title: 'Upload Survey',
        success: 'the Survey was uploaded successfully.'
      },
      deleteSurvey: {
        title: 'Delete Survey',
        success: 'the Survey was deleted successfully',
        confirmation: 'Are you sure you want to delete %{name} from surveys?'
      },
      launchSurvey: 'Launch Survey',
      addToStudy: {
        success: 'Patient successfully added to the Study'
      },
      viewResponses: 'View Responses',
      downloadResults: 'Download Results',
      updateSurvey: {
        title: 'Update Survey',
        success: 'The Survey was updated sucessfully'
      },
      scoreCharts: 'Show Score Chart',
      changeLanguage: {
        title: 'Warning',
        body:
          'Please note that changing the language, might override your ' +
          'answers and you will have to answer some of the questions again'
      }
    },
    sendSurvey: {
      title: 'Send Surveys',
      help: 'Select the patients to which the surveys should be send.'
    },
    viewResponses: {
      title: 'View Responses'
    },
    downloadResults: {
      title: 'Download Results',
      error: 'Could not extract file'
    },
    surveyParticipation: {
      completed: 'You already completed this survey',
      moreInfo: 'More info'
    },
    timepoint: {
      year: 'y',
      month: 'm',
      week: 'w',
      day: 'd'
    },
    scoreCharts: {
      chartType: 'Chart type',
      nodata: 'No score data available for this survey',
      creationDate: 'Creation date',
      score: 'Score'
    },
    surveyTheme: {
      themeLink: 'Themes',
      updateTheme: 'Edit',
      delete: 'Delete',
      deleteConfirmation:
        'Are you sure? Clicking "OK" will permanently delete the theme.' +
        ' Any surveys using this theme will revert to the default theme.',
      upload: 'Upload theme',
      feedback: {
        create: {
          success: 'File uploaded successfully',
          error: 'An error has occurred',
          fileError:
            'File not uploaded, ”theme name” is not specified in the JSON file'
        }
      }
    },
    surveyGroup: {
      themeLink: 'Modules',
      deleteConfirmation:
        'Are you sure? Clicking "OK" will permanently delete the %{name} module.',
      upload: 'Create module',
      feedback: {
        create: {
          success: 'Module saved successfully',
          error: 'An error has occurred'
        }
      }
    }
  },
  de: {
    table: {
      title: 'Umfragen'
    },
    notifications: {
      surveyCompleted: '%{user} hat die Umfrage %{survey} abgeschlossen',
      surveyNotCompleted:
        '%{user} hat noch immer nicht auf die Umfrage reagiert. ' +
        'Die Umfrage  %{survey} wurde am %{creationDate} versendet.',
      surveyInvitation: 'Sie haben eine Einladung für die Umfrage %{survey}',
      lastProtocolSurveySent:
        '%{user} hat die letzte Umfrage im Umfrageprotokoll / %{survey} erhalten. ' +
        'Klicken Sie hier, um weitere Informationen zu sehen.',
      surveyReminder:
        'Bitte füllen Sie die Umfrage %{survey} aus, welche Sie am %{creationDate} erhalten haben.'
    },
    addToStudy: { title: 'Patient einer Studie hinzufügen' },
    studyReport: {
      title: 'Studien Daten Exportieren',
      fileName: 'studien_export'
    },
    expireSurveyDesc: 'After the survey expires',
    expireSurveyDesc2: 'the survey will display in “Past” surveys in the survey groups view.',
    expireSurveyDesc3: 'The patient will still have the option to fill out and',
    expireSurveyDesc4: 'submit the survey.',
    uploadSurvey: {
      file: 'JSON Datei',
      name: 'Name',
      restrictedAccess: 'Eingeschränkter Zugriff',
      allowedClinicIds: 'Kiniken (Eingeschränkter Zugriff)',
      remindAfterHours: 'Wiederholen nach',
      alertAfterHours: 'Benachrichtigen nach',
      themeId: 'Themenbereiche',
      expireAfter: 'Example 1h/1d/1m',
      canExpire: 'Survey Expiration',
      ...studies.de.scheduleStudySurvey,
      ...surveyTranslations.de.fields
    },

    actions: {
      actions: 'Aktionen',
      sendSurvey: {
        title: 'Versende eine Umfrage',
        notActive: 'Selektieren Sie nur aktive Umfragen',
        success:
          'Die Umfrage(n) %{surveyNames} wurden an %{users} geschicket oder geplant',
        schedulingHint:
          'Die Umfrage wurde zum Versenden eingeplant.' +
          ' Sobald diese an den Patienten übermittelt wurde, wird sie in der Liste erscheinen'
      },
      downloadSurvey: {
        title: 'CSV-Datei herunterladen',
        downloadJSON: 'Download Answers',
        downloadJSONDef: 'Download JSON'
      },
      activateSurvey: {
        title: 'Umfrage aktivieren',
        active: 'Selektrieren Sie nur nicht aktive Umfragen',
        success: '%{number} Umfrage(n) wurden aktiviert'
      },
      uploadSurvey: {
        title: 'Umfrage hochladen',
        success: 'Die Umfrage wurde erfolgreich hochgeladen.'
      },
      deleteSurvey: {
        title: 'Umfrage Löschen',
        success: 'Die Umfrage wurde erfolgreich gelöscht',
        confirmation:
          'Sind Sie sicher, dass Sie %{name} aus den Umfragen löschen möchten?'
      },
      export: {
        title: 'Antworten exportieren',
        filename: 'UmfrageAnworten'
      },
      importSurvey: {
        title: 'Import',
        success: '%{number} Umfragen(n) wurden importiert'
      },
      launchSurvey: 'Umfrage starten',
      addToStudy: {
        success: 'Patient wurde erfolgreich der Studie hinzugefügt'
      },
      viewResponses: 'Antworten anzeigen',
      downloadResults: 'Ergebnisse herunterladen',
      updateSurvey: {
        title: 'Umfrage Updaten',
        success: 'Die Umfrage wurde erfolgreich aktualisiert'
      },
      scoreCharts: 'Charts anzeigen',
      changeLanguage: {
        title: 'Warnung',
        body:
          'Bitte beachten Sie, dass eine Änderung der Sprache Ihre Antworten' +
          ' möglicherweise überschreiben kann und Sie einige der Fragen erneut beantworten müssen'
      }
    },
    sendSurvey: {
      title: 'Umfrage versenden',
      help:
        'Selektieren Sie die Patienten an welche Sie die Umfrage schicken möchten'
    },
    viewResponses: {
      title: 'Antworten Anzeigen'
    },
    downloadResults: {
      title: 'Ergebnisse herunterladen',
      error: 'Datei konnte nicht extrahiert werden'
    },
    surveyParticipation: {
      completed: 'Sie haben diese Umfrage bereits ausgefüllt',
      moreInfo: 'Mehr Infos'
    },
    timepoint: {
      year: 'J',
      month: 'M',
      week: 'W',
      day: 'T'
    },
    scoreCharts: {
      chartType: 'Darstellung',
      nodata: 'Für diese Umfrage sind keine Score Daten vorhanden',
      creationDate: 'Versanddatum',
      score: 'Score'
    },
    surveyTheme: {
      themeLink: 'Themenbereiche',
      updateTheme: 'Bearbeiten',
      delete: 'Löschen',
      deleteConfirmation:
        'Sind Sie sicher? Wenn Sie auf "OK" klicken, wird der Themenbereich endgültig gelöscht.' +
        ' Alle Umfragen, die diesen Themenbereich verwenden,' +
        ' werden auf den Standardthemenbereich zurückgesetzt.',
      upload: 'Themenbereich hochladen',
      feedback: {
        create: {
          success: 'Datei erfolgreich hochgeladen',
          error: 'Es ist ein Fehler aufgetreten',
          fileError:
            'Datei nicht hochgeladen, da "theme name" nicht in der JSON-Datei angegeben ist'
        }
      }
    },
    surveyGroup: {
      themeLink: 'Modules',
      deleteConfirmation:
        'Are you sure? Clicking "OK" will permanently delete the {{name}} module.',
      upload: 'Create module',
      feedback: {
        create: {
          success: 'Module saved successfully',
          error: 'An error has occurred'
        }
      }
    }
  }
}
