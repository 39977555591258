/**
 * @flow
 */
import { type StudyDtoType } from '../types/studyDto'
import { type StudyParticipantDtoType } from '../types/studyParticipantDto'
import { type SurveyDefinitionDtoType } from '../types/surveyDefinitionDto'
import { type SurveyDefinitionSummaryType } from '../types/surveyDefinitionSummary'
import { type SurveyParticipationDtoType } from '../types/surveyParticipationDto'
import { type ConnectedDeviceDtoType } from '../types/connectedDeviceDto'
import { type StudySurveyDtoType } from '../types/studySurveyDto'
import { type SurveyScheduleSpecType } from '../types/surveyScheduleSpec'
import { type SurveyQuestionStatisticType } from '../types/surveyQuestionStatistic'
import { type DeviceDefinitionDtoType } from '../types/deviceDefinitionDto'
import { type StudyDoctorDtoType } from '../types/studyDoctorDto'
import deviceDataRange from '../types/deviceDataRange'

export const surveyActionTypes = {
  //      -- APPEND ACTION TYPES HERE --
  DELETE_STUDY_REQUESTED: 'DELETE_STUDY_REQUESTED',
  DELETE_STUDY_SUCCEEDED: 'DELETE_STUDY_SUCCEEDED',
  DELETE_STUDY_FAILED: 'DELETE_STUDY_FAILED',
  SAVE_SURVEY_ANSWERS_REQUESTED: 'SAVE_SURVEY_ANSWERS_REQUESTED',
  SAVE_SURVEY_ANSWERS_SUCCEEDED: 'SAVE_SURVEY_ANSWERS_SUCCEEDED',
  SAVE_SURVEY_ANSWERS_FAILED: 'SAVE_SURVEY_ANSWERS_FAILED',
  GET_SURVEY_PARTICIPATION_REQUESTED: 'GET_SURVEY_PARTICIPATION_REQUESTED',
  GET_SURVEY_PARTICIPATION_SUCCEEDED: 'GET_SURVEY_PARTICIPATION_SUCCEEDED',
  GET_SURVEY_PARTICIPATION_FAILED: 'GET_SURVEY_PARTICIPATION_FAILED',
  GET_SURVEY_SPEC_REQUESTED: 'GET_SURVEY_SPEC_REQUESTED',
  GET_SURVEY_SPEC_SUCCEEDED: 'GET_SURVEY_SPEC_SUCCEEDED',
  GET_SURVEY_SPEC_FAILED: 'GET_SURVEY_SPEC_FAILED',

  ACTIVATE_SURVEY_DEFINITIONS_REQUESTED: 'ACTIVATE_SURVEY_DEFINITIONS_REQUESTED',
  ACTIVATE_SURVEY_DEFINITIONS_SUCCEEDED: 'ACTIVATE_SURVEY_DEFINITIONS_SUCCEEDED',
  ACTIVATE_SURVEY_DEFINITIONS_FAILED: 'ACTIVATE_SURVEY_DEFINITIONS_FAILED',

  ADD_INTEGRATION_REQUESTED: 'ADD_INTEGRATION_REQUESTED',
  ADD_INTEGRATION_SUCCEEDED: 'ADD_INTEGRATION_SUCCEEDED',
  ADD_INTEGRATION_FAILED: 'ADD_INTEGRATION_FAILED',

  ADD_STUDY_PARTICIPANT_REQUESTED: 'ADD_STUDY_PARTICIPANT_REQUESTED',
  ADD_STUDY_PARTICIPANT_SUCCEEDED: 'ADD_STUDY_PARTICIPANT_SUCCEEDED',
  ADD_STUDY_PARTICIPANT_FAILED: 'ADD_STUDY_PARTICIPANT_FAILED',

  GET_SURVEY_DEFINITIONS_REQUESTED: 'GET_SURVEY_DEFINITIONS_REQUESTED',
  GET_SURVEY_DEFINITIONS_SUCCEEDED: 'GET_SURVEY_DEFINITIONS_SUCCEEDED',
  GET_SURVEY_DEFINITIONS_FAILED: 'GET_SURVEY_DEFINITIONS_FAILED',

  GET_SURVEY_DEFINITIONS_BY_ID_REQUESTED: 'GET_SURVEY_DEFINITIONS_BY_ID_REQUESTED',
  GET_SURVEY_DEFINITIONS_BY_ID_SUCCEEDED: 'GET_SURVEY_DEFINITIONS_BY_ID_SUCCEEDED',
  GET_SURVEY_DEFINITIONS_BY_ID_FAILED: 'GET_SURVEY_DEFINITIONS_BY_ID_FAILED',

  GET_ALL_STUDIES_REQUESTED: 'GET_ALL_STUDIES_REQUESTED',
  GET_ALL_STUDIES_SUCCEEDED: 'GET_ALL_STUDIES_SUCCEEDED',
  GET_ALL_STUDIES_FAILED: 'GET_ALL_STUDIES_FAILED',

  GET_DEVICE_DATA_REQUESTED: 'GET_DEVICE_DATA_REQUESTED',
  GET_DEVICE_DATA_SUCCEEDED: 'GET_DEVICE_DATA_SUCCEEDED',
  GET_DEVICE_DATA_FAILED: 'GET_DEVICE_DATA_FAILED',

  GET_DEVICE_STATE_REQUESTED: 'GET_DEVICE_STATE_REQUESTED',
  GET_DEVICE_STATE_SUCCEEDED: 'GET_DEVICE_STATE_SUCCEEDED',
  GET_DEVICE_STATE_FAILED: 'GET_DEVICE_STATE_FAILED',

  GET_MY_SURVEYS_REQUESTED: 'GET_MY_SURVEYS_REQUESTED',
  GET_MY_SURVEYS_SUCCEEDED: 'GET_MY_SURVEYS_SUCCEEDED',
  GET_MY_SURVEYS_FAILED: 'GET_MY_SURVEYS_FAILED',

  GET_STUDY_PARTICIPATIONS_FOR_PATIENT_REQUESTED: 'GET_STUDY_PARTICIPATIONS_FOR_PATIENT_REQUESTED',
  GET_STUDY_PARTICIPATIONS_FOR_PATIENT_SUCCEEDED: 'GET_STUDY_PARTICIPATIONS_FOR_PATIENT_SUCCEEDED',
  GET_STUDY_PARTICIPATIONS_FOR_PATIENT_FAILED: 'GET_STUDY_PARTICIPATIONS_FOR_PATIENT_FAILED',

  GET_SURVEY_PARTICIPATIONS_REQUESTED: 'GET_SURVEY_PARTICIPATIONS_REQUESTED',
  GET_SURVEY_PARTICIPATIONS_SUCCEEDED: 'GET_SURVEY_PARTICIPATIONS_SUCCEEDED',
  GET_SURVEY_PARTICIPATIONS_FAILED: 'GET_SURVEY_PARTICIPATIONS_FAILED',

  GET_SURVEY_RESPONSES_REQUESTED: 'GET_SURVEY_RESPONSES_REQUESTED',
  GET_SURVEY_RESPONSES_SUCCEEDED: 'GET_SURVEY_RESPONSES_SUCCEEDED',
  GET_SURVEY_RESPONSES_FAILED: 'GET_SURVEY_RESPONSES_FAILED',

  SEND_SURVEYS_REQUESTED: 'SEND_SURVEYS_REQUESTED',
  SEND_SURVEYS_SUCCEEDED: 'SEND_SURVEYS_SUCCEEDED',
  SEND_SURVEYS_FAILED: 'SEND_SURVEYS_FAILED',

  CREATE_STUDY_REQUESTED: 'CREATE_STUDY_REQUESTED',
  CREATE_STUDY_SUCCEEDED: 'CREATE_STUDY_SUCCEEDED',
  CREATE_STUDY_FAILED: 'CREATE_STUDY_FAILED',

  GET_STUDY_REQUESTED: 'GET_STUDY_REQUESTED',
  GET_STUDY_SUCCEEDED: 'GET_STUDY_SUCCEEDED',
  GET_STUDY_FAILED: 'GET_STUDY_FAILED',

  ADD_SURVEY_TO_STUDY_REQUESTED: 'ADD_SURVEY_TO_STUDY_REQUESTED',
  ADD_SURVEY_TO_STUDY_SUCCEEDED: 'ADD_SURVEY_TO_STUDY_SUCCEEDED',
  ADD_SURVEY_TO_STUDY_FAILED: 'ADD_SURVEY_TO_STUDY_FAILED',

  REMOVE_STUDY_SURVEY_REQUESTED: 'REMOVE_STUDY_SURVEY_REQUESTED',
  REMOVE_STUDY_SURVEY_SUCCEEDED: 'REMOVE_STUDY_SURVEY_SUCCEEDED',
  REMOVE_STUDY_SURVEY_FAILED: 'REMOVE_STUDY_SURVEY_FAILED',

  REMOVE_STUDY_PHYSICIAN_REQUESTED: 'REMOVE_STUDY_PHYSICIAN_REQUESTED',
  REMOVE_STUDY_PHYSICIAN_SUCCEEDED: 'REMOVE_STUDY_PHYSICIAN_SUCCEEDED',
  REMOVE_STUDY_PHYSICIAN_FAILED: 'REMOVE_STUDY_PHYSICIAN_FAILED',

  DELETE_SURVEY_REQUESTED: 'DELETE_SURVEY_REQUESTED',
  DELETE_SURVEY_SUCCEEDED: 'DELETE_SURVEY_SUCCEEDED',
  DELETE_SURVEY_FAILED: 'DELETE_SURVEY_FAILED',

  UPDATE_STUDY_REQUESTED: 'UPDATE_STUDY_REQUESTED',
  UPDATE_STUDY_SUCCEEDED: 'UPDATE_STUDY_SUCCEEDED',
  UPDATE_STUDY_FAILED: 'UPDATE_STUDY_FAILED',

  UPDATE_STUDY_SURVEY_REQUESTED: 'UPDATE_STUDY_SURVEY_REQUESTED',
  UPDATE_STUDY_SURVEY_SUCCEEDED: 'UPDATE_STUDY_SURVEY_SUCCEEDED',
  UPDATE_STUDY_SURVEY_FAILED: 'UPDATE_STUDY_SURVEY_FAILED',

  GET_SURVEY_STATISTIC_REQUESTED: 'GET_SURVEY_STATISTIC_REQUESTED',
  GET_SURVEY_STATISTIC_SUCCEEDED: 'GET_SURVEY_STATISTIC_SUCCEEDED',
  GET_SURVEY_STATISTIC_FAILED: 'GET_SURVEY_STATISTIC_FAILED',

  GET_SURVEY_DEFINITIONS_BY_PATIENT_REQUESTED: 'GET_SURVEY_DEFINITIONS_BY_PATIENT_REQUESTED',
  GET_SURVEY_DEFINITIONS_BY_PATIENT_SUCCEEDED: 'GET_SURVEY_DEFINITIONS_BY_PATIENT_SUCCEEDED',
  GET_SURVEY_DEFINITIONS_BY_PATIENT_FAILED: 'GET_SURVEY_DEFINTIONS_BY_PATIENT_FAILED',

  ADD_STUDY_PHYSICIAN_REQUESTED: 'ADD_STUDY_PHYSICIAN_REQUESTED',
  ADD_STUDY_PHYSICIAN_SUCCEEDED: 'ADD_STUDY_PHYSICIAN_SUCCEEDED',
  ADD_STUDY_PHYSICIAN_FAILED: 'ADD_STUDY_PHYSICIAN_FAILED',

  GET_DEVICE_DEFINITIONS_REQUESTED: 'GET_DEVICE_DEFINITIONS_REQUESTED',
  GET_DEVICE_DEFINITIONS_SUCCEEDED: 'GET_DEVICE_DEFINITIONS_SUCCEEDED',
  GET_DEVICE_DEFINITIONS_FAILED: 'GET_DEVICE_DEFINITIONS_FAILED',

  UPLOAD_LSS_FILE_REQUESTED: 'UPLOAD_LSS_FILE_REQUESTED',
  UPLOAD_LSS_FILE_SUCCEEDED: 'UPLOAD_LSS_FILE_SUCCEEDED',
  UPLOAD_LSS_FILE_FAILED: 'UPLOAD_LSS_FILE_FAILED',

  UPDATE_SURVEY_DEFINITION_REQUESTED: 'UPDATE_SURVEY_DEFINITION_REQUESTED',
  UPDATE_SURVEY_DEFINITION_SUCCEEDED: 'UPDATE_SURVEY_DEFINITION_SUCCEEDED',
  UPDATE_SURVEY_DEFINITION_FAILED: 'UPDATE_SURVEY_DEFINITION_FAILED',

  GET_SURVEY_PARTICIPATIONS_BY_PATIENT_REQUESTED: 'GET_SURVEY_PARTICIPATIONS_BY_PATIENT_REQUESTED',
  GET_SURVEY_PARTICIPATIONS_BY_PATIENT_SUCCEEDED: 'GET_SURVEY_PARTICIPATIONS_BY_PATIENT_SUCCEEDED',
  GET_SURVEY_PARTICIPATIONS_BY_PATIENT_FAILED: 'GET_SURVEY_PARTICIPATIONS_BY_PATIENT_FAILED',

  RESET_PARTICIPATION_DATA: 'RESET_PARTICIPATION_DATA',

  GET_SURVEY_THEMES_REQUESTED: 'GET_SURVEY_THEMES_REQUESTED',
  GET_SURVEY_THEMES_SUCCEEDED: 'GET_SURVEY_THEMES_SUCCEEDED',
  GET_SURVEY_THEMES_FAILED: 'GET_SURVEY_THEMES_FAILED',

  UPLOAD_SURVEY_THEMES_REQUESTED: 'UPLOAD_SURVEY_THEMES_REQUESTED',
  UPLOAD_SURVEY_THEMES_SUCCEEDED: 'UPLOAD_SURVEY_THEMES_SUCCEEDED',
  UPLOAD_SURVEY_THEMES_FAILED: 'UPLOAD_SURVEY_THEMES_FAILED',

  UPDATE_SURVEY_THEMES_REQUESTED: 'UPDATE_SURVEY_THEMES_REQUESTED',
  UPDATE_SURVEY_THEMES_SUCCEEDED: 'UPDATE_SURVEY_THEMES_SUCCEEDED',
  UPDATE_SURVEY_THEMES_FAILED: 'UPDATE_SURVEY_THEMES_FAILED',

  DELETE_SURVEY_THEMES_REQUESTED: 'UPDATE_SURVEY_THEMES_REQUESTED',
  DELETE_SURVEY_THEMES_SUCCEEDED: 'UPDATE_SURVEY_THEMES_SUCCEEDED',
  DELETE_SURVEY_THEMES_FAILED: 'UPDATE_SURVEY_THEMES_FAILED',

  UPLOAD_SURVEY_THEME_FILE_REQUESTED: 'UPLOAD_SURVEY_THEME_FILE_REQUESTED',
  UPLOAD_SURVEY_THEME_FILE_SUCCEEDED: 'UPLOAD_SURVEY_THEME_FILE_SUCCEEDED',
  UPLOAD_SURVEY_THEME_FILE_FAILED: 'UPLOAD_SURVEY_THEME_FILE_FAILED',

  DELETE_SURVEY_THEME_REQUESTED: 'DELETE_SURVEY_THEME_REQUESTED',
  DELETE_SURVEY_THEME_SUCCEEDED: 'DELETE_SURVEY_THEME_SUCCEEDED',
  DELETE_SURVEY_THEME_FAILED: 'DELETE_SURVEY_THEME_FAILED',

  CREATE_SURVEY_GROUP_REQUESTED: 'CREATE_SURVEY_GROUP_REQUESTED',
  CREATE_SURVEY_GROUP_SUCCEEDED: 'CREATE_SURVEY_GROUP_SUCCEEDED',
  CREATE_SURVEY_GROUP_FAILED: 'CREATE_SURVEY_GROUP_FAILED',

  UPDATE_SURVEY_GROUP_REQUESTED: 'UPDATE_SURVEY_GROUP_REQUESTED',
  UPDATE_SURVEY_GROUP_SUCCEEDED: 'UPDATE_SURVEY_GROUP_SUCCEEDED',
  UPDATE_SURVEY_GROUP_FAILED: 'UPDATE_SURVEY_GROUP_FAILED',

  DELETE_SURVEY_GROUP_REQUESTED: 'DELETE_SURVEY_GROUP_REQUESTED',
  DELETE_SURVEY_GROUP_SUCCEEDED: 'DELETE_SURVEY_GROUP_SUCCEEDED',
  DELETE_SURVEY_GROUP_FAILED: 'DELETE_SURVEY_GROUP_FAILED'
}

export type SurveyActionType = $Keys<typeof surveyActionTypes>

type SendSurveyRequestedType = {
  surveyId: number,
  userRef: string,
  patientUuid: string,
  scheduling?: ?SurveyScheduleSpecType
}

export const surveyActions = {
  //          -- APPEND ACTIONS HERE --
  deleteStudyRequested(studyId: number) {
    return { type: surveyActionTypes.DELETE_STUDY_REQUESTED, payload: studyId }
  },
  deleteStudySucceeded() {
    return {
      type: surveyActionTypes.DELETE_STUDY_SUCCEEDED
    }
  },
  deleteStudyFailed(error: Error) {
    return {
      type: surveyActionTypes.DELETE_STUDY_FAILED,
      payload: { error }
    }
  },

  saveSurveyAnswersRequested(surveyParticipationId: number, answers: Object, complete: boolean) {
    return {
      type: surveyActionTypes.SAVE_SURVEY_ANSWERS_REQUESTED,
      payload: {
        surveyParticipationResult: {
          id: surveyParticipationId,
          answers
        },
        complete
      }
    }
  },
  saveSurveyAnswersSucceeded() {
    return {
      type: surveyActionTypes.SAVE_SURVEY_ANSWERS_SUCCEEDED
    }
  },
  saveSurveyAnswersFailed(error: Error) {
    return {
      type: surveyActionTypes.SAVE_SURVEY_ANSWERS_FAILED,
      payload: { error }
    }
  },

  getSurveyParticipationRequested(participationId: number) {
    return { type: surveyActionTypes.GET_SURVEY_PARTICIPATION_REQUESTED, payload: participationId }
  },
  getSurveyParticipationSucceeded(payload: SurveyParticipationDtoType) {
    return {
      type: surveyActionTypes.GET_SURVEY_PARTICIPATION_SUCCEEDED,
      payload
    }
  },
  getSurveyParticipationFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_SURVEY_PARTICIPATION_FAILED,
      payload: { error }
    }
  },

  getSurveySpecRequested(participationId: number) {
    return {
      type: surveyActionTypes.GET_SURVEY_SPEC_REQUESTED,
      payload: participationId
    }
  },
  getSurveySpecSucceeded(participationId: number, spec: Object) {
    return {
      type: surveyActionTypes.GET_SURVEY_SPEC_SUCCEEDED,
      payload: {
        participationId,
        spec
      }
    }
  },
  getSurveySpecFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_SURVEY_SPEC_FAILED,
      payload: { error }
    }
  },

  activateSurveyDefinitionsRequested(ids: Array<number>) {
    return {
      type: surveyActionTypes.ACTIVATE_SURVEY_DEFINITIONS_REQUESTED,
      payload: ids
    }
  },
  activateSurveyDefinitionsSucceeded(ids: Array<number>) {
    return {
      type: surveyActionTypes.ACTIVATE_SURVEY_DEFINITIONS_SUCCEEDED,
      payload: ids
    }
  },
  activateSurveyDefinitionsFailed(error: Error) {
    return {
      type: surveyActionTypes.ACTIVATE_SURVEY_DEFINITIONS_REQUESTED,
      payload: { error }
    }
  },

  addIntegrationRequested(patientUuid: string, deviceName: string) {
    return {
      type: surveyActionTypes.ADD_INTEGRATION_REQUESTED,
      payload: {
        patientUuid,
        deviceName
      }
    }
  },
  addIntegrationSucceeded(patientUuid: string, url: string) {
    return {
      type: surveyActionTypes.ADD_INTEGRATION_SUCCEEDED,
      payload: {
        patientUuid,
        url
      }
    }
  },
  addIntegrationFailed(error: Error) {
    return {
      type: surveyActionTypes.ADD_INTEGRATION_FAILED,
      payload: { error }
    }
  },
  addStudyParticipantRequested(participant: StudyParticipantDtoType) {
    return {
      type: surveyActionTypes.ADD_STUDY_PARTICIPANT_REQUESTED,
      payload: participant
    }
  },
  addStudyParticipantSuceeded(participant: StudyParticipantDtoType) {
    return {
      type: surveyActionTypes.ADD_STUDY_PARTICIPANT_SUCCEEDED,
      payload: participant
    }
  },
  addStudyParticipantFailed(error: Error) {
    return {
      type: surveyActionTypes.ADD_STUDY_PARTICIPANT_FAILED,
      payload: { error }
    }
  },
  getSurveyDefinitionsRequested(payload: any) {
    return {
      type: surveyActionTypes.GET_SURVEY_DEFINITIONS_REQUESTED,
      payload
    }
  },
  getSurveyDefinitionsSucceeded(surveyDefinitions: Array<SurveyDefinitionSummaryType>) {
    return {
      type: surveyActionTypes.GET_SURVEY_DEFINITIONS_SUCCEEDED,
      payload: surveyDefinitions
    }
  },
  getSurveyDefinitionsFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_SURVEY_DEFINITIONS_FAILED,
      payload: { error }
    }
  },
  getSurveyDefinitionsByIdRequested(ids: Array<number>) {
    return {
      type: surveyActionTypes.GET_SURVEY_DEFINITIONS_BY_ID_REQUESTED,
      payload: ids
    }
  },
  getSurveyDefinitionsByIdSucceeded(surveyDefinitions: Array<SurveyDefinitionSummaryType>) {
    return {
      type: surveyActionTypes.GET_SURVEY_DEFINITIONS_BY_ID_SUCCEEDED,
      payload: surveyDefinitions
    }
  },
  getSurveyDefinitionsByIdFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_SURVEY_DEFINITIONS_BY_ID_FAILED,
      payload: { error }
    }
  },
  getAllStudiesRequested() {
    return {
      type: surveyActionTypes.GET_ALL_STUDIES_REQUESTED
    }
  },
  getAllStudiesSucceeded(payload: Array<StudyDtoType>) {
    return {
      type: surveyActionTypes.GET_ALL_STUDIES_SUCCEEDED,
      payload
    }
  },
  getAllStudiesFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_ALL_STUDIES_FAILED,
      payload: { error }
    }
  },
  getDeviceDataRequested(
    patientUuid: string,
    deviceName: string,
    startDate: Date = new Date(Date.now()),
    range: string = deviceDataRange.DAILY
  ) {
    return {
      type: surveyActionTypes.GET_DEVICE_DATA_REQUESTED,
      payload: {
        patientUuid,
        deviceName,
        startDate,
        range
      }
    }
  },
  getDeviceDataSucceeded(patientUuid: string, deviceName: string, data: Array<Object>) {
    return {
      type: surveyActionTypes.GET_DEVICE_DATA_SUCCEEDED,
      payload: {
        patientUuid,
        deviceName,
        data
      }
    }
  },
  getDeviceDataFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_DEVICE_DATA_FAILED,
      payload: { error }
    }
  },
  getDeviceStateRequested(patientUuid: string) {
    return {
      type: surveyActionTypes.GET_DEVICE_STATE_REQUESTED,
      payload: {
        patientUuid
      }
    }
  },
  getDeviceStateSucceeded(patientUuid: string, deviceStates: Array<ConnectedDeviceDtoType>) {
    return {
      type: surveyActionTypes.GET_DEVICE_STATE_SUCCEEDED,
      payload: {
        patientUuid,
        deviceStates
      }
    }
  },
  getDeviceStateFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_DEVICE_STATE_FAILED,
      payload: { error }
    }
  },
  getMySurveysRequested() {
    return {
      type: surveyActionTypes.GET_MY_SURVEYS_REQUESTED
    }
  },
  getMySurveysSucceeded(payload: Array<Object>) {
    return {
      type: surveyActionTypes.GET_MY_SURVEYS_SUCCEEDED,
      payload
    }
  },
  getMySurveysFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_MY_SURVEYS_FAILED,
      payload: { error }
    }
  },
  getStudyParticipationsForPatientRequested(patientUuid: string) {
    return {
      type: surveyActionTypes.GET_STUDY_PARTICIPATIONS_FOR_PATIENT_REQUESTED,
      payload: patientUuid
    }
  },
  getStudyParticipationsForPatientSucceeded(
    patientUuid: string,
    participations: Array<StudyParticipantDtoType & StudyDtoType>
  ) {
    return {
      type: surveyActionTypes.GET_STUDY_PARTICIPATIONS_FOR_PATIENT_SUCCEEDED,
      payload: { patientUuid, participations }
    }
  },
  getStudyParticipationsForPatientFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_STUDY_PARTICIPATIONS_FOR_PATIENT_FAILED,
      payload: { error }
    }
  },
  getSurveyParticipationsRequested(surveyId: number) {
    return {
      type: surveyActionTypes.GET_SURVEY_PARTICIPATIONS_REQUESTED,
      payload: surveyId
    }
  },
  getSurveyParticipationsSucceeded(participations: Array<SurveyParticipationDtoType>) {
    return {
      type: surveyActionTypes.GET_SURVEY_PARTICIPATIONS_SUCCEEDED,
      payload: participations
    }
  },
  getSurveyParticipationsFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_SURVEY_PARTICIPATIONS_FAILED,
      payload: { error }
    }
  },

  getSurveyResponsesRequested(participationId: number) {
    return {
      type: surveyActionTypes.GET_SURVEY_RESPONSES_REQUESTED,
      payload: participationId
    }
  },
  getSurveyResponsesSucceeded(participationId: number, responses: Object) {
    return {
      type: surveyActionTypes.GET_SURVEY_RESPONSES_SUCCEEDED,
      payload: {
        participationId,
        responses
      }
    }
  },
  getSurveyResponsesFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_SURVEY_RESPONSES_FAILED,
      payload: { error }
    }
  },
  sendSurveysRequested(participants: Array<SendSurveyRequestedType>) {
    return {
      type: surveyActionTypes.SEND_SURVEYS_REQUESTED,
      payload: participants
    }
  },
  sendSurveysSucceeded() {
    return {
      type: surveyActionTypes.SEND_SURVEYS_SUCCEEDED
    }
  },
  sendSurveysFailed(error: Error) {
    return {
      type: surveyActionTypes.SEND_SURVEYS_FAILED,
      payload: { error }
    }
  },
  createStudyRequested(name: string, key: string) {
    return {
      type: surveyActionTypes.CREATE_STUDY_REQUESTED,
      payload: { name, key }
    }
  },
  createStudySucceeded(study: StudyDtoType) {
    return {
      type: surveyActionTypes.CREATE_STUDY_SUCCEEDED,
      payload: study
    }
  },
  createStudyFailed(error: Error) {
    return {
      type: surveyActionTypes.CREATE_STUDY_FAILED,
      payload: { error }
    }
  },
  getStudyRequested(studyId: number) {
    return {
      type: surveyActionTypes.GET_STUDY_REQUESTED,
      payload: studyId
    }
  },
  getStudySucceeded(study: StudyDtoType) {
    return {
      type: surveyActionTypes.GET_STUDY_SUCCEEDED,
      payload: {
        study
      }
    }
  },
  getStudyFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_STUDY_FAILED,
      payload: {
        error
      }
    }
  },

  addSurveyToStudyRequested(studySurvey: StudySurveyDtoType) {
    return {
      type: surveyActionTypes.ADD_SURVEY_TO_STUDY_REQUESTED,
      payload: studySurvey
    }
  },
  addSurveyToStudySucceeded(studySurvey: StudySurveyDtoType) {
    return {
      type: surveyActionTypes.ADD_SURVEY_TO_STUDY_SUCCEEDED,
      payload: studySurvey
    }
  },
  addSurveyToStudyFailed(error: Error) {
    return {
      type: surveyActionTypes.ADD_SURVEY_TO_STUDY_FAILED,
      payload: {
        error
      }
    }
  },

  removeStudySurveyRequested(studyId: number, surveyId: number) {
    return {
      type: surveyActionTypes.REMOVE_STUDY_SURVEY_REQUESTED,
      payload: { studyId, surveyId }
    }
  },
  removeStudySurveySucceeded(studyId: number, surveyId: number) {
    return {
      type: surveyActionTypes.REMOVE_STUDY_SURVEY_SUCCEEDED,
      payload: { studyId, surveyId }
    }
  },
  removeStudySurveyFailed(error: Error) {
    return {
      type: surveyActionTypes.REMOVE_STUDY_SURVEY_FAILED,
      payload: {
        error
      }
    }
  },
  deleteSurveyRequested(surveyId: number) {
    return {
      type: surveyActionTypes.DELETE_SURVEY_REQUESTED,
      payload: surveyId
    }
  },
  deleteSurveySucceeded(surveyId: number) {
    return {
      type: surveyActionTypes.DELETE_SURVEY_SUCCEEDED,
      payload: surveyId
    }
  },
  deleteSurveyFailed(error: Error) {
    return {
      type: surveyActionTypes.DELETE_SURVEY_FAILED,
      payload: {
        error
      }
    }
  },
  addStudyPhysicianRequested(studyId: number, physicianId: number) {
    return {
      type: surveyActionTypes.ADD_STUDY_PHYSICIAN_REQUESTED,
      payload: { studyId, physicianId }
    }
  },
  addStudyPhysicianSucceeded(studyId: number, physicianId: number) {
    return {
      type: surveyActionTypes.ADD_STUDY_PHYSICIAN_SUCCEEDED,
      payload: { studyId, physicianId }
    }
  },
  addStudyPhysicianFailed(error: Error) {
    return {
      type: surveyActionTypes.ADD_STUDY_PHYSICIAN_FAILED,
      payload: {
        error
      }
    }
  },

  removeStudyPhysicianRequested(studyDoctor: StudyDoctorDtoType) {
    return {
      type: surveyActionTypes.REMOVE_STUDY_PHYSICIAN_REQUESTED,
      payload: studyDoctor
    }
  },
  removeStudyPhysicianSucceeded(studyDoctor: StudyDoctorDtoType) {
    return {
      type: surveyActionTypes.REMOVE_STUDY_PHYSICIAN_SUCCEEDED,
      payload: studyDoctor
    }
  },
  removeStudyPhysicianFailed(error: Error) {
    return {
      type: surveyActionTypes.REMOVE_STUDY_PHYSICIAN_FAILED,
      payload: {
        error
      }
    }
  },

  updateStudyRequested(study: StudyDtoType) {
    return {
      type: surveyActionTypes.UPDATE_STUDY_REQUESTED,
      payload: study
    }
  },
  updateStudySucceeded(study: StudyDtoType) {
    return {
      type: surveyActionTypes.UPDATE_STUDY_SUCCEEDED,
      payload: study
    }
  },
  updateStudyFailed(error: Error) {
    return {
      type: surveyActionTypes.UPDATE_STUDY_FAILED,
      payload: { error }
    }
  },

  updateStudySurveyRequested(studySurvey: StudySurveyDtoType) {
    return {
      type: surveyActionTypes.UPDATE_STUDY_SURVEY_REQUESTED,
      payload: studySurvey
    }
  },
  updateStudySurveySucceeded(studySurvey: StudySurveyDtoType) {
    return {
      type: surveyActionTypes.UPDATE_STUDY_SURVEY_SUCCEEDED,
      payload: studySurvey
    }
  },
  updateStudySurveyFailed(error: Error) {
    return {
      type: surveyActionTypes.UPDATE_STUDY_SURVEY_FAILED,
      payload: { error }
    }
  },

  getSurveyStatisticRequested(surveyId: number, patientUuid?: string) {
    return {
      type: surveyActionTypes.GET_SURVEY_STATISTIC_REQUESTED,
      payload: { surveyId, patientUuid }
    }
  },
  getSurveyStatisticSucceeded(surveyId?: number, patientUuid?: string, statistic?: Array<SurveyQuestionStatisticType>) {
    return {
      type: surveyActionTypes.GET_SURVEY_STATISTIC_SUCCEEDED,
      payload: { surveyId, patientUuid, statistic }
    }
  },
  getSurveyStatisticFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_SURVEY_STATISTIC_FAILED,
      payload: { error }
    }
  },

  getSurveyDefinitionsByPatientRequested(patientUuid: string) {
    return {
      type: surveyActionTypes.GET_SURVEY_DEFINITIONS_BY_PATIENT_REQUESTED,
      payload: { patientUuid }
    }
  },
  getSurveyDefinitionsByPatientSucceeded(patientUuid: string, surveyDefinitions: Array<SurveyDefinitionSummaryType>) {
    return {
      type: surveyActionTypes.GET_SURVEY_DEFINITIONS_BY_PATIENT_SUCCEEDED,
      payload: { patientUuid, surveyDefinitions }
    }
  },
  getSurveyDefinitionsByPatientFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_SURVEY_DEFINITIONS_BY_PATIENT_FAILED,
      payload: { error }
    }
  },

  getDeviceDefinitionsRequested() {
    return {
      type: surveyActionTypes.GET_DEVICE_DEFINITIONS_REQUESTED
    }
  },
  getDeviceDefinitionsSucceeded(deviceDefinitions: Array<DeviceDefinitionDtoType>) {
    return {
      type: surveyActionTypes.GET_DEVICE_DEFINITIONS_SUCCEEDED,
      payload: deviceDefinitions
    }
  },
  getDeviceDefinitionsFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_DEVICE_DEFINITIONS_FAILED,
      payload: { error }
    }
  },

  uploadLSSFileRequested(name: string, content: any) {
    return {
      type: surveyActionTypes.UPLOAD_LSS_FILE_REQUESTED,
      payload: { name, content }
    }
  },
  uploadLSSFileSucceeded(name: string, surveyDefinition: SurveyDefinitionDtoType) {
    return {
      type: surveyActionTypes.UPLOAD_LSS_FILE_SUCCEEDED,
      payload: { name, surveyDefinition }
    }
  },
  uploadLSSFileFailed(error: Error) {
    return {
      type: surveyActionTypes.UPLOAD_LSS_FILE_FAILED,
      payload: { error }
    }
  },
  updateSurveyDefinitionRequested(payload: SurveyDefinitionDtoType) {
    return {
      type: surveyActionTypes.UPDATE_SURVEY_DEFINITION_REQUESTED,
      payload
    }
  },
  updateSurveyDefinitionSucceeded(payload: SurveyDefinitionDtoType) {
    return {
      type: surveyActionTypes.UPDATE_SURVEY_DEFINITION_SUCCEEDED,
      payload
    }
  },
  updateSurveyDefinitionFailed(error: Error) {
    return {
      type: surveyActionTypes.UPDATE_SURVEY_DEFINITION_FAILED,
      payload: { error }
    }
  },
  getSurveyParticipationsByPatientRequested(patientUuid?: string) {
    return { type: surveyActionTypes.GET_SURVEY_PARTICIPATIONS_BY_PATIENT_REQUESTED, payload: patientUuid }
  },

  getSurveyParticipationsByPatientSucceeded(surveyParticipations: Array<SurveyParticipationDtoType>) {
    return {
      type: surveyActionTypes.GET_SURVEY_PARTICIPATIONS_BY_PATIENT_SUCCEEDED,
      payload: surveyParticipations
    }
  },
  getSurveyParticipationsByPatientFailed(error: Error) {
    return {
      type: surveyActionTypes.GET_SURVEY_PARTICIPATIONS_BY_PATIENT_FAILED,
      payload: { error }
    }
  },
  getSurveyParticipationsReset(id: string) {
    return {
      type: surveyActionTypes.RESET_PARTICIPATION_DATA,
      payload: id
    }
  },

  uploadSurveyThemeFileRequested(name: string, content: any, id: any) {
    return {
      type: surveyActionTypes.UPLOAD_SURVEY_THEMES_REQUESTED,
      payload: { name, content, id }
    }
  },
  uploadSurveyThemeFileSucceeded(name: string, surveyTheme: any) {
    return {
      type: surveyActionTypes.UPLOAD_SURVEY_THEMES_SUCCEEDED,
      payload: { name, surveyTheme }
    }
  },
  uploadSurveyThemeFileFailed(error: Error) {
    return {
      type: surveyActionTypes.UPLOAD_SURVEY_THEMES_FAILED,
      payload: { error }
    }
  },

  deleteSurveyThemeFileRequested(id: any, name: string) {
    return {
      type: surveyActionTypes.DELETE_SURVEY_THEMES_REQUESTED,
      payload: { id, name }
    }
  },
  deleteSurveyThemeFileSucceeded(name: string) {
    return {
      type: surveyActionTypes.DELETE_SURVEY_THEMES_SUCCEEDED,
      payload: { name }
    }
  },
  deleteSurveyThemeFileFailed(error: Error) {
    return {
      type: surveyActionTypes.DELETE_SURVEY_THEME_FAILED,
      payload: { error }
    }
  },

  createSurveyGroupRequested(payload: any) {
    return {
      type: surveyActionTypes.CREATE_SURVEY_GROUP_REQUESTED,
      payload
    }
  },
  createSurveyGroupSucceeded(name: string, data: any = {}) {
    return {
      type: surveyActionTypes.CREATE_SURVEY_GROUP_SUCCEEDED,
      payload: { name, ...data }
    }
  },
  createSurveyGroupFailed(error: Error) {
    return {
      type: surveyActionTypes.CREATE_SURVEY_GROUP_FAILED,
      payload: { error }
    }
  },

  updateSurveyGroupRequested(payload: any) {
    return {
      type: surveyActionTypes.UPDATE_SURVEY_GROUP_REQUESTED,
      payload
    }
  },
  updateSurveyGroupSucceeded(name: string, data: any = {}) {
    return {
      type: surveyActionTypes.UPDATE_SURVEY_GROUP_SUCCEEDED,
      payload: { name, ...data }
    }
  },
  updateSurveyGroupFailed(error: Error) {
    return {
      type: surveyActionTypes.UPDATE_SURVEY_GROUP_FAILED,
      payload: { error }
    }
  },

  deleteSurveyGroupRequested(payload: any) {
    return {
      type: surveyActionTypes.DELETE_SURVEY_GROUP_REQUESTED,
      payload
    }
  },
  deleteSurveyGroupSucceeded(name: string) {
    return {
      type: surveyActionTypes.DELETE_SURVEY_GROUP_SUCCEEDED,
      payload: { name }
    }
  },
  deleteSurveyGroupFailed(error: Error) {
    return {
      type: surveyActionTypes.DELETE_SURVEY_GROUP_FAILED,
      payload: { error }
    }
  }
}
