// @flow
import { takeEvery, put, select } from 'redux-saga/effects'
import { stopSubmit, destroy } from 'redux-form'
import type { GenType } from 'common-docdok/src/types'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'

import { asyncAction } from 'common-docdok/src/utils/sagaUtils'

import { dynamicFormActionTypes, type SubmitFormType } from '../../DynamicForm/actions'
import { infoPanelActions } from '../../InfoPanel/actions'
import { addToStudyDialogActions } from '../actions'

export const delayFn: any = (timeout: number) => new Promise((resolve) => setTimeout(() => resolve(true), timeout))

export function* addToStudyGen({ payload }: SubmitFormType): GenType {
  if (payload.name === 'addToStudy') {
    const { userRef, patientUuid } = yield select((state: StoreType) => state.addToStudyDialog)
    const participant = {
      ...payload.data,
      userRef,
      patientUuid,
      addedBy: getKeycloakClient().getUserId()
    }
    const winner = yield asyncAction(surveyActions.addStudyParticipantRequested(participant))

    if (winner.succeeded) {
      yield put(addToStudyDialogActions.toggleAddToStudyDialog())
      yield put(stopSubmit(payload.name))
      yield put(infoPanelActions.showMessage('surveys.actions.addToStudy.success'))
      const uuid = yield select((state: StoreType) => state.selectedPatient)
      if (uuid) {
        yield asyncAction(delayFn(5000)) // workaround to make sure surveys are created in the background
        yield put(healthrelationActions.loadPatientRequested(uuid))
        yield put(destroy('addToStudy'))
      }
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export default function studiesSaga(): Array<GenType> {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, addToStudyGen)]
}
