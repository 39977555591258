// @flow
import pathOr from 'ramda/src/pathOr'
import find from 'ramda/src/find'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'

import { getKeycloakClient } from '../../../common/Keycloak/keycloakProvider'
import { type CommonStateType } from '../../../types'

const isMuted = (...conversationIds: Array<number>) => (state: CommonStateType) => {
  const isConversationMuted = conversationId => {
    const subscriptions = pathOr([], ['messaging', 'conversations', conversationId, 'subscriptions'])(state)
    const conversation = find(propEq(getKeycloakClient().getUserId(), 'userRef'))(subscriptions)
    return propOr(false, 'mute')(conversation)
  }

  if (conversationIds.length === 1) {
    const [conversationId] = conversationIds
    return isConversationMuted(conversationId)
  }
  return conversationIds.reduce(
    (result, conversationId) => ({
      ...result,
      [conversationId]: isConversationMuted(conversationId)
    }),
    {}
  )
}

export default isMuted
