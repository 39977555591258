export default {
  de: {
    tabs: {
      profile: 'Profil',
      availability: 'Verfügbarkeit'
    },
    profilePhoto: 'Benutzerbild',
    changePhoto: 'Benutzerbild ändern',
    uploadHint: 'Drag & Drop dein Bild hier!',
    photoUpdated: 'Benutzerbild aktualisiert',
    chooseImage: 'Wähle ein Bild',
    language: 'Applikations Sprache',
    emailNotifications: 'Email Benachrichtigung für Chat-Nachrichten',
    emailNotificationDisableInfo:
      'Stellt die Email Benachrichtigung für Nachrichten aus. '
      + 'Mit dieser Option werden sie keine weiteren Emails über neue Nachrichten erhalten.',
    terms: 'Nutzungsbedingungen anzeigen',
    availability: {
      updated: 'Die  Verfügbarkeit wurde erfolgreich gespeichert.',
      physician: {
        title: 'Arzt Verfügbarkeit',
        subtitle: 'Spezifizieren Sie ihre Verfügbarkeit',
        predefined: 'Selektieren Sie eine vordefinierte Nachricht',
        message: 'Verfügbarkeitsnachricht',
        options: {
          always: {
            title: 'Immer verfügbar',
            message: 'Ich versuche nach Möglichkeit jederzeit zu antworten, 24 Studen, 7 Tage die Woche.'
          },
          businessHours: {
            title: 'Praxisöffnungszeiten',
            message:
              'Ich versuche während den Praxisöffnungszeiten zu antworten: '
              + '8:00 - 12:00 und 13:30 - 17:30, Montag bis Freitag.'
          },
          resonseTime24h: {
            title: '24h Antwortszeit, Jeden Tag',
            message: 'Ich versuche innerhalb von 24h, nach dem Erhalt Ihrer Nachricht zu antworten.'
          },
          resonseTime24hBussinesHours: {
            title: '24h Antwortszeit, Mo - Fr',
            message: 'Ich versuche Montag - Freitag innerhalb von 24h, nach dem erhalt Ihrer Nachricht zu antworten.'
          },
          oneWeekResponse: {
            title: '1 Woche Antwortszeit',
            message: 'Ich versuche innerhalb von 24h, nach dem Erhalt Ihrer Nachricht zu antworten.'
          },
          custom: {
            title: 'Eigene Nachricht',
            message: ''
          }
        }
      },
      clinic: {
        title: 'Klinik Verfügbarkeit',
        subtitle:
          'Spezifizieren sie die generelle Reaktionszeit Ihrer Praxis '
          + 'für die Beantwortung von Anfragen über docdok. '
          + 'Diese Einstellung ist global und betrifft somit alle Teammitglieder in Ihrer Praxis.',
        part1: 'Die normale Reaktionszeit unserer Arztpraxis für Patientenanfragen ist normalerweise ',
        part2: 'Stunden während der normalen Praxisöffnungszeiten.'
      },
      patientConversations: {
        title: 'Klinik Messaging Verfügbarkeit',
        part1: 'Messaging ist ',
        patientReadOnly: 'für Patienten nicht verfügbar. Kontaktieren Sie den Support für mehr Informationen',
        patientReadWrite: 'für Patienten verfügbar'
      }
    }
  },
  en: {
    tabs: {
      profile: 'Profile',
      availability: 'Availability'
    },
    emailNotifications: 'Email Notifications for Chat-Messages',
    emailNotificationDisableInfo:
      'Turn on or off emails for messages sent to you in docdok. '
      + 'When you turn this off, you will not receive any emails when you receive new messages. ',
    terms: 'Show Terms',
    profilePhoto: 'Profile Photo',
    changePhoto: 'Change Photo',
    uploadHint: 'Drag & Drop your Image here!',
    photoUpdated: 'Photo updated',
    chooseImage: 'Choose an Image',
    language: 'Application Language',
    availability: {
      updated: 'Availability successfully updated',
      physician: {
        title: 'Physician Availability',
        subtitle: 'Specify your availability',
        predefined: 'Select a predefined availability message',
        message: 'Availability Message',
        options: {
          always: {
            title: 'Always available',
            message: 'I try to respond to messages sent any time you send them, 24 hours, 7 days a week.'
          },
          businessHours: {
            title: 'Normal business hours',
            message:
              'I try to respond to messages sent any time during normal business hours: '
              + '8-12 and 1.30 to 5.30, Monday through Friday.'
          },
          resonseTime24h: {
            title: '24 hr response time, any day',
            message: 'I try to respond to your messages within 24 hours from the time you send them.'
          },
          resonseTime24hBussinesHours: {
            title: '24 hr response time, Mon - Fri',
            message:
              'I try to respond to messages sent Monday through Friday '
              + 'within 24 hours from the time they are sent.'
          },
          oneWeekResponse: {
            title: 'One-week response time',
            message: 'I try to respond to your messages within one week from the time you send them.'
          },
          custom: {
            title: 'Custom',
            message: ''
          }
        }
      },
      clinic: {
        title: 'Clinic Availability',
        part1: 'The normal response time for our office is usually ',
        part2: 'hours during normal business hours.',

        subtitle:
          'Specify your availability of your clinic. '
          + 'This settings is global and affects all the other members of your team.'
      },
      patientConversations: {
        title: 'Clinic Chat availability',
        part1: 'Your chat is  ',
        patientReadOnly: 'not available to patients, contact support for more info',
        patientReadWrite: 'available to patients'
      }
    }
  }
}
