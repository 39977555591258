// @flow


export function isRouteMatch(route: string, currentLocation: ?string, ignoreAll?: boolean) {
  if (!currentLocation || !route) {
    return false
  }
  let path = currentLocation.replace('/private/app', '')
  if (path === '') path = '/'

  // remove trailing slash and whitespaces

  if (route === 'ALL' && !ignoreAll) {
    // right with the route 'ALL' matches every route to test
    return true
  }

  const regex = new RegExp(':([^/]*)', 'g')
  const regexRoute = route.replace(regex, '[^/]*')
  const testRegex = `^${regexRoute}$`
  const test = new RegExp(testRegex)
  const result = test.exec(path) != null
  // console.log(JSON.stringify({ route, testRegex, path, result }))
  return result
}

export function checkRoute(state: StoreType, testRoute: string, replace?: (path: string) => void) {
  const { rights } = state.security || {}
  if (rights) {
    const rightsForRoute = rights.filter(r => isRouteMatch(r.route, testRoute, true))[0]
    if (!rightsForRoute) {
      if (replace) replace('/')
      return false
    }
  }
  return true
}
