// @flow
import pathEq from 'ramda/src/pathEq'
import type { ConversationStateType } from 'common-docdok/src/domain/messaging/reducers/messaging'

export default function (state: any, userRef: string): ?number {
  if (!userRef) return undefined

  let conversationId

  const conversations = Object.values(state.messaging.conversations)
  if (conversations) {
    const primaryConvs: any = conversations.filter((c: any) => {
      const conversation: ConversationStateType = c
      const isPrimary = pathEq('true', ['meta', 'primary'], conversation)
      const subjectMatches = conversation.meta.userRef === userRef
      return subjectMatches && isPrimary
    })
    if (primaryConvs.length > 0) {
      conversationId = primaryConvs[0].id
    }
  }
  return conversationId
}
