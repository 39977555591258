import React from 'react'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { pink } from '@mui/material/colors'
import palette from './palette'

/* const zIndex = {
  zIndex: {
    menu: 600,
    appBar: 700,
    drawerOverlay: 800,
    drawer: 900,
    dialogOverlay: 1000,
    dialog: 1100,
    layer: 1600,
    popover: 1700,
    snackbar: 2500,
    tooltip: 2600
  }
} */

const primary1Color = palette.docdokBlue
const fontFamily = 'Titillium Web, sans-serif'

export const themeV1 = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily
  },
  /*  zIndex: { tooltip: 1200 }, */
  palette: {
    text: { secondary: '#5f5f5f' },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primary1Color,
      dark: palette.docdokBlueDark
      // contrastText: will be calculated to contast with palette.primary.main
    },
    secondary: pink
  },
  components: {
    MuiFormControl: {
      root: {
        width: 256
      }
    },
    MuiToolbar: {
      regular: {
        '@media(max-width:768px)': {
          minHeight: '48px',
          maxHeight: '50px'
        }
      }
    }
  }
})

export const ThemeProvider = ({ children }: Object) => <MuiThemeProvider theme={themeV1}>{children}</MuiThemeProvider>
