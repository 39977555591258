// @flow

import axios from 'axios'
import type { Axios } from 'axios'
import type { AxiosConfigType } from './types'
import { getKeycloakClient } from '../common/Keycloak/keycloakProvider'
import * as user from './user'
import * as survey from './survey'
import * as messaging from './messaging'
import * as healthrelation from './healthrelation'

export const hostConfig = {
  // set a specific host if the calls are not relative
  host: ''
}

export const axiosConfig: AxiosConfigType = {
  // set a specific axios implementation
  impl: undefined
}

const axiosInstance = axios.create()
/**
 * Get axios implementation to execute query.
 * @return {Axios}
 */
export const getAxiosInstance: void => Axios = () => axiosConfig.impl || axiosInstance

axiosInstance.interceptors.request.use((config: any) => {
  if (config && config.headers && !config.headers['Content-Type']) {
    // eslint-disable-next-line
    config.headers = {
      ...config.headers,
      ...{
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  }
  if (config.url.startsWith('/rest')) {
    return getKeycloakClient()
      .refreshToken()
      .then((result) => {
        let res = {
          ...config,
          url: hostConfig.host + config.url,
          headers: {
            ...{
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            ...config.headers
          }
        }

        let accessToken
        if (result && result.access_token) {
          accessToken = result.access_token
        }
        const includeTokenAsParam = config.url.includes('/media/redirect/')
        if (includeTokenAsParam) {
          res = { ...res, params: { ...res.params, token: getKeycloakClient().getToken() } }
        } else {
          res = {
            ...res,
            headers:  {
              ...res.headers,
              Authorization: `Bearer ${accessToken || getKeycloakClient().getToken()}`
            }
          }
        }
        return res
      })
  }

  return config
})

export default {
  ...user,
  ...survey,
  ...messaging,
  ...healthrelation
}
