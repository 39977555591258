// @flow
/* eslint no-underscore-dangle: 0 */

import curry from 'ramda/src/curry'
import compose from 'ramda/src/compose'
import multiply from 'ramda/src/multiply'
import subtract from 'ramda/src/subtract'
import divide from 'ramda/src/divide'
import gte from 'ramda/src/gte'
import propOr from 'ramda/src/propOr'
import __ from 'ramda/src/__'
import T from 'ramda/src/T'
import F from 'ramda/src/F'
import concat from 'ramda/src/concat'
import Maybe from 'data.maybe'
import type MaybeType from 'data.maybe'
import type { LocalStorageType } from './localStorage'
import { keys, dummyMemoryStore } from './localStorage'
import log from './log'

export type OptionsType = {
  expirationInDays?: number, // default = Infinity
  prefixKey?: string,
  storage?: LocalStorageType,
  removeAfterUnreadForInDays?: number // default = 10
}

export type ValueType = {
  value: string, // data url
  fetchedAt: number, // Date.now() on set
  lastReadAt: number // updated at every read
}

const then = curry((f: Function, thenable: Promise<*>) => thenable.then(f))

const catchP = curry((f: Function, promise: Promise<*>) => promise.catch(f))

export const makeKey = concat

export const now = () => Number(new Date().getTime())

export function makeValue(value: string | ArrayBuffer, fetchedAt: number = now(), lastReadAt: number = now()): string {
  return JSON.stringify({ value, fetchedAt, lastReadAt })
}

export function parseValue(storageValue: string): MaybeType<ValueType> {
  try {
    return Maybe.Just(JSON.parse(storageValue))
  } catch (error) {
    log('error', `mediaCache parseValue error with ${storageValue}`)
    return Maybe.Nothing()
  }
}

export const getItem = curry((storage: LocalStorageType, prefixKey: string, key: string) =>
  compose(
    storage.getItem,
    curry(makeKey(prefixKey))
  )(key)
)

export function _exist({ storage, prefixKey }: OptionsType, key: string): Promise<boolean> {
  return compose(
    catchP(F),
    then(T),
    getItem(storage, prefixKey)
  )(key)
}

export const precision = compose(
  Math.round,
  divide(__, 1000)
)

export const elapsedTimeInMs = compose(
  subtract(now()),
  multiply(24 * 60 * 60 * 1000)
)

const _0 = () => 0

function getNumberProperty({ storage, prefixKey }: OptionsType, key: string, prop: string): Promise<number> {
  return compose(
    catchP(_0),
    then(propOr(0, prop)),
    then((maybe: MaybeType<ValueType>) => (maybe.isJust ? maybe.value : {})),
    then(parseValue),
    getItem(storage, prefixKey)
  )(key)
}

export function getFetchedAt(options: OptionsType, key: string): Promise<number> {
  return getNumberProperty(options, key, 'fetchedAt')
}

export function _hasExpired(options: OptionsType, key: string): Promise<boolean> {
  const { expirationInDays } = options
  return compose(
    then(gte(precision(elapsedTimeInMs(expirationInDays)))),
    then(precision),
    curry(getFetchedAt)
  )(options, key)
}

export function getLastReadAt(options: OptionsType, key: string): Promise<number> {
  return getNumberProperty(options, key, 'lastReadAt')
}

export function needToBePrune(options: OptionsType, key: string): Promise<boolean> {
  const { removeAfterUnreadForInDays } = options
  return compose(
    then(gte(precision(elapsedTimeInMs(removeAfterUnreadForInDays)))),
    then(precision),
    curry(getLastReadAt)
  )(options, key)
}

export function blobToDataURL(mediaBlob: Blob): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader()
      fileReader.onloadend = () => fileReader.result && resolve(fileReader.result)
      fileReader.onerror = () => reject(new Error('mediaCache error on blob to base64 conversion'))
      fileReader.readAsDataURL(mediaBlob)
    } catch (error) {
      reject(error)
    }
  })
}

export async function _set(
  options: OptionsType,
  key: string,
  value: Blob | string
): Promise<MaybeType<string | ArrayBuffer>> {
  const mediaDataUrl = typeof value === 'string' ? value : await blobToDataURL(value)
  const { storage, prefixKey } = options
  if (storage) {
    await storage.setItem(makeKey(prefixKey, key), makeValue(mediaDataUrl))
  } else {
    log('error', 'mediaCache storage is not set')
  }

  return Maybe.Just(mediaDataUrl)
}

export async function getData({ storage, prefixKey }: OptionsType, key: string): Promise<MaybeType<ValueType>> {
  if (storage) {
    return parseValue(await storage.getItem(makeKey(prefixKey, key)))
  }
  log('error', 'mediaCache storage is not set')
  return Promise.resolve(Maybe.Nothing())
}

export async function updateLastReadAt(
  options: OptionsType,
  key: string,
  data: MaybeType<ValueType>
): Promise<boolean> {
  const { prefixKey, storage } = options
  if (data.isJust && storage) {
    const newValue = { ...data.get(), lastReadAt: now() }
    await storage.setItem(makeKey(prefixKey, key), JSON.stringify(newValue))
    return true
  }
  return false
}

export type FetchResourceCallbackType = string => Promise<Blob>
export async function _getOrElseUpdate(
  options: OptionsType,
  key: string,
  fetchResourceCallback?: FetchResourceCallbackType
): Promise<MaybeType<string | ArrayBuffer>> {
  const keyExist = await _exist(options, key)
  const keyHasExpired = await _hasExpired(options, key)
  if ((!keyExist || keyHasExpired) && fetchResourceCallback) {
    const mediaBlob: Blob = await fetchResourceCallback(key)
    return _set(options, key, mediaBlob)
  }
  const data: MaybeType<ValueType> = await getData(options, key)

  const updated = await updateLastReadAt(options, key, data)
  if (!updated) {
    log('warn', 'lastReadAt not updated')
  }

  return data.isJust ? Maybe.Just(data.get().value) : Maybe.Nothing()
}

export async function _prune(options: OptionsType): Promise<Array<string>> {
  /* eslint no-restricted-syntax: 0 */
  const { storage, prefixKey } = options

  if (!storage) {
    log('error', 'storage is not set')
    return []
  }

  const entryToRemove = []
  for await (const key of keys(storage)) {
    const needPrune = await needToBePrune(options, key.substr(prefixKey ? prefixKey.length : 0))
    if (key && prefixKey && key.startsWith(prefixKey) && needPrune) {
      entryToRemove.push(key)
    }
  }
  await Promise.all(entryToRemove.map(storage.removeItem))
  return entryToRemove
}

const DEFAULT_OPTION: OptionsType = {
  expirationInDays: Infinity, // never expire by default
  prefixKey: 'cachekey-',
  storage: dummyMemoryStore,
  removeAfterUnreadForInDays: 10
}

export function setMediaCacheDefaultStorage(storage: LocalStorageType) {
  if (storage) {
    DEFAULT_OPTION.storage = storage
  }
}

/**
 * <code>
 *  import MediaCache from '../utils/mediaCache'
 *
 *  const Cache: CacheType = new MediaCache()
 *
 *  await Cache.set('123', <Blob>)
 *  const img = await Cache.get('123',
 *    (key: string) => {
 *     // a function that return a Promise<Blob>
 *     // nb. key = 123
 *    }
 *  )
 *
 * </code>
 *
 */
class Cache {
  constructor(options?: OptionsType) {
    if (options) {
      this.options = { ...DEFAULT_OPTION, ...options }
    }
  }

  options: OptionsType = DEFAULT_OPTION

  // set :: string -> Blob -> Promise<Maybe<string>>
  set = (key: string, file: Blob | string) => _set(this.options, key, file)

  // get :: (string, function) -> Promise<Maybe<string>>
  get = (key: string, updateCallback: FetchResourceCallbackType) => _getOrElseUpdate(this.options, key, updateCallback)

  // exist :: string -> Promise<boolean>
  exist = (key: string) => _exist(this.options, key)

  // hasExpired :: string -> Promise<boolean>
  hasExpired = (key: string) => _hasExpired(this.options, key)

  // prune :: void => Promise<Array<string>>
  prune = () => _prune(this.options)
}

export default Cache

export type CacheType = Cache
