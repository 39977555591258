// @flow
import saveAs from 'file-saver'
import get from 'lodash/get'
import i18n from '../i18n'

const prodOrStageHosts = ['web.docdok.ch', 'stage.dev.docdok.ch', 'stage.docdok.ch', 'web.docdok.de']

export const dispatchNavigateEvent = (path: string) => {
  const event = new CustomEvent('NAVIGATE_EVENT', {
    bubbles: true,
    cancelable: false,
    detail: { path }
  })
  const bodyElement = document.body
  if (bodyElement) {
    bodyElement.dispatchEvent(event)
  }
}

export function getWindowWidth(): number {
  return isNaN(window.innerWidth) ? window.clientWidth : window.innerWidth
}
export function getWindowHeight(): number {
  return isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight
}
export function pullGetParameter(parameterName: string): ?string {
  let result
  let tmp = []
  const { origin, href } = window.location
  let newUrl = href.replace(origin, '')
  // debugger // eslint-disable-line no-debugger
  location.search
    .substr(1)
    .split('&')
    .forEach((item) => {
      tmp = item.split('=')
      if (tmp[0] === parameterName) {
        result = decodeURIComponent(tmp[1])
        newUrl = newUrl.replace(`${tmp[0]}=${tmp[1]}`, '')
      }
    })
  newUrl = newUrl.replace('?&', '?')

  dispatchNavigateEvent(newUrl)
  return result
}

export function isFirefox(): boolean {
  return navigator.userAgent.indexOf('Firefox') !== -1
}

export function isIE(): boolean {
  // Get IE or Edge browser version
  let ie = false
  const ua = window.navigator.userAgent

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36
  // (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36
  // (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return true
  }

  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    // const rv = ua.indexOf('rv:')
    ie = true // parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    ie = true // parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return ie
}

export const IDLE_LISTENERS = []
export const IDLE_NO_MORE_LISTENERS = []
export const IDLE_TIMEOUT = 30 // seconds
let idleSecondsCounter = 0
let idle = false
function CheckIdleTime() {
  idleSecondsCounter += 1
  if (idleSecondsCounter >= IDLE_TIMEOUT && !idle) {
    idle = true
    IDLE_LISTENERS.forEach((listner) => {
      if (listner) {
        listner()
      }
    })
  }
}

function noMoreIdle() {
  idleSecondsCounter = 0
  if (idle) {
    idle = false
    IDLE_NO_MORE_LISTENERS.forEach((listner) => {
      if (listner) {
        listner()
      }
    })
  }
}
// $DisableFlow
document.onclick = noMoreIdle
// $DisableFlow
document.onmousemove = noMoreIdle
// $DisableFlow
document.onkeypress = noMoreIdle

window.setInterval(CheckIdleTime, 1000)

export function downloadSVC(name: string, data: any) {
  const blob = new Blob([`\uFEFF${data}`], {
    type: 'text/csv; charset=utf-8'
  })
  // const blob = new Blob([data], {
  //   type: 'text/csv;charset=utf-8'
  // })
  saveAs(blob, name)
}

export function downloadJSON(name: string, data: any) {
  const blob = new Blob([`\uFEFF${data}`], {
    type: 'application/json; charset=utf-8'
  })
  // const blob = new Blob([data], {
  //   type: 'text/csv;charset=utf-8'
  // })
  saveAs(blob, name)
}


export function isStageOrProd() {
  const { hostname } = window.location
  return prodOrStageHosts.filter(host => hostname.indexOf(host) !== -1).length > 0
}

export function getEnvFromHostName() {
  const { hostname } = window.location
// const hostname = 'tech-upgrade.dev.docdok.ch'
  if (hostname.indexOf('dev.docdok.ch') !== -1 || hostname.indexOf('stage') > -1) {
    return hostname.split('.')[0]
  }
  return hostname
}

export function areArrayEquals(arrayA: Array<any>, array: Array<any>) {
  // if the other array is a falsy value, return
  if (!arrayA && !array) return true
  if (arrayA && !array) return false
  if (array && !arrayA) return false

  // compare lengths - can save a lot of time
  if (arrayA.length !== array.length) return false

  for (let i = 0, l = arrayA.length; i < l; i += 1) {
    // Check if we have nested arrays
    if (arrayA[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!areArrayEquals(arrayA[i], array[i])) return false
    } else if (arrayA[i] !== array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }
  return true
}

export function timeStringToDate(timeString: string) {
  let result
  if (timeString) {
    const timeParts = timeString.split(':')
    const hours = timeParts[0]
    const minutes = timeParts[1]
    const timeDate = new Date()
    timeDate.setHours(Number(hours), Number(minutes), 0, 0)
    result = timeDate
  }
  return result
}

function pad(num) {
  const s = `${num}`
  return s.length === 2 ? s : `0${num}`
}

export function dateToTimeString(date: Date) {
  let result
  if (date) {
    result = `${pad(date.getHours())}:${pad(date.getMinutes())}`
  }
  return result
}

export function serverErrorToString(error: Error) {
  const messageKey = get(error, 'response.data.message')
  const params = get(error, 'response.data.params')

  return i18n.t(messageKey, params)
}

export function renderAndClickHiddenDownloadLink(dataUrl: string, downloadFileName: string) {
  if (dataUrl) {
    const link = document.createElement('a')
    link.download = downloadFileName
    link.href = dataUrl
    const { body } = document
    if (body) {
      body.appendChild(link)
      link.click()
      body.removeChild(link)
    }
  }
}

export function isOnline() {
  if ('serviceWorker' in navigator) {
    return navigator.onLine
  }
  return true
}

export function isEmpty(str: ?string) {
  return !str || str.length === 0
}
