// @flow
import type { ActionType, ExtractReturn } from 'common-docdok/src/types'
import { type ContentDefinitionVmType } from 'common-docdok/src/domain/messaging/types/contentDefinitionVm'
import { catalogActionTypes, catalogActions } from '../actions'

export type CatalogStateType = {
  selectedContent: Array<ContentDefinitionVmType>
}

const initialState: CatalogStateType = {
  selectedContent: [],
  showCreateCatalogDialog: false
}

// -- APPEND HANDLER HERE --
function setSendContentDialog(
  state: CatalogStateType,
  action: ExtractReturn<typeof catalogActions.setSendContentDialog>
) {
  return {
    ...state,
    selectedContent: action.payload
  }
}

function setToggleCatalogCreateDialog(state: CatalogStateType, action: any) {
  return {
    ...state,
    showCreateCatalogDialog: action.payload
  }
}

const ACTION_HANDLERS = {
  // -- APPEND ACTION TYPES HERE --
  [catalogActionTypes.SET_SEND_CONTENT_DIALOG]: setSendContentDialog,
  [catalogActionTypes.TOGGLE_CREATE_CATALOG_DIALOG]: setToggleCatalogCreateDialog
}

export default function catalogReducer(
  state: CatalogStateType = initialState,
  action: ActionType
): CatalogStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
