// @flow
import { takeEvery } from 'redux-saga/effects'
import type { GenType, ExtractReturn } from 'common-docdok/src/types'

import { launchSurveyActions, launchSurveyActionTypes } from '../actions'

export function* launchSurvey(action: ExtractReturn<typeof launchSurveyActions.launchSurvey>): GenType {
  const { participationId, navigate } = action.payload
  yield navigate(`/private/app/surveyparticipation/${participationId}`)
}

export default function surveySaga(): Array<GenType> {
  return [takeEvery(launchSurveyActionTypes.LAUNCH_SURVEY, launchSurvey)]
}
