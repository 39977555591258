// @flow

import invariant from 'invariant'
import type { AxiosPromise } from 'axios'
import { getAxiosInstance } from '.'
import type { UserDtoType } from '../types'
import buildPaginingUrl, { setAvatarPictureVersion, setAvatarPictureVersions } from './util'
import type { PreferencesType } from '../domain/user/types/preferences'

const BASE_PATH = '/rest/user/api/'

/**
 * Upload avatar.
 * @param {string} url
 * @param {any} image
 * @return {AxiosPromise<*>}
 */
export function uploadAvatar(url: string, image: any): AxiosPromise<*> {
  invariant(url, 'url undefined')
  invariant(image, 'image undefined')
  let headers = { 'Content-Type': 'image/png' }
  if (url.includes('googleapis')) {
    headers = { ...headers, 'x-goog-acl': 'public-read' }
  }

  return getAxiosInstance().put(url, image, {
    headers
  })
}

/**
 * Get list of users.
 * @return {AxiosPromise<Array<UserDtoType>>} users list
 */
export function getUsers(
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<UserDtoType>> {
  const path = buildPaginingUrl(`${BASE_PATH}users`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance()
    .get(path)
    .then(setAvatarPictureVersions)
}
/**
 * Get list of users.
 * @return {AxiosPromise<Array<UserDtoType>>} users list
 */
export function getUsersByRole(
  role: string,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
): AxiosPromise<Array<UserDtoType>> {
  invariant(role, 'role undefined')
  const path = buildPaginingUrl(`${BASE_PATH}users`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance()
    .get(`${path}&role=${role}`)
    .then(setAvatarPictureVersions)
}

/**
 * Get profile (me); the current logged in user.
 * @return {AxiosPromise<UserDtoType>}
 */
export function getProfile(): AxiosPromise<UserDtoType> {
  return getAxiosInstance()
    .get(`${BASE_PATH}users/me`)
    .then(setAvatarPictureVersion)
}

/**
 * Gets the rights of the current logged in user.
 * @return {AxiosPromise<UserDtoType>}
 */
export function getRights(): AxiosPromise<UserDtoType> {
  return getAxiosInstance().get(`${BASE_PATH}users/profile`)
}

// TODO: updateProfile
export function updateProfile() {
  invariant(false, 'Not yet implemented.')
}

/**
 * Get user by id.
 * @param {string} userId
 * @return {AxiosPromise<UserDtoType>}
 */
export function getUser(userId: string): AxiosPromise<UserDtoType> {
  invariant(userId, 'userId undefined')
  return getAxiosInstance()
    .get(`${BASE_PATH}users/${userId}`)
    .then(setAvatarPictureVersion)
}

export function getKeycloakEvents(
  userId: string,
  pageSize?: number = 1000,
  page?: number = 0,
  searchTerm?: string,
  orderBy?: string
) {
  const path = buildPaginingUrl(`${BASE_PATH}users/${userId}/keycloakEvents`, pageSize, page, searchTerm, orderBy)
  return getAxiosInstance().get(path)
}

/**
 * Create user.
 * @param {Object} data
 * @return {AxiosPromise<UserDtoType>}
 */
export function createUser(data: Object): AxiosPromise<UserDtoType> {
  invariant(data, 'data undefined')
  return getAxiosInstance().post(`${BASE_PATH}users`, data)
}

/**
 * Get upload avatar url.
 * @return {AxiosPromise<*>}
 */
export function getUploadAvatarUrl(): AxiosPromise<*> {
  return getAxiosInstance().get(`${BASE_PATH}users/me/uploadAvatarUrl`)
}

/**
 * Re-new invitation.
 * @param {String} uid
 * @return {AxiosPromise<*>}
 */
export function renewInvitation(uid: string): AxiosPromise<*> {
  invariant(uid, 'uid undefined')
  return getAxiosInstance().put(`${BASE_PATH}users/${uid}/renewInvitation`)
}

/**
 * update user email adresse
 * @param {String} uid
 * @return {AxiosPromise<*>}
 */
export function updateEmail(uid: string, email: string): AxiosPromise<*> {
  invariant(uid, 'uid undefined')
  invariant(email, 'email undefined')
  return getAxiosInstance().put(`${BASE_PATH}users/${uid}/email`, { email })
}

/**
 * update user email adresse
 * @param {String} uid
 * @return {AxiosPromise<*>}
 */
export function updateMobileNumber(uid: string, mobileNumber: string): AxiosPromise<*> {
  invariant(uid, 'uid undefined')
  invariant(mobileNumber, 'tel undefined')
  return getAxiosInstance().put(`${BASE_PATH}users/${uid}/mobileNumber`, {
    mobileNumber
  })
}

/**
 * Get user definition.
 * @return {AxiosPromise<*>}
 */
export function getUserDefinition(): AxiosPromise<*> {
  return getAxiosInstance().get(`${BASE_PATH}docs`)
}

/**
 * Get limited access token.
 * @param {AxiosXHRConfig} [config=undefined] axios config
 * @return {AxiosPromise<*>}
 */
export function getLimitedAccessToken(): AxiosPromise<*> {
  return getAxiosInstance().get(`${BASE_PATH}users/limitedaccesstoken`)
}

const DEFAULT_TERMS_VERSION = '2018-04-03'
const SWISS_ORIGIN = ['SALUTA', 'MIGROS']
/**
 * Get terms actual version
 * @return {AxiosPromise<String>}
 */
export async function getTermsVersion(origin: string = ''): AxiosPromise<*> {
  const { data } = await getAxiosInstance().get('/version.json')
  const withOrigin = data[`latestTermsVersion${origin}`]
  const termsOfChUser = data.latestTermsVersionCh
  const termsOfDeUser = data.latestTermsVersionDe
  const isWebCh = window ? String(window?.location?.host).includes('ch') : ''
  const isWebDe = window ? String(window?.location?.host).includes('de') : ''
  if (SWISS_ORIGIN.includes(origin) || isWebCh) {
    return termsOfChUser || withOrigin || data.latestTermsVersion || DEFAULT_TERMS_VERSION
  }
  if (isWebDe) {
    return termsOfDeUser || withOrigin || data.latestTermsVersion || DEFAULT_TERMS_VERSION
  }
  return withOrigin || data.latestTermsVersion || DEFAULT_TERMS_VERSION
}

/**
 * Update accepted terms version
 * @param {String} termsVersion
 * @return {AxiosPromise<Any>}
 */
export function updateAcceptedTermsVersion(termsVersion: string): AxiosPromise<*> {
  invariant(termsVersion, 'termsVersion undefined')
  return getAxiosInstance().put(`${BASE_PATH}users/me/acceptedTermsVersion`, termsVersion, {
    headers: {
      'Content-Type': 'text/plain'
    }
  })
}

/**
 *
 * @param {PreferencesType} preferences
 * @return {AxiosPromise<Any>}
 */
export function updateUserPreferences(preferences: PreferencesType): AxiosPromise<PreferencesType> {
  invariant(preferences, 'preferences undefined')
  return getAxiosInstance().put(`${BASE_PATH}users/me/preferences`, preferences)
}

export function getUserPreferences(): AxiosPromise<PreferencesType> {
  return getAxiosInstance().get(`${BASE_PATH}users/me/preferences`)
}

export function updateUser(user: UserDtoType): AxiosPromise<UserDtoType> {
  invariant(user, 'user undefined')
  return getAxiosInstance().put(`${BASE_PATH}users`, user)
}

export function disableUser(userRef: string): AxiosPromise<void> {
  invariant(userRef, 'userRef undefined')
  return getAxiosInstance().post(`${BASE_PATH}users/${userRef}/disable`)
}

export function getDocdokEvents(userId: string): AxiosPromise<*> {
  const path = `/rest/user/management/audits?uid=${userId}`
  return getAxiosInstance().get(path)
}

export function resendInitialSms(userRef: string): AxiosPromise<void> {
  invariant(userRef, 'userRef undefined')
  return getAxiosInstance().put(`${BASE_PATH}users/onboarding/${userRef}`)
}

export function deleteUser(userRef: string): AxiosPromise<void> {
  invariant(userRef, 'userRef undefined')
  return getAxiosInstance().delete(`/rest/healthrelation/api/cleanup/patient/${userRef}`)
}
