import customRecurrence from './customRecurrence'

export default {
  en: {
    title: 'Studies',
    actions: {
      createStudy: {
        title: 'Create Study',
        success: 'Study %{study} successfully created'
      },
      exportStudyData: {
        title: 'Export Study data'
      },
      addSurvey: {
        title: 'Add Survey',
        success: '%{addedCount} Survey successfully added to the study '
      },
      addStudyDevice: {
        title: 'Add Device',
        success: 'Devices %{names} successfully added to the study '
      },
      removeSurvey: {
        tooltip: 'Remove Survey from Study',
        device: 'Remove device from the study',
        success: 'Survey successfully removed from Study'
      },
      addStudyParticipant: {
        title: 'Add Study Patient',
        success: 'Study Patient successfully added'
      },
      addStudyPhysicians: {
        title: 'Add Study Physicians',
        success: 'Study Physicians %{userNames} successfully added'
      },
      removeStudyPhysician: {
        tooltip: 'Remove Physician from Study',
        success: 'Physician successfully removed from Study'
      },
      addStudyCoordinator: {
        title: 'Add Study Coordinator',
        success: 'Study Coordinator %{userName} successfully added'
      },
      scheduleSurvey: {
        tooltip: 'Schedule Survey',
        success: 'updated survey schedule successfully'
      },
      deleteStudy: {
        title: 'Delete Study',
        success: 'Study successfully deleted'
      },
      addParticipationCriterias: {
        title: 'Participation Criteria',
        success: 'Participation Criteria successfully added',
        save: 'Save',
        howto: 'Paste the text of the criteria from the study proposal and separate each with a semicolon.'
      }
    },
    physicians: {
      title: 'Study Physicians'
    },
    participants: {
      title: 'Study Patients'
    },
    studyCoordiantors: {
      title: 'Study Coordinator'
    },
    devices: {
      title: 'Devices'
    },
    surveys: {
      title: 'Surveys'
    },
    more: '%{more} more',
    showAll: 'show all',
    targetParticipantNumberHint: 'Enter the Targetnumber of Patients',
    targetPhysicianNumberHint: 'Enter the Targetnumber of Physicians',
    scheduleStudySurvey: {
      title: 'Schedule Survey',
      respondent: 'Respondent',
      surveyRespondentHP: 'Health professional',
      surveyRespondentPatient: 'Patient',
      doSchedule: 'Do schedule the survey',
      startAtTitle: 'Start Date',
      daysAfterAdded: 'Day Count',
      startAtOpts: {
        instant: 'Send as soon patient is added to the study',
        whenAdded: 'Send as soon the given time is reached after the patient is added to the study',
        daysAfterAdded: 'Send with delay:'
      },
      timeTitle: 'Sendingtime',
      patternTitle: 'Pattern',
      pattern: 'Pattern',
      patternOpts: {
        daily: 'Daily',
        weekly: 'Weekly',
        noRecurrence: 'No Recurrence',
        customRecurrence: 'Custom Recurrence'
      },
      interval: 'Interval',
      intervalOpts: {
        daily: 'Day',
        weekly: 'Week'
      },
      every: 'Every',
      time: 'Time',
      numberOfRecurrences: 'Number Of Recurrences',
      customRecurrence: customRecurrence.en
    },
    participationCriteria: {
      title: 'Participation Criteria - %{studyName}',
      inclusion: 'Inclusion Criteria',
      inclusionWhat: 'For the inclusion criteria below, verify that each item is present for the patient.',
      exclusion: 'Exclusion Criteria',
      exclusionWhat: 'Verify below that the patient has no exclusion criteria.',
      verify: 'Verify',
      informedConsent: 'Informed Consent',
      informedConsentWhat:
        'Please verify that the patient has agreed to and signed the informed consent form for the study',
      informedConsentVerifyText: 'Patient agreed/signed to informed consent',
      inclusionMet: 'All inclusion criteria met',
      exclusionMet: 'No exclusions',
      consentSigned: 'Informed consent signed'
    }
  },
  de: {
    title: 'Studien',
    actions: {
      createStudy: {
        title: 'Studie erstellen',
        success: 'Studie %{study} wurde erfolgreich erstellt'
      },
      exportStudyData: {
        title: 'Studiendaten Exportieren'
      },
      addSurvey: {
        title: 'Umfrage hinzufügen',
        success: '%{addedCount} Umfagen wurde erfolgreich zur Studie hinzugefügt'
      },
      addStudyDevice: {
        title: 'Gerät Hinzufügen',
        success: 'Geräte %{names} wurden erfolgreich zur Studie hinzugefügt'
      },
      removeSurvey: {
        tooltip: 'Umfrage von der Studie entfernen',
        device: 'Entfernen Sie das Gerät aus der Studie',
        success: 'Die Umfrage wurde erfolgreich von der Studie entfernt'
      },
      addStudyParticipant: {
        title: 'Studienpatient hinzufügen',
        success: 'Studienpatient erfolgreich hinzugefügt'
      },
      addStudyPhysicians: {
        title: 'Studienärzte hinzufügen',
        success: 'Die Ärzte %{userNames} wurden der Studie erfolgreich hinzugefügt'
      },
      removeStudyPhysician: {
        tooltip: 'Arzt von der Studie entfernen',
        success: 'Der Arzt wurde erfolgreich von der Studie entfernt.'
      },
      addStudyCoordinator: {
        title: 'Studienkoordinator hinzufügen',
        success: 'Der Studienkoordinator %{userName} wurden der Studie erfolgreich hinzugefügt'
      },
      scheduleSurvey: {
        tooltip: 'Umfragezeitplan erstellen',
        success: 'Umfragezeitplan wurde erfolgreich gesetzt'
      },
      deleteStudy: {
        title: 'Studie löschen',
        success: 'Studie wurde erfolgreich gelöscht.'
      },
      addParticipationCriterias: {
        title: 'Teilnahme Kriterien',
        success: 'Teilnahme Kriterien wurden erfolgreich gesetzt',
        save: 'Speichern',
        howto:
          'Pasten Sie den Kriterien Text aus dem Study Proposal und separieren sie diese jeweils mit einem Strichpunkt'
      }
    },
    physicians: {
      title: 'Studien Ärzte'
    },
    participants: {
      title: 'Studien Patienten'
    },
    studyCoordiantors: {
      title: 'Studiekoordinator'
    },
    devices: {
      title: 'Geräte'
    },
    surveys: {
      title: 'Umfragen'
    },
    targetParticipantNumberHint: 'Zielanzahl der Patienten eintragen',
    targetPhysicianNumberHint: 'Zielanzahl der Ärtzte eintragen',
    more: '%{more} zusätzlich',
    showAll: 'alle anzeigen',
    scheduleStudySurvey: {
      title: 'Umfrage konfigurieren',
      respondent: 'Befragter',
      surveyRespondentHP: 'Fachkraft',
      surveyRespondentPatient: 'Patient',
      doSchedule: 'Umfrage zeitlich einplanen',
      startAtTitle: 'Start Datum',
      daysAfterAdded: 'Anzahl Tage',
      startAtOpts: {
        instant: 'Direkt nach Studieneinschluss des Patienten versenden',
        whenAdded: 'Nach Studieneinschluss des Patienten, wenn die gegebene Uhrzeit erreicht wurde',
        daysAfterAdded: 'Mit Verzögerung versenden:'
      },
      timeTitle: 'Versendunszeit',
      patternTitle: 'Wiederholungsart',
      pattern: 'Wiederholungsart',
      patternOpts: {
        daily: 'Täglich',
        weekly: 'Wöchtentlich',
        noRecurrence: 'Keine Wiederholung',
        customRecurrence: 'Benutzerdefinierte Wiederholung'
      },
      interval: 'Interval',
      intervalOpts: {
        daily: 'Tag',
        weekly: 'Woche'
      },
      every: 'Jeden',
      time: 'Uhrzeit',
      numberOfRecurrences: 'Wiederholungsanzahl',
      customRecurrence: customRecurrence.de
    },
    participationCriteria: {
      title: 'Teilnahme Kriterien - %{studyName}',
      inclusion: 'Einschluss Kriterien',
      inclusionWhat: 'Bestätigen Sie für jedes Einschluss Kriterium, dass dieses zutrifft.',
      exclusion: 'Ausschluss Kriterien',
      exclusionWhat: 'Bestätigen Sie, dass für den Patienten kein Ausschluss Kriterium vorliegt.',
      verify: 'Bestätigen',
      informedConsent: 'Einverständniserklärung',
      informedConsentWhat: `Bestätigen Sie, dass der Patient der Einverständniserklärung der Studie zugestimmt
        und diese unterschrieben hat`,
      informedConsentVerifyText: 'Patient hat der Einverständniserklärung zugestimmt',
      inclusionMet: 'Alle Einschluss Kriterien erfüllt',
      exclusionMet: 'Keine Ausschluss Kriterien',
      consentSigned: 'Einverständniserklärung unterschrieben'
    }
  }
}
