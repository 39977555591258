// @flow

export const launchSurveyActionTypes = {
  LAUNCH_SURVEY: 'LAUNCH_SURVEY',
  LAUNCH_SURVEY_CLOSED: 'LAUNCH_SURVEY_CLOSED'
}
export type SurveysActionType = $Keys<typeof launchSurveyActionTypes>
export const launchSurveyActions = {
  launchSurvey(participationId: number, navigate: any) {
    return {
      type: launchSurveyActionTypes.LAUNCH_SURVEY,
      payload: { participationId, navigate }
    }
  },
  launchSurveyClosed(participationId: number) {
    return {
      type: launchSurveyActionTypes.LAUNCH_SURVEY_CLOSED,
      payload: participationId
    }
  }
}
