import { v4 as generateUUID } from 'uuid'

import type { PatientDtoType, CreatePatientDtoType } from '../types/patientDto'
import type { HealthcareProfessionalDtoType } from '../types/healthcareProfessionalDto'
import type { AvailabilityDtoType } from '../types/availabilityDto'
import type { ClinicDtoType } from '../types/clinicDto'
import type { MassMessagingVmType } from '../types/massMessagingVm'
import type { AppointmentDtoType } from '../types/appointmentDto'
import type {
  PatientActivitiesDtoType,
  CreatePatientActivitiesDtoType
} from '../types/patientActivitiesDto'
import type { PatientMeassurmentsDtoType } from '../types/patientMeassurements'

export const healthrelationActionTypes = {
  //      -- APPEND ACTION TYPES HERE --
  DELETE_CLINIC_REQUESTED: 'DELETE_CLINIC_REQUESTED',
  DELETE_CLINIC_SUCCEEDED: 'DELETE_CLINIC_SUCCEEDED',
  DELETE_CLINIC_FAILED: 'DELETE_CLINIC_FAILED',

  UPDATE_CLINIC_REQUESTED: 'UPDATE_CLINIC_REQUESTED',
  UPDATE_CLINIC_SUCCEEDED: 'UPDATE_CLINIC_SUCCEEDED',
  UPDATE_CLINIC_FAILED: 'UPDATE_CLINIC_FAILED',

  GET_CLINIC_MINE_REQUESTED: 'GET_CLINIC_MINE_REQUESTED',
  GET_CLINIC_MINE_SUCCEEDED: 'GET_CLINIC_MINE_SUCCEEDED',
  GET_CLINIC_MINE_FAILED: 'GET_CLINIC_MINE_FAILED',

  MASS_SENDING_REQUESTED: 'MASS_SENDING_REQUESTED',
  MASS_SENDING_SUCCEEDED: 'MASS_SENDING_SUCCEEDED',
  MASS_SENDING_COUNT_SUCCEEDED: 'MASS_SENDING_COUNT_SUCCEEDED',
  MASS_SENDING_FAILED: 'MASS_SENDING_FAILED',

  LOAD_CLINICS_REQUESTED: 'LOAD_CLINICS_REQUESTED',
  LOAD_CLINICS_SUCCEEDED: 'LOAD_CLINICS_SUCCEEDED',
  LOAD_CLINICS_FAILED: 'LOAD_CLINICS_FAILED',

  GET_CLINIC_REQUESTED: 'GET_CLINIC_REQUESTED',
  GET_CLINIC_SUCCEEDED: 'GET_CLINIC_SUCCEEDED',
  GET_CLINIC_FAILED: 'GET_CLINIC_FAILED',

  LOAD_ALL_PATIENTS_REQUESTED: 'LOAD_ALL_PATIENTS_REQUESTED',
  LOAD_ALL_PATIENTS_SUCCEEDED: 'LOAD_ALL_PATIENTS_SUCCEEDED',
  LOAD_ALL_PATIENTS_FAILED: 'LOAD_ALL_PATIENTS_FAILED',

  LOAD_PATIENT_REQUESTED: 'LOAD_PATIENT_REQUESTED',
  LOAD_PATIENT_SUCCEEDED: 'LOAD_PATIENT_SUCCEEDED',
  LOAD_PATIENT_FAILED: 'LOAD_PATIENT_FAILED',

  GET_PATIENT_DTO_REQUESTED: 'GET_PATIENT_DTO_REQUESTED',
  GET_PATIENT_DTO_SUCCEEDED: 'GET_PATIENT_DTO_SUCCEEDED',
  GET_PATIENT_DTO_FAILED: 'GET_PATIENT_DTO_FAILED',

  LOAD_TEAM_REQUESTED: 'LOAD_TEAM_REQUESTED',
  LOAD_TEAM_SUCCEEDED: 'LOAD_TEAM_SUCCEEDED',
  LOAD_TEAM_FAILED: 'LOAD_TEAM_FAILED',

  LOAD_PROFESSIONALS_REQUESTED: 'LOAD_PROFESSIONALS_REQUESTED',
  LOAD_PROFESSIONALS_SUCCEEDED: 'LOAD_PROFESSIONALS_SUCCEEDED',
  LOAD_PROFESSIONALS_FAILED: 'LOAD_PROFESSIONALS_FAILED',

  LOAD_PROFESSIONALS_BY_PUBLICUUID_REQUESTED:
    'LOAD_PROFESSIONALS_BY_PUBLICUUID_REQUESTED',
  LOAD_PROFESSIONALS_BY_PUBLICUUID_SUCCEEDED:
    'LOAD_PROFESSIONALS_BY_PUBLICUUID_SUCCEEDED',
  LOAD_PROFESSIONALS_BY_PUBLICUUID_FAILED:
    'LOAD_PROFESSIONALS_BY_PUBLICUUID_FAILED',

  CREATE_MPA_REQUESTED: 'CREATE_MPA_REQUESTED',
  CREATE_MPA_SUCCEEDED: 'CREATE_MPA_SUCCEEDED',
  CREATE_MPA_FAILED: 'CREATE_MPA_FAILED',

  CREATE_PATIENT_REQUESTED: 'CREATE_PATIENT_REQUESTED',
  CREATE_PATIENT_SUCCEEDED: 'CREATE_PATIENT_SUCCEEDED',
  CREATE_PATIENT_FAILED: 'CREATE_PATIENT_FAILED',

  CREATE_CLINIC_REQUESTED: 'CREATE_CLINIC_REQUESTED',
  CREATE_CLINIC_SUCCEEDED: 'CREATE_CLINIC_SUCCEEDED',
  CREATE_CLINIC_FAILED: 'CREATE_CLINIC_FAILED',

  CREATE_PHYSICIAN_REQUESTED: 'CREATE_PHYSICIAN_REQUESTED',
  CREATE_PHYSICIAN_SUCCEEDED: 'CREATE_PHYSICIAN_SUCCEEDED',
  CREATE_PHYSICIAN_FAILED: 'CREATE_PHYSICIAN_FAILED',

  REQUEST_ATTENTION_REQUESTED: 'REQUEST_ATTENTION_REQUESTED',
  REQUEST_ATTENTION_SUCCEEDED: 'REQUEST_ATTENTION_SUCCEEDED',
  REQUEST_ATTENTION_FAILED: 'REQUEST_ATTENTION_FAILED',

  LOAD_MISSING_PROFESSIONALS: 'LOAD_MISSING_PROFESSIONALS',

  UPLOAD_PATIENT_AVATAR_REQUESTED: 'UPLOAD_PATIENT_AVATAR_REQUESTED',
  UPLOAD_PATIENT_AVATAR_SUCCEEDED: 'UPLOAD_PATIENT_AVATAR_SUCCEEDED',
  UPLOAD_PATIENT_AVATAR_FAILED: 'UPLOAD_PATIENT_AVATAR_FAILED',

  UPDATE_PATIENT_REQUESTED: 'UPDATE_PATIENT_REQUESTED',
  UPDATE_PATIENT_SUCCEEDED: 'UPDATE_PATIENT_SUCCEEDED',
  UPDATE_PATIENT_FAILED: 'UPDATE_PATIENT_FAILED',

  GET_AVAILABILITIES_REQUESTED: 'GET_AVAILABILITIES_REQUESTED',
  GET_AVAILABILITIES_SUCCEEDED: 'GET_AVAILABILITIES_SUCCEEDED',
  GET_AVAILABILITIES_FAILED: 'GET_AVAILABILITIES_FAILED',

  SET_AVAILABILITY_REQUESTED: 'SET_AVAILABILITY_REQUESTED',
  SET_AVAILABILITY_SUCCEEDED: 'SET_AVAILABILITY_SUCCEEDED',
  SET_AVAILABILITY_FAILED: 'SET_AVAILABILITY_FAILED',

  DELETE_PATIENT_REQUESTED: 'DELETE_PATIENT_REQUESTED',
  DELETE_PATIENT_SUCCEEDED: 'DELETE_PATIENT_SUCCEEDED',
  DELETE_PATIENT_FAILED: 'DELETE_PATIENT_FAILED',

  UPCOMING_APPOINTMENTS_REQUESTED: 'UPCOMING_APPOINTMENTS_REQUESTED',
  UPCOMING_APPOINTMENTS_SUCCEEDED: 'UPCOMING_APPOINTMENTS_SUCCEEDED',
  UPCOMING_APPOINTMENTS_FAILED: 'UPCOMING_APPOINTMENTS_FAILED',

  LOAD_PATIENT_ACTIVITIES_REQUESTED: 'LOAD_PATIENT_ACTIVITIES_REQUESTED',
  LOAD_PATIENT_ACTIVITIES_SUCCEEDED: 'LOAD_PATIENT_ACTIVITIES_SUCCEEDED',
  LOAD_PATIENT_UNIQUE_ACTIVITIES_SUCCEEDED:
    'LOAD_PATIENT_UNIQUE_ACTIVITIES_SUCCEEDED',
  LOAD_PATIENT_ACTIVITIES_FAILED: 'LOAD_PATIENT_ACTIVITIES_FAILED',

  EDIT_PATIENT_ACTIVITIES_REQUESTED: 'EDIT_PATIENT_ACTIVITIES_REQUESTED',
  EDIT_PATIENT_ACTIVITIES_SUCCEEDED: 'EDIT_PATIENT_ACTIVITIES_SUCCEEDED',
  EDIT_PATIENT_ACTIVITIES_FAILED: 'EDIT_PATIENT_ACTIVITIES_FAILED',

  DELETE_PATIENT_ACTIVITIES_REQUESTED: 'DELETE_PATIENT_ACTIVITIES_REQUESTED',
  DELETE_PATIENT_ACTIVITIES_SUCCEEDED: 'DELETE_PATIENT_ACTIVITIES_SUCCEEDED',
  DELETE_PATIENT_ACTIVITIES_FAILED: 'DELETE_PATIENT_ACTIVITIES_FAILED',

  LOAD_PATIENT_MEASUREMENTS_REQUESTED: 'LOAD_PATIENT_MEASUREMENTS_REQUESTED',
  LOAD_PATIENT_MEASUREMENTS_SUCCEEDED: 'LOAD_PATIENT_MEASUREMENTS_SUCCEEDED',
  LOAD_PATIENT_MEASUREMENTS_FAILED: 'LOAD_PATIENT_MEASUREMENTS_FAILED',

  EDIT_PATIENT_MEASUREMENTS_REQUESTED: 'EDIT_PATIENT_MEASUREMENTS_REQUESTED',
  EDIT_PATIENT_MEASUREMENTS_SUCCEEDED: 'EDIT_PATIENT_MEASUREMENTS_SUCCEEDED',
  EDIT_PATIENT_MEASUREMENTS_FAILED: 'EDIT_PATIENT_MEASUREMENTS_FAILED',

  DELETE_PATIENT_MEASUREMENTS_REQUESTED:
    'DELETE_PATIENT_MEASUREMENTS_REQUESTED',
  DELETE_PATIENT_MEASUREMENTS_SUCCEEDED:
    'DELETE_PATIENT_MEASUREMENTS_SUCCEEDED',
  DELETE_PATIENT_MEASUREMENTS_FAILED: 'DELETE_PATIENT_MEASUREMENTS_FAILED',

  UPDATE_PATIENT_ACTIVITY_REQUESTED: 'UPDATE_PATIENT_ACTIVITY_REQUESTED',
  UPDATE_PATIENT_ACTIVITY_SUCCEEDED: 'UPDATE_PATIENT_ACTIVITY_SUCCEEDED',
  UPDATE_PATIENT_ACTIVITY_FAILED: 'UPDATE_PATIENT_ACTIVITY_FAILED',

  PATIENT_ACTIVITY_ADD_FILE_REQUESTED: 'PATIENT_ACTIVITY_ADD_FILE_REQUESTED',

  LOAD_PATIENT_PROGRESS_REQUESTED: 'LOAD_PATIENT_PROGRESS_REQUESTED',
  LOAD_PATIENT_PROGRESS_SUCCEEDED: 'LOAD_PATIENT_PROGRESS_SUCCEEDED',
  LOAD_PATIENT_PROGRESS_FAILED: 'LOAD_PATIENT_PROGRESS_FAILED',

  LOAD_PATIENT_COUNT_REQUESTED: 'LOAD_PATIENT_COUNT_REQUESTED',
  LOAD_PATIENT_COUNT_SUCCEEDED: 'LOAD_PATIENT_COUNT_SUCCEEDED',
  LOAD_PATIENT_COUNT_FAILED: 'LOAD_PATIENT_COUNT_FAILED',

  GET_CLINIC_OF_PATIENT_REQUESTED: 'GET_CLINIC_OF_PATIENT_REQUESTED',
  GET_CLINIC_OF_PATIENT_SUCCEEDED: 'GET_CLINIC_OF_PATIENT_SUCCEEDED',
  GET_CLINIC_OF_PATIENT_FAILED: 'GET_CLINIC_OF_PATIENT_FAILED',

  CREATE_DIGA_1_PATIENT_REQUESTED: 'CREATE_DIGA_1_PATIENT_REQUESTED',
  CREATE_DIGA_1_PATIENT_SUCCEEDED: 'CREATE_DIGA_1_PATIENT_SUCCEEDED',
  CREATE_DIGA_1_PATIENT_FAILED: 'CREATE_DIGA_1_PATIENT_FAILED',

  GET_PATIENT_DETAILS_REQUESTED: 'GET_PATIENT_DETAILS_REQUESTED',
  GET_PATIENT_DETAILS_SUCCEEDED: 'GET_PATIENT_DETAILS_SUCCEEDED',
  GET_PATIENT_DETAILS_FAILED: 'GET_PATIENT_DETAILS_FAILED',

  LOAD_PATIENT_TARGET_REQUESTED: 'LOAD_PATIENT_TARGET_REQUESTED',
  LOAD_PATIENT_TARGET_SUCCEEDED: 'LOAD_PATIENT_TARGET_SUCCEEDED',
  LOAD_PATIENT_TARGET_FAILED: 'LOAD_PATIENT_TARGET_FAILED',

  UPDATE_PATIENT_TARGET_REQUESTED: 'UPDATE_PATIENT_TARGET_REQUESTED',
  UPDATE_PATIENT_TARGET_SUCCEEDED: 'UPDATE_PATIENT_TARGET_SUCCEEDED',
  UPDATE_PATIENT_TARGET_FAILED: 'UPDATE_PATIENT_TARGET_FAILED',

  GET_PATIENT_DATA_FROM_DIGA_REQUESTED: 'GET_PATIENT_DATA_FROM_DIGA_REQUESTED',
  GET_PATIENT_DATA_FROM_DIGA_SUCCEEDED: 'GET_PATIENT_DATA_FROM_DIGA_SUCCEEDED',
  GET_PATIENT_DATA_FROM_DIGA_FAILED: 'GET_PATIENT_DATA_FROM_DIGA_FAILED',

  GET_PATIENT_APPOINTMENTS_REQUESTED: 'GET_PATIENT_APPOINTMENTS_REQUESTED',
  GET_PATIENT_APPOINTMENTS_SUCCEEDED: 'GET_PATIENT_APPOINTMENTS_SUCCEEDED',
  GET_PATIENT_APPOINTMENTS_FAILED: 'GET_PATIENT_APPOINTMENTS_FAILED'
}

let fileOrder = 0

export type HealthrelationActionType = any

export const healthrelationActions = {
  //      -- APPEND ACTIONS HERE --

  loadPatientActivitiesRequested(payload: {
    patientUuid: string,
    physicianUuid: string | null,
    distinct: boolean
  }) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_ACTIVITIES_REQUESTED,
      payload
    }
  },
  loadPatientActivitiesSucceeded(payload: PatientActivitiesDtoType) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_ACTIVITIES_SUCCEEDED,
      payload
    }
  },

  loadPatientUniqueActivitiesSucceeded(payload: PatientActivitiesDtoType) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_UNIQUE_ACTIVITIES_SUCCEEDED,
      payload
    }
  },

  loadPatientActivitiesFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_ACTIVITIES_FAILED,
      payload: { error }
    }
  },
  loadPatientMeasurementsRequested(patientRef: String) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_MEASUREMENTS_REQUESTED,
      payload: { patientRef }
    }
  },
  loadPatientMeasurementsSucceeded(payload: PatientMeassurmentsDtoType) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_MEASUREMENTS_SUCCEEDED,
      payload
    }
  },
  loadPatientMeasurementsFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_MEASUREMENTS_FAILED,
      payload: { error }
    }
  },
  upcomingAppointmentsRequested() {
    return {
      type: healthrelationActionTypes.UPCOMING_APPOINTMENTS_REQUESTED
    }
  },
  upcomingAppointmentsSucceeded(payload: Array<AppointmentDtoType>) {
    return {
      type: healthrelationActionTypes.UPCOMING_APPOINTMENTS_SUCCEEDED,
      payload
    }
  },
  upcomingAppointmentsFailed(error: Error) {
    return {
      type: healthrelationActionTypes.UPCOMING_APPOINTMENTS_FAILED,
      payload: { error }
    }
  },
  updateClinicRequested(payload: ClinicDtoType) {
    return {
      type: healthrelationActionTypes.UPDATE_CLINIC_REQUESTED,
      payload
    }
  },
  updateClinicSucceeded(payload: ClinicDtoType) {
    return {
      type: healthrelationActionTypes.UPDATE_CLINIC_SUCCEEDED,
      payload
    }
  },
  updateClinicFailed(error: Error) {
    return {
      type: healthrelationActionTypes.UPDATE_CLINIC_FAILED,
      payload: { error }
    }
  },

  getClinicMineRequested() {
    return { type: healthrelationActionTypes.GET_CLINIC_MINE_REQUESTED }
  },

  getClinicMineSucceeded(payload: ClinicDtoType) {
    return {
      type: healthrelationActionTypes.GET_CLINIC_MINE_SUCCEEDED,
      payload
    }
  },
  getClinicMineFailed(error: Error) {
    return {
      type: healthrelationActionTypes.GET_CLINIC_MINE_FAILED,
      payload: { error }
    }
  },

  massSendingRequested(
    message: string,
    activated: ?boolean,
    simulation: boolean = true,
    countCall: boolean = false
  ) {
    return {
      type: healthrelationActionTypes.MASS_SENDING_REQUESTED,
      payload: { message, activated, simulation, countCall }
    }
  },
  massSendingSucceeded(payload: MassMessagingVmType & { simulation: boolean }) {
    return {
      type: healthrelationActionTypes.MASS_SENDING_SUCCEEDED,
      payload
    }
  },
  massSendingCountSucceeded(payload: MassMessagingVmType & { simulation: boolean }) {
    return {
      type: healthrelationActionTypes.MASS_SENDING_COUNT_SUCCEEDED,
      payload
    }
  },
  massSendingFailed(error: Error) {
    return {
      type: healthrelationActionTypes.MASS_SENDING_FAILED,
      payload: { error }
    }
  },

  loadClinicsRequested() {
    return { type: healthrelationActionTypes.LOAD_CLINICS_REQUESTED }
  },
  loadClinicsSucceeded(payload: Array<ClinicDtoType>) {
    return {
      type: healthrelationActionTypes.LOAD_CLINICS_SUCCEEDED,
      payload
    }
  },
  loadClinicsFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_CLINICS_FAILED,
      payload: { error }
    }
  },

  getClinicRequested(id: string) {
    return { type: healthrelationActionTypes.GET_CLINIC_REQUESTED, payload: id }
  },
  getClinicSucceeded(payload: ClinicDtoType) {
    return {
      type: healthrelationActionTypes.GET_CLINIC_SUCCEEDED,
      payload
    }
  },
  getClinicFailed(error: Error) {
    return {
      type: healthrelationActionTypes.GET_CLINIC_FAILED,
      payload: { error }
    }
  },

  loadAllPatientsRequested() {
    return { type: healthrelationActionTypes.LOAD_ALL_PATIENTS_REQUESTED }
  },
  // TODO: define array template type
  loadAllPatientsSucceeded(users: Array<PatientDtoType>) {
    return {
      type: healthrelationActionTypes.LOAD_ALL_PATIENTS_SUCCEEDED,
      payload: users
    }
  },
  loadAllPatientsFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_ALL_PATIENTS_FAILED,
      payload: { error }
    }
  },
  // Loads the patients with all the dependencies
  loadPatientRequested(uuid: string) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_REQUESTED,
      payload: uuid
    }
  },
  loadPatientSucceeded(payload: PatientDtoType) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_SUCCEEDED,
      payload
    }
  },
  loadPatientFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_FAILED,
      payload: { error }
    }
  },
  // loads only the patient dto
  getPatientDtoRequested(uuid: string) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_DTO_REQUESTED,
      payload: uuid
    }
  },
  getPatientDtoSucceeded(payload: PatientDtoType) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_DTO_SUCCEEDED,
      payload
    }
  },
  getPatientDtoFailed(error: Error) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_DTO_FAILED,
      payload: { error }
    }
  },

  loadTeamRequested() {
    return {
      type: healthrelationActionTypes.LOAD_TEAM_REQUESTED
    }
  },
  loadTeamSucceeded(payload: Array<HealthcareProfessionalDtoType>) {
    return {
      type: healthrelationActionTypes.LOAD_TEAM_SUCCEEDED,
      payload
    }
  },
  loadTeamFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_TEAM_FAILED,
      payload: { error }
    }
  },

  loadProfessionalsRequested(ids: Array<string>) {
    return {
      type: healthrelationActionTypes.LOAD_PROFESSIONALS_REQUESTED,
      payload: ids
    }
  },
  loadProfessionalsSucceeded(payload: Array<HealthcareProfessionalDtoType>) {
    return {
      type: healthrelationActionTypes.LOAD_PROFESSIONALS_SUCCEEDED,
      payload
    }
  },
  loadProfessionalsFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_PROFESSIONALS_FAILED,
      payload: { error }
    }
  },

  loadProfessionalsByPublicUuidRequested(publicUuids: Array<string>) {
    return {
      type:
        healthrelationActionTypes.LOAD_PROFESSIONALS_BY_PUBLICUUID_REQUESTED,
      payload: publicUuids
    }
  },
  loadProfessionalsByPublicUuidSucceeded(
    payload: Array<HealthcareProfessionalDtoType>
  ) {
    return {
      type:
        healthrelationActionTypes.LOAD_PROFESSIONALS_BY_PUBLICUUID_SUCCEEDED,
      payload
    }
  },
  loadProfessionalsByPublicUuidFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_PROFESSIONALS_BY_PUBLICUUID_FAILED,
      payload: { error }
    }
  },

  createMpaRequested(payload: HealthcareProfessionalDtoType) {
    return {
      type: healthrelationActionTypes.CREATE_MPA_REQUESTED,
      payload: {
        ...payload,
        class: 'MPA'
      }
    }
  },
  createMpaSucceeded(payload: HealthcareProfessionalDtoType) {
    return {
      type: healthrelationActionTypes.CREATE_MPA_SUCCEEDED,
      payload
    }
  },
  createMpaFailed(error: Error) {
    return {
      type: healthrelationActionTypes.CREATE_MPA_FAILED,
      payload: { error }
    }
  },

  createPatientRequested(payload: CreatePatientDtoType) {
    return {
      type: healthrelationActionTypes.CREATE_PATIENT_REQUESTED,
      payload
    }
  },
  createPatientSucceeded(payload: PatientDtoType) {
    return {
      type: healthrelationActionTypes.CREATE_PATIENT_SUCCEEDED,
      payload
    }
  },
  createPatientFailed(error: Error) {
    return {
      type: healthrelationActionTypes.CREATE_PATIENT_FAILED,
      payload: { error }
    }
  },

  createClinicRequested(payload: ClinicDtoType) {
    return {
      type: healthrelationActionTypes.CREATE_CLINIC_REQUESTED,
      payload
    }
  },
  createClinicSucceeded(payload: ClinicDtoType) {
    return {
      type: healthrelationActionTypes.CREATE_CLINIC_SUCCEEDED,
      payload
    }
  },
  createClinicFailed(error: Error) {
    return {
      type: healthrelationActionTypes.CREATE_CLINIC_FAILED,
      payload: { error }
    }
  },

  createPhysicianRequested(payload: HealthcareProfessionalDtoType) {
    return {
      type: healthrelationActionTypes.CREATE_PHYSICIAN_REQUESTED,
      payload: {
        ...payload,
        class: 'PHYSICIAN'
      }
    }
  },
  createPhysicianSucceeded(payload: HealthcareProfessionalDtoType) {
    return {
      type: healthrelationActionTypes.CREATE_PHYSICIAN_SUCCEEDED,
      payload
    }
  },
  createPhysicianFailed(error: Error) {
    return {
      type: healthrelationActionTypes.CREATE_PHYSICIAN_FAILED,
      payload: { error }
    }
  },

  requestAttentionRequested(
    patientUuid: string,
    receiverIds: Array<string>,
    message: string
  ) {
    return {
      type: healthrelationActionTypes.REQUEST_ATTENTION_REQUESTED,
      payload: {
        patientUuid,
        receiverIds,
        message
      }
    }
  },
  requestAttentionSucceeded() {
    return { type: healthrelationActionTypes.REQUEST_ATTENTION_SUCCEEDED }
  },
  requestAttentionFailed(error: Error) {
    return {
      type: healthrelationActionTypes.REQUEST_ATTENTION_FAILED,
      payload: { error }
    }
  },

  loadMissingProfessionals(uuids: Array<string>) {
    return {
      type: healthrelationActionTypes.LOAD_MISSING_PROFESSIONALS,
      payload: uuids
    }
  },

  uploadPatientAvatarRequested(patientUuid: string, bytes: any) {
    return {
      type: healthrelationActionTypes.UPLOAD_PATIENT_AVATAR_REQUESTED,
      payload: {
        patientUuid,
        bytes
      }
    }
  },
  uploadPatientAvatarSucceeded(patientUuid: string, avatarUrl: string) {
    return {
      type: healthrelationActionTypes.UPLOAD_PATIENT_AVATAR_SUCCEEDED,
      payload: { patientUuid, avatarUrl }
    }
  },
  uploadPatientAvatarFailed(error: Error) {
    return {
      type: healthrelationActionTypes.UPLOAD_PATIENT_AVATAR_FAILED,
      payload: { error }
    }
  },

  updatePatientRequested(payload: PatientDtoType) {
    return {
      type: healthrelationActionTypes.UPDATE_PATIENT_REQUESTED,
      payload
    }
  },
  updatePatientSucceeded(payload: PatientDtoType) {
    return {
      type: healthrelationActionTypes.UPDATE_PATIENT_SUCCEEDED,
      payload
    }
  },
  updatePatientFailed(error: Error) {
    return {
      type: healthrelationActionTypes.UPDATE_PATIENT_FAILED,
      payload: { error }
    }
  },

  getAvailabilitiesRequested() {
    return {
      type: healthrelationActionTypes.GET_AVAILABILITIES_REQUESTED
    }
  },
  getAvailabilitiesSucceeded(availabilities: Array<AvailabilityDtoType>) {
    return {
      type: healthrelationActionTypes.GET_AVAILABILITIES_SUCCEEDED,
      payload: availabilities
    }
  },
  getAvailabilitiesFailed(error: Error) {
    return {
      type: healthrelationActionTypes.GET_AVAILABILITIES_FAILED,
      payload: { error }
    }
  },
  setAvailabilityRequested(type: string, message: string) {
    return {
      type: healthrelationActionTypes.SET_AVAILABILITY_REQUESTED,
      payload: {
        type,
        message
      }
    }
  },
  setAvailabilitySucceeded(payload: AvailabilityDtoType) {
    return {
      type: healthrelationActionTypes.SET_AVAILABILITY_SUCCEEDED,
      payload
    }
  },
  setAvailabilityFailed(error: Error) {
    return {
      type: healthrelationActionTypes.SET_AVAILABILITY_FAILED,
      payload: { error }
    }
  },

  deletePatientRequested(payload: PatientDtoType) {
    return {
      type: healthrelationActionTypes.DELETE_PATIENT_REQUESTED,
      payload
    }
  },
  deletePatientSucceeded(payload: PatientDtoType) {
    return {
      type: healthrelationActionTypes.DELETE_PATIENT_SUCCEEDED,
      payload
    }
  },
  deletePatientFailed(error: Error) {
    return {
      type: healthrelationActionTypes.DELETE_PATIENT_FAILED,
      payload: { error }
    }
  },

  deleteClinicRequested(clinicId: number) {
    return {
      type: healthrelationActionTypes.DELETE_CLINIC_REQUESTED,
      payload: { clinicId }
    }
  },
  deleteClinicSucceeded(clinicId: number) {
    return {
      type: healthrelationActionTypes.DELETE_CLINIC_SUCCEEDED,
      payload: { clinicId }
    }
  },
  deleteClinicFailed(error: Error) {
    return {
      type: healthrelationActionTypes.DELETE_CLINIC_FAILED,
      payload: { error }
    }
  },

  updatePatientActivityRequested(payload: CreatePatientActivitiesDtoType) {
    return {
      type: healthrelationActionTypes.UPDATE_PATIENT_ACTIVITY_REQUESTED,
      payload
    }
  },
  updatePatientActivitySucceeded(payload: any) {
    return {
      type: healthrelationActionTypes.UPDATE_PATIENT_ACTIVITY_SUCCEEDED,
      payload
    }
  },
  updatePatientActivityFailed(error: Error) {
    return {
      type: healthrelationActionTypes.UPDATE_PATIENT_ACTIVITY_FAILED,
      payload: { error }
    }
  },

  addFileRequested(patientActivityId: number, file: any) {
    fileOrder += 1
    return {
      type: healthrelationActionTypes.PATIENT_ACTIVITY_ADD_FILE_REQUESTED,
      payload: {
        patientActivityId,
        file: {
          order: fileOrder,
          id: generateUUID(),
          mediaResourceId: -1,
          uploaded: false,
          url: undefined,
          description: undefined,
          ...file
        }
      }
    }
  },

  loadPatientProgressRequested(params: any) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_PROGRESS_REQUESTED,
      payload: params
    }
  },
  loadPatientProgressSucceeded(payload) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_PROGRESS_SUCCEEDED,
      payload
    }
  },
  loadPatientProgressFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_PROGRESS_FAILED,
      payload: { error }
    }
  },

  loadPatientCountRequested(params: any) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_COUNT_REQUESTED,
      payload: params
    }
  },
  loadPatientCountSucceeded(payload) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_COUNT_SUCCEEDED,
      payload
    }
  },
  loadPatientCountFailed(error: Error) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_COUNT_FAILED,
      payload: { error }
    }
  },

  getClinicOfPatientRequested() {
    return { type: healthrelationActionTypes.GET_CLINIC_OF_PATIENT_REQUESTED }
  },
  getClinicOfPatientSucceeded(payload) {
    return {
      type: healthrelationActionTypes.GET_CLINIC_OF_PATIENT_SUCCEEDED,
      payload
    }
  },
  getClinicOfPatientFailed(error) {
    return {
      type: healthrelationActionTypes.GET_CLINIC_OF_PATIENT_FAILED,
      payload: { error }
    }
  },

  createDiga1PatientRequested(payload) {
    return {
      type: healthrelationActionTypes.CREATE_DIGA_1_PATIENT_REQUESTED,
      payload
    }
  },
  createDiga1PatientSucceeded(payload) {
    return {
      type: healthrelationActionTypes.CREATE_DIGA_1_PATIENT_SUCCEEDED,
      payload
    }
  },
  createDiga1PatientFailed(error) {
    return {
      type: healthrelationActionTypes.CREATE_DIGA_1_PATIENT_FAILED,
      payload: { error }
    }
  },

  getCurrentPatientRequested(uuid) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_DETAILS_REQUESTED,
      payload: uuid
    }
  },
  getCurrentPatientSucceeded(payload) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_DETAILS_SUCCEEDED,
      payload
    }
  },
  getCurrentPatientFailed(error) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_DETAILS_FAILED,
      payload: { error }
    }
  },

  loadPatientTargetRequested(id, cb) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_TARGET_REQUESTED,
      payload: { id, cb }
    }
  },
  loadPatientTargetSucceeded(payload) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_TARGET_SUCCEEDED,
      payload
    }
  },
  loadPatientTargetFailed(error) {
    return {
      type: healthrelationActionTypes.LOAD_PATIENT_TARGET_FAILED,
      payload: { error }
    }
  },

  updatePatientTargetRequested(payload) {
    return {
      type: healthrelationActionTypes.UPDATE_PATIENT_TARGET_REQUESTED,
      payload
    }
  },
  updatePatientTargetSucceeded(payload) {
    return {
      type: healthrelationActionTypes.UPDATE_PATIENT_TARGET_SUCCEEDED,
      payload
    }
  },
  updatePatientTargetFailed(error) {
    return {
      type: healthrelationActionTypes.UPDATE_PATIENT_TARGET_FAILED,
      payload: { error }
    }
  },

  loadPatientDigaDetailsRequested(payload) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_DATA_FROM_DIGA_REQUESTED,
      payload
    }
  },
  loadPatientDigaDetailsSucceeded(payload) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_DATA_FROM_DIGA_SUCCEEDED,
      payload
    }
  },
  loadPatientDigaDetailsFailed(error) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_DATA_FROM_DIGA_FAILED,
      payload: { error }
    }
  },

  loadPatientAppoitmentsRequested(payload) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_APPOINTMENTS_REQUESTED,
      payload
    }
  },
  loadPatientAppoitmentsSucceeded(payload) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_APPOINTMENTS_SUCCEEDED,
      payload
    }
  },
  loadPatientAppoitmentsFailed(error) {
    return {
      type: healthrelationActionTypes.GET_PATIENT_APPOINTMENTS_FAILED,
      payload: { error }
    }
  }
}
