// @flow
import { put, takeEvery } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import type { GenType } from 'common-docdok/src/types'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import { createCaseButtonActions } from '../actions'
import { infoPanelActions } from '../../InfoPanel/actions'
import { dynamicFormActionTypes, type SubmitFormType } from '../../DynamicForm/actions'
import { dispatchNavigateEvent } from '../../utils'

export function* createCaseGen({ payload }: SubmitFormType): GenType {
  if (payload.name === 'createCase') {
    const caseOwner = getKeycloakClient().getUserId()
    const {
      data: { caseName: title, casedata }
    } = payload

    const meta = { case: true, caseOwner, casedata }
    const winner = yield asyncAction(messagingActions.createConversationRequested(title, [], meta))

    if (winner.succeeded) {
      yield put(createCaseButtonActions.toggleCreateCaseDialog())
      yield put(infoPanelActions.showMessage('cases.newCase.created'))
      yield put(stopSubmit(payload.name))
      dispatchNavigateEvent(`/private/app/messages/conversation/${winner.succeeded.payload.conversation.id}`)
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export default function caseSaga(): Array<GenType> {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, createCaseGen)]
}
