// @flow
import { put, takeEvery, call, select } from 'redux-saga/effects'

import type MaybeType from 'data.maybe'
import { messagingActions, messagingActionTypes } from '../actions'
import type { ExtractReturn, GenType, CommonStateType } from '../../../types'
import api from '../../../api'
import MediaCache from '../../../utils/mediaCache'
import type { CacheType } from '../../../utils/mediaCache'

export function renderAndClickHiddenDownloadLink(dataUrl: string, downloadFileName: string) {
  if (dataUrl) {
    const link = document.createElement('a')
    link.download = downloadFileName
    link.href = dataUrl
    const { body } = document
    if (body) {
      body.appendChild(link)
      link.click()
      body.removeChild(link)
    }
  }
}

const Cache: CacheType = new MediaCache()

export function* addFileGen(action: ExtractReturn<typeof messagingActions.addFileRequested>): GenType {
  const {
    conversationId,
    file,
    file: { mimeType, arrayBuffer }
  } = action.payload
  const {
    data: { mediaResourceId, url }
  } = yield call(api.uploadRequest, mimeType, conversationId)

  yield call(api.uploadFile, url, mimeType, arrayBuffer)
  // load file form store since it can be changed in the meantime
  const currentFile = yield select(
    (state: CommonStateType) => state.messaging.attachments[conversationId].filter(f => f.id === file.id)[0]
  )
  yield put(
    messagingActions.addFileSucceeded(conversationId, {
      ...currentFile,
      mediaResourceId,
      url,
      uploaded: true
    })
  )
}

export function* getMediaResource(action: ExtractReturn<typeof messagingActions.getMediaResourceRequested>): GenType {
  try {
    const { uuid, preview, mimeType, message } = action.payload
    const pdfMimeType = 'application/pdf'

    let cacheKey = uuid

    // if original image exist (ie. aware mimeType != pdf)
    // so return original even if it's a preview
    if (preview) {
      if (mimeType !== pdfMimeType) {
        const originalExist = yield call(Cache.exist, uuid)
        cacheKey = originalExist ? uuid : `preview-${uuid}`
      } else {
        cacheKey = `preview-${uuid}`
      }
    }

    if (message && message.clearCatch) {
      cacheKey = `preview-${uuid}-cache-buster-${new Date().getTime()}`
    }

    const fetchResourceCallback = () =>
      api
        .getMediaRedirectUrl(uuid) //
        .then(result => result.data) //
        .then(url => api.getMediaBlobWithSignedUrl(url, mimeType, message && message.clearCatch))

    const rawMedia: MaybeType<string> = yield call(Cache.get, cacheKey, fetchResourceCallback)

    if (rawMedia.isJust) {
      if (message && message.clearCatch) {
        renderAndClickHiddenDownloadLink(rawMedia.get(), message.text)
      }

      yield put(messagingActions.getMediaResourceSucceeded(rawMedia.get(), uuid, preview, mimeType))
    } else {
      yield put(messagingActions.getMediaResourceFailed(new Error('could not find resource in the cache')))
    }

    // FIMXE
    // yield call(Cache.prune)
  } catch (error) {
    yield put(messagingActions.getMediaResourceFailed(error))
  }
}

export default function messagesSaga(): Array<GenType> {
  return [
    takeEvery(messagingActionTypes.ADD_FILE_REQUESTED, addFileGen),
    takeEvery(messagingActionTypes.GET_MEDIA_RESOURCE_REQUESTED, getMediaResource)
  ]
}
