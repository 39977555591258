// @flow

import type { ExtractReturn } from '../../../types'
import type { SurveyParticipantDtoType } from '../types/surveyParticipantDto'
import { type SurveyParticipationDtoType } from '../types/surveyParticipationDto'
import { surveyActions as actions, surveyActionTypes as types } from '../actions'
import type { SurveyActionType } from '../actions'
import type { SurveyDefinitionDtoType } from '../types/surveyDefinitionDto'
import type { ConnectedDeviceDtoType } from '../types/connectedDeviceDto'
import type { SurveyDefinitionSummaryType } from '../types/surveyDefinitionSummary'
import type { StudyDtoType } from '../types/studyDto'
import type { StudyParticipantDtoType } from '../types/studyParticipantDto'
import type { DeviceDefinitionDtoType } from '../types/deviceDefinitionDto'
import type { SurveyQuestionStatisticType } from '../types/surveyQuestionStatistic'

// State Definition
export type SurveyStateType = {
  surveyDefinitions: Array<SurveyDefinitionSummaryType>,
  surveyDefinitionsById: {
    [id: number]: SurveyDefinitionSummaryType
  },
  surveySpecsByParticipation: {
    [id: number]: Object
  },
  surveyParticipations: {
    [participationId: string]: Array<SurveyParticipantDtoType>
  },
  usersSurveyParticipations: {
    [patientId: string]: Array<SurveyParticipantDtoType>
  },
  mySurveys: Array<SurveyParticipantDtoType>,
  devices: {
    [patientUuid: string]: {
      [deviceName: string]: ConnectedDeviceDtoType
    }
  },
  deviceDefinitions: Array<DeviceDefinitionDtoType>,
  deviceData: {
    [patientUuid: string]: {
      [deviceName: string]: Object
    }
  },
  allStudies: Array<StudyDtoType>,
  studies: {
    [studiyId: number]: StudyDtoType
  },
  studyParticipations: {
    [patientUuid: string]: Array<StudyParticipantDtoType & StudyDtoType>
  },
  surveyResponses: {
    [participationId: number]: Object
  },
  surveyStatistic: {
    [patientUuid: string]: {
      [surveyId: number]: Array<SurveyQuestionStatisticType>
    }
  },
  patientSurveyDefinitions: {
    [patientUuid: string]: Array<SurveyDefinitionSummaryType>
  },
  surveyParticipationsByPatient: Array<SurveyParticipationDtoType>
}

// Default State
const initialState: SurveyStateType = {
  surveyDefinitions: [],
  mySurveys: [],
  surveyParticipations: {},
  usersSurveyParticipations: {},
  devices: {},
  deviceData: {},
  deviceDefinitions: [],
  allStudies: [],
  studies: {},
  studyParticipations: {},
  surveyResponses: {},
  surveyDefinitionsById: {},
  surveyStatistic: {},
  patientSurveyDefinitions: {},
  surveySpecsByParticipation: {},
  surveyParticipationsByPatient: []
}

// Action Handlers

function getSurveyDefinitonsSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveyDefinitionsSucceeded>
): SurveyStateType {
  const newIds = action.payload.map(s => s.id)
  const oldSurveys = state.surveyDefinitions.filter(s => !newIds.includes(s.id))

  return {
    ...state,
    surveyDefinitions: [...action.payload, ...oldSurveys]
  }
}

function getSurveyParticipationsSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveyParticipationsSucceeded>
): SurveyStateType {
  let newState = {
    ...state
  }
  const participations = action.payload
  if (participations.length > 0) {
    const { id: participationId } = participations[0]

    newState = {
      ...newState,
      surveyParticipations: {
        ...newState.surveyParticipations,
        [participationId]: participations
      }
    }
  }

  return newState
}

function getSurveyParticipationSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveyParticipationSucceeded>
): SurveyStateType {
  let newState = {
    ...state
  }
  const participation = action.payload
  const { id: participationId } = participation
  newState = {
    ...newState,
    surveyParticipations: {
      ...newState.surveyParticipations,
      [participationId]: participation
    }
  }

  return newState
}

function setDeviceStateSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getDeviceStateSucceeded>
): SurveyStateType {
  const { patientUuid, deviceStates } = action.payload

  let newState = {
    ...state
  }
  deviceStates.forEach((deviceState) => {
    newState = {
      ...newState,
      devices: {
        ...newState.devices,
        [patientUuid]: {
          ...newState.devices[patientUuid],
          [deviceState.device]: deviceState
        }
      }
    }
  })

  return newState
}

function getDeviceDataSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getDeviceDataSucceeded>
): SurveyStateType {
  const { patientUuid, data, deviceName } = action.payload
  const newState = {
    ...state,
    deviceData: {
      ...state.deviceData,
      [patientUuid]: {
        ...state.deviceData[patientUuid],
        [deviceName]: data
      }
    }
  }

  return newState
}

function getAllStudiesSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getAllStudiesSucceeded>
): SurveyStateType {
  const { payload } = action
  const newState = {
    ...state,
    allStudies: payload
  }

  return newState
}

function createStudySucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.createStudySucceeded>
): SurveyStateType {
  const { payload } = action
  const newState = {
    ...state,
    allStudies: [...state.allStudies, payload]
  }

  return newState
}

function getDeviceDefinitionsSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getDeviceDefinitionsSucceeded>
): SurveyStateType {
  const { payload } = action
  const newState = {
    ...state,
    deviceDefinitions: payload
  }

  return newState
}

function getMySurveysSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getMySurveysSucceeded>
): SurveyStateType {
  const { payload } = action
  const newState = {
    ...state,
    mySurveys: payload
  }
  return newState
}

function getStudyParticipationsForPatientSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getStudyParticipationsForPatientSucceeded>
): SurveyStateType {
  const { patientUuid, participations } = action.payload
  const newState = {
    ...state,
    studyParticipations: {
      ...state.studyParticipations,
      [patientUuid]: participations
    }
  }

  return newState
}

function addStudyParticipantSuceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.addStudyParticipantSuceeded>
): SurveyStateType {
  const participation = action.payload
  const { patientUuid } = participation
  const currerntParticipations = state.studyParticipations[patientUuid] || []
  const newState = {
    ...state,
    studyParticipations: {
      ...state.studyParticipations,
      [patientUuid]: [...currerntParticipations, participation]
    }
  }

  return newState
}

function getSurveyResponsesSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveyResponsesSucceeded>
): SurveyStateType {
  const { participationId, responses } = action.payload
  const newState = {
    ...state,
    surveyResponses: {
      ...state.surveyResponses,
      [participationId]: responses
    }
  }

  return newState
}

function getStudySucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getStudySucceeded>
): SurveyStateType {
  const { study } = action.payload
  const newState = {
    ...state,
    studies: {
      [study.id]: study
    }
  }

  return newState
}

function updateStudySucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.updateStudySucceeded>
): SurveyStateType {
  const study = action.payload
  const newState = {
    ...state,
    studies: {
      [study.id]: study
    }
  }

  return newState
}

function getSurveyDefinitionByIdSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveyDefinitionsByIdSucceeded>
): SurveyStateType {
  const definitions = action.payload
  let newState = {
    ...state
  }

  definitions.forEach((def: any) => {
    const definition: SurveyDefinitionDtoType = def
    newState = {
      ...newState,
      surveyDefinitionsById: {
        ...newState.surveyDefinitionsById,
        [definition.id]: definition
      }
    }
  })
  return newState
}

function getSurveySpecSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveySpecSucceeded>
): SurveyStateType {
  const { participationId, spec } = action.payload

  const newState = {
    ...state,
    surveySpecsByParticipation: {
      ...state.surveySpecsByParticipation,
      [participationId]: spec
    }
  }

  return newState
}

function getSurveyStatisticSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveyStatisticSucceeded>
): SurveyStateType {
  const { patientUuid, surveyId, statistic } = action.payload
  return {
    ...state,
    surveyStatistic: {
      ...state.surveyStatistic,
      [String(patientUuid)]: {
        ...state.surveyStatistic[String(patientUuid)],
        [String(surveyId)]: statistic
      }
    }
  }
}

function getSurveyDefinitionsByPatientSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveyDefinitionsByPatientSucceeded>
): SurveyStateType {
  const { patientUuid, surveyDefinitions } = action.payload
  return {
    ...state,
    patientSurveyDefinitions: {
      ...state.patientSurveyDefinitions,
      [patientUuid]: surveyDefinitions
    }
  }
}

function updateSurveyDefinitionSucceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.updateSurveyDefinitionSucceeded>
): SurveyStateType {
  const definition = action.payload
  return {
    ...state,
    surveyDefinitionsById: {
      ...state.surveyDefinitionsById,
      [definition.id]: definition
    }
  }
}

function getSurveyParticipantsByPatientSuceeded(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveyParticipationsByPatientSucceeded>
): SurveyStateType {
  return {
    ...state,
    surveyParticipationsByPatient: [...action.payload]
  }
}

function getSurveyParticipationsReset(
  state: SurveyStateType,
  action: ExtractReturn<typeof actions.getSurveyParticipationsReset>
): SurveyStateType {
  let newState = {
    ...state
  }
  const data = action.payload
  newState = {
    ...newState,
    surveyParticipations: {
      ...newState.surveyParticipations,
      [data]: undefined
    }
  }

  return newState
}

const ACTION_HANDLERS: { [key: SurveyActionType]: Function } = {
  [types.GET_SURVEY_DEFINITIONS_SUCCEEDED]: getSurveyDefinitonsSucceeded,
  [types.GET_SURVEY_PARTICIPATIONS_SUCCEEDED]: getSurveyParticipationsSucceeded,
  [types.GET_SURVEY_PARTICIPATION_SUCCEEDED]: getSurveyParticipationSucceeded,
  [types.GET_DEVICE_STATE_SUCCEEDED]: setDeviceStateSucceeded,
  [types.GET_DEVICE_DATA_SUCCEEDED]: getDeviceDataSucceeded,
  [types.GET_ALL_STUDIES_SUCCEEDED]: getAllStudiesSucceeded,
  [types.CREATE_STUDY_SUCCEEDED]: createStudySucceeded,
  [types.GET_STUDY_PARTICIPATIONS_FOR_PATIENT_SUCCEEDED]: getStudyParticipationsForPatientSucceeded,
  [types.GET_SURVEY_RESPONSES_SUCCEEDED]: getSurveyResponsesSucceeded,
  [types.GET_MY_SURVEYS_SUCCEEDED]: getMySurveysSucceeded,
  [types.GET_STUDY_SUCCEEDED]: getStudySucceeded,
  [types.UPDATE_STUDY_SUCCEEDED]: updateStudySucceeded,
  [types.GET_SURVEY_DEFINITIONS_BY_ID_SUCCEEDED]: getSurveyDefinitionByIdSucceeded,
  [types.GET_SURVEY_STATISTIC_SUCCEEDED]: getSurveyStatisticSucceeded,
  [types.GET_SURVEY_DEFINITIONS_BY_PATIENT_SUCCEEDED]: getSurveyDefinitionsByPatientSucceeded,
  [types.GET_DEVICE_DEFINITIONS_SUCCEEDED]: getDeviceDefinitionsSucceeded,
  [types.GET_SURVEY_SPEC_SUCCEEDED]: getSurveySpecSucceeded,
  [types.UPDATE_SURVEY_DEFINITION_SUCCEEDED]: updateSurveyDefinitionSucceeded,
  [types.ADD_STUDY_PARTICIPANT_SUCCEEDED]: addStudyParticipantSuceeded,
  [types.GET_SURVEY_PARTICIPATIONS_BY_PATIENT_SUCCEEDED]: getSurveyParticipantsByPatientSuceeded,
  [types.RESET_PARTICIPATION_DATA]: getSurveyParticipationsReset
}
type ActionType = { type: SurveyActionType }
export default function surveysReducer(state: SurveyStateType = initialState, action: ActionType): SurveyStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
