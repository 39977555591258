// @flow
import { put, takeEvery, call, select, take, all, race } from 'redux-saga/effects'
import pathOr from 'ramda/src/pathOr'
import invariant from 'invariant'

import type { ExtractReturn, GenType, CommonStateType } from '../../../types'
import api from '../../../api'
import type { ConversationDtoType } from '../types/conversationDto'
import type { ConversationStateType } from '../reducers/messaging'
import type { MessageDtoType } from '../types/messageDto'
import { messagingActionTypes, messagingActions } from '../actions'
import { getPagesToLoad, pageSize } from './utils'
import isMuted from '../selectors/isMuted'
import getCoworkerSubscriber from '../selectors/getCoworkerSubscriber'
import { getKeycloakClient } from '../../../common/Keycloak/keycloakProvider'

export const delay = (timeout: number) => new Promise(resolve => setTimeout(() => resolve(true), timeout))

const getSecret = (state: Object, conversationId: number) =>
  pathOr('', ['messaging', 'conversations', conversationId, 'secret'], state)

type ConvsType = Array<ConversationDtoType & ConversationStateType>
function* loadMessagesGen(action: ExtractReturn<typeof messagingActions.loadMessagesRequested>): GenType {
  try {
    const conversationId = action.payload
    const conversations: ConvsType = yield select((state: CommonStateType) => state.messaging.conversations)
    let conversation = conversations[conversationId]
    let latestSequenceNo
    if (conversation === undefined) {
      yield take(messagingActionTypes.GET_SUBSCRIPTIONS_SUCCEEDED)
      conversation = conversations[conversationId]
    }

    if (conversation === undefined || conversation.messages.length === 0) {
      latestSequenceNo = 0
    } else {
      latestSequenceNo = Math.max.apply(null, conversation.messages.map(m => (m.sequenceNo ? m.sequenceNo : 0)))
    }

    const secret = yield select(getSecret, conversationId)
    const result = yield call(api.getMessages, conversationId, secret, latestSequenceNo)
    const messages = result.data // .slice(0, 20)

    yield put(messagingActions.loadMessagesSucceeded(messages))
  } catch (error) {
    yield put(messagingActions.loadMessagesFailed(error))
  }
}

const checkAllMessagesFromTheSameConversation = (messages: Array<MessageDtoType>) => {
  if (messages.length === 0) {
    return true
  }
  const [firstMessage] = messages
  const otherThanFirst = messages.filter(({ conversationId }) => conversationId !== firstMessage.conversationId)
  return otherThanFirst.length === 0
}

const getMaxSequenceNo = (messages: Array<MessageDtoType>) => {
  const latestSequenceNo = Math.max.apply(null, messages.map(m => (m.sequenceNo ? m.sequenceNo : 0)))
  return latestSequenceNo
}

/**
 * It takes a list of messages of the same conversation.
 * It finds the latest message and checks if all conditions are given to mark it as checked.
 * it will dispatch a succeeded message with that latest Message or empty if no servercall happens
 * @param {markAsCheckedRequested} action
 */
function* markAsCheckedRequested(action: ExtractReturn<typeof messagingActions.markAsCheckedRequested>): GenType {
  try {
    const messages: any = action.payload.messages.filter(m => Boolean(m))
    const { forced } = action.payload
    if (messages.length === 0) {
      yield put(messagingActions.markAsCheckedSucceeded([]))
      return
    }
    invariant(
      checkAllMessagesFromTheSameConversation(messages),
      'All messages have to belong to the same conversation. Use multiple markAsCheckedRequested'
    )

    const maxSequenceNo = getMaxSequenceNo(messages)
    const [{ conversationId = 0 }] = messages
    const conversation = yield select((state: CommonStateType) => state.messaging.conversations[conversationId])

    const { meta } = conversation
    const conversationUserRef = meta.userRef
    let [newestMessage] = messages.filter(m => m.sequenceNo === maxSequenceNo && m.meta.subtype !== 'conversationevent')
    if (!newestMessage) {
      [newestMessage] = messages.filter(m => m.sequenceNo === maxSequenceNo)
    }
    const isAssigned =
      messages.filter(m => m.sequenceNo === maxSequenceNo && m.meta.eventKey === 'assignToMe').length === 1

    const { userRef } = newestMessage

    const selectedConversationId = yield select((state: CommonStateType) => state.messaging.selectedConversation)
    const coworkers = yield select(getCoworkerSubscriber(conversationId))

    const isNotNotifications = conversation.type !== 'NOTIFICATION'
    const isNotRelatedToPatient = conversationUserRef === undefined
    const isRelatedToPatient = !isNotRelatedToPatient
    const mute = yield select(isMuted(conversationId))
    const userIsSender = getKeycloakClient().getUserId() === userRef
    const userIsPatient = getKeycloakClient().getUserId() === conversationUserRef
    const userIsNotPatient = !userIsPatient
    const senderIsCooworker = coworkers.includes(userRef)
    const messageFromCoworkerToCooworker = !userIsPatient && senderIsCooworker
    const isWatchingConversation = selectedConversationId === conversationId
    if (
      forced ||
      mute ||
      userIsSender ||
      (isAssigned && userIsNotPatient) ||
      (messageFromCoworkerToCooworker && isRelatedToPatient) ||
      (isWatchingConversation && userIsPatient && isNotNotifications) ||
      (isWatchingConversation && isNotRelatedToPatient && isNotNotifications)
    ) {
      // use markAsCheckedLocal to update lcoal state and dont wait for api call
      yield put(messagingActions.markAsCheckedLocal([newestMessage]))
      yield call(api.markAsChecked, conversationId, maxSequenceNo)
      yield put(messagingActions.markAsCheckedSucceeded([newestMessage])) //
    } else {
      yield put(messagingActions.markAsCheckedSucceeded([]))
    }
  } catch (error) {
    yield put(messagingActions.markAsCheckedFailed(error))
  }
}

function* loadOlderMessages(action: ExtractReturn<typeof messagingActions.loadOlderMessagesRequested>): GenType {
  try {
    const conversationId = action.payload
    const currentMessages = yield select(
      (state: CommonStateType) => state.messaging.conversations[conversationId].messages
    )
    const pagesToLoad = getPagesToLoad(currentMessages)
    const secret = yield select(getSecret, conversationId)
    const calls = yield all(pagesToLoad.map(page => call(api.getMessagePage, conversationId, secret, page, pageSize)))
    const messages = calls.map(response => response.data).reduce((allMessages, msgs) => [...allMessages, ...msgs], [])

    yield put(messagingActions.loadOlderMessagesSucceeded(messages))
  } catch (error) {
    yield put(messagingActions.loadOlderMessagesFailed(error))
  }
}

function* postMessageToServer(post, tries = 0): GenType {
  let cleanPost = { ...post, sequenceNo: undefined }
  if (cleanPost.mediaResource) {
    cleanPost = {
      ...cleanPost,
      mediaResource: {
        uuid: cleanPost.mediaResource.uuid
      }
    }
  }
  try {
    const secret = yield select(getSecret, post.conversationId)

    const response = yield call(api.postMessage, cleanPost, secret)
    const createdPost = response.data
    yield put(messagingActions.postMessageSucceeded(createdPost))
  } catch (e) {
    if (tries > 2) {
      yield put(messagingActions.postMessageFailed(post))
    } else {
      yield delay(1000)
      yield postMessageToServer(post, tries + 1)
    }
  }
}

function* postMessage(action: ExtractReturn<typeof messagingActions.postMessageRequested>): GenType {
  const { payload } = action
  yield postMessageToServer(payload)
}

function* markMessageAsChecked(action: ExtractReturn<typeof messagingActions.postMessageSucceeded>): GenType {
  const message = action.payload
  yield put(messagingActions.markAsCheckedRequested([message]))
}
function* markMessagesAsChecked(action: { payload: Array<MessageDtoType> }): GenType {
  const messages = action.payload
  yield put(messagingActions.markAsCheckedRequested(messages))
}

function* selectConversation(action: ExtractReturn<typeof messagingActions.selectConversation>) {
  const conversationId = action.payload
  if (!conversationId) return
  let conversation = yield select((state: CommonStateType) => state.messaging.conversations[conversationId])
  if (!conversation) {
    yield race({
      subsLoaded: take(messagingActionTypes.GET_SUBSCRIPTIONS_SUCCEEDED),
      timeout: call(delay, 3000)
    })

    conversation = yield select((state: CommonStateType) => state.messaging.conversations[conversationId])
    invariant(conversation, 'selectConversation - conversation needed to load messages')
  }

  if (conversation) {
    const latestMessage = yield select(
      (state: CommonStateType) => state.messaging.conversations[conversationId].latestMessage
    )

    yield put(messagingActions.markAsCheckedRequested([latestMessage]))
  }
  yield put(messagingActions.loadMessagesRequested(conversationId))
}

export default function messagesSaga(): Array<GenType> {
  return [
    takeEvery(messagingActionTypes.LOAD_OLDER_MESSAGES_REQUESTED, loadOlderMessages),
    takeEvery(messagingActionTypes.SELECT_CONVERSATION, selectConversation),
    takeEvery(messagingActionTypes.LOAD_MESSAGES_REQUESTED, loadMessagesGen),
    takeEvery(messagingActionTypes.POST_MESSAGE_REQUESTED, postMessage),
    takeEvery(messagingActionTypes.MARK_AS_CHECKED_REQUESTED, markAsCheckedRequested),
    takeEvery(messagingActionTypes.POST_MESSAGE_SUCCEEDED, markMessageAsChecked),
    takeEvery(messagingActionTypes.LOAD_MESSAGES_SUCCEEDED, markMessagesAsChecked),
    takeEvery(messagingActionTypes.LOAD_OLDER_MESSAGES_SUCCEEDED, markMessagesAsChecked)
  ]
}
