export default {
  en: {
    reset: 'Reset Tutorials',
    reseted: 'All tutorials were reseted successfully.',
    tours: {
      home: [
        {
          title: 'Welcome to Docdok',
          content: `These boxes will help you to get used to the application.
            they are all over the place. Press "Ok" to continue`
        },
        {
          title: 'Tiles',
          content: `These tiles on the home screen are short cuts to the most used places.
            Click on this one for example to navigate to your patients overview`
        },
        {
          title: 'Search',
          content: `This is the global search.
            You can use this at anytime to directly navigate to a patient profile`
        },
        {
          title: 'Add Patient',
          content: 'With this button you could create a new patient in the system.'
        },
        {
          title: 'Notifications',
          content: `Here you will see notifcations about events in the application
            You will be informed about completed surveys for example.`
        },
        {
          title: 'Start Tutorials',
          content:
            'If you ever feel lost on a page you can push this button and '
            + 'the Tutorials will guide you through the site like this one'
        },
        {
          title: 'Logout',
          content: `To ensure the security of the system
            you should log your self out when leaving the computer.
            Click here and then choose "Logout".`
        },
        {
          title: 'Billing',
          content: `Select this option to download an Excel file listing
            all of the billing events you have logged in docdok when communicating
            with patients. You can use data in the export as a comparison list to
            ensure that the appropriate billing has taken place in your EMR or to get
            an overview of how much billable work you are doing in docdok.`
        },
        {
          title: 'Customer Support',
          content: `If you facing any issues please do not hesitate to contact our customer support.
            Just click this button to send an email with your request.
            If you have no email-client installed copy this adresse:
            supportdesk@docdok.atlassian.net`
        },
        {
          title: 'Your Profile',
          content: 'A good place to start is your Profile. Click on it to set it up.'
        }
      ],
      messages: [
        {
          title: 'Create Conversation',
          content: `Use this to create conversations
           for your internal team communication.
           Give it a title and add some of your colleages`
        },
        {
          title: 'Edit or leave conversation',
          content: `If you want to leave or edit a conversation click on the 3 dots
            and choose the corresponding action.`
        }
      ],
      profile: [
        {
          title: 'Profile Picture',
          content: `Click here to add a profile picture so your
            patients can see your photo during conversations`
        },
        {
          title: 'Billing Reminder',
          content: `When you check this box, you will receive reminders to bill your patients
            during a consultation in case you forget to start billing
            You can allways come back here to change the option`
        },
        {
          title: 'Availability Message',
          content: `Click here to define your and the clinics availability.
            Those informations are shown to the patients on the app.`
        }
      ],
      patients: [
        {
          title: 'Patients Overview',
          content: `This is the Patient Overview.
            In the table below do you see all the patients in your clinic`
        },
        {
          title: 'Search',
          content: `Click on the loupe to search for a patient.
            The search includes salutation, firstname and lastname.`
        },
        {
          title: 'App activated',
          content: `In this column you see if a patient has downloaded the app
            and logged into it at least once.
            If this is the case he should receive all your messages, survey etc.`
        },
        {
          title: 'Renew Invitation',
          content: `Hover your mouse here to see the actions.
            If your patient did not activate the app yet he may simply just forgot about it.
            Click this button to resend the invitation.`
        },
        {
          title: 'Add to Study',
          content: `Hover your mouse here to see the actions.
           If you are part of a Study you can add Patients to the study with this button.
           You also find similar functionality in the Studien-Interface.`
        }
      ],
      patient: [
        {
          title: 'Patient Profile',
          content: `This is the patients profile.
          Here you see all the relevant infos about the patient.
          Click on his name to see more details`
        },
        {
          title: 'Surveys',
          content: 'In this tab you see all the surevs you sent a patient.'
        },
        {
          title: 'Integration',
          content: `With the tab "Integration" you have the possiblity to integrate a device
            like Fitbit into the profil of the patient. With this you could monitor the
            daily steps of a patient for example.`
        }
      ],
      patientSurvey: [
        {
          title: 'Send Survey',
          content: `Click on "Send a Survey" to directly send a survey or start a protocol,
            which are send over a longer period of time, to a patient.`
        },
        {
          title: 'Surveys',
          content: `In this table you see all the surveys which were sent to a patient.
            You have the option to start a survey directly on the row an fill it out together
            with your patient.
            Or the patient fills out the survey at home with the Docdok-App.
            Additionally you also have the possability to look at the patients answers.`
        },

        {
          title: 'Analytics',
          content: `With the button "Analytics" you can see statistics about a Survey.
            This is very handy together with protocols.`
        }
      ],
      surveys: [
        {
          title: 'Survy Overview',
          content: `This is the Survey Overview.
            In the table below you see all the surveys which can be sent`
        },
        {
          title: 'Survey',
          content: `Select the surveys you want to send and then click
            on "Send a Survey". Alternatively you can click directly on the arrow of a survey.`
        },
        {
          title: 'Number of sent Surveys',
          content: `Here you see how often you sent this survey.
            Click on the number to see which patients have participated in the this survey.`
        }
      ],
      chat: [
        {
          title: 'Chat',
          content: `The direct messages channel is where you and others at your clinic
            can communicate directly with the patient in real time.
            Whenever a patient sends the clinic a new message via this conversation,
            anyone who is part of the conversation will see a red "badge"
            indicating no one has read the message yet.
            When anyone responds to the patient, the red badge will disappear for everyone else.`
        },
        {
          title: 'Attention Request',
          content: 'With this button you can notify another person to look into the patients chat'
        },
        {
          title: 'Mute Conversation',
          content:
            'With this button you can mute the conversation, '
            + 'you will no longer get emails and notifications if something happens in this chat.'
        },
        {
          title: 'Add People',
          content: 'With this button you can add more people from you team to the chat.'
        },
        {
          title: 'Conversation Viewed',
          content: `If you have already seen the patient's message and
            it does not require a chat response,
            click the 'Conversation Viewed' button to remove the unread messages badge
            from your coworkers so they know they do not need to read it too.`
        }
      ],
      timer: [
        {
          title: 'Tarmed-Code',
          content: 'Here you see on which code you currently booking your time.'
        },
        {
          title: 'Start/Stop Billing',
          content: `With this Button you can start or stop the billing for a
            person in the current context.`
        }
      ]
    }
  },
  de: {
    reset: 'Tutorials zurücksetzen',
    reseted: 'Alle Tutorials wurden erfolgreich zurück gesetzt.',
    tours: {
      home: [
        {
          title: 'Willkommen bei docdok',
          content: `Diese Tutorialboxen helfen Ihnen, mit der Applikation vertraut zu werden.
            Sie werden diese an verschiedenen Stellen in der Applikation antreffen.
            Drücken Sie "Ok" um vorzufahren.`
        },
        {
          title: 'Kacheln',
          content: `Diese Kacheln sind Verknüpfungen zu den meist
            genutzten Bereichen der Applikation. Klicken Sie beispielsweise auf diese
            Kachel, um zur Übersicht der Patienten zu gelangen.`
        },

        {
          title: 'Suche',
          content: `Das hier ist die Globale Patienten Suche.
            Hier können Sie jederzeit nach einem bestimmten Patienten suchen
            und direkt zu dessen Profil navigieren.`
        },
        {
          title: 'Patient Hinzufügen',
          content: `Mit diesem Button können Sie einen neuen Patienten im System
            erfassen.`
        },
        {
          title: 'Benachrichtigungen',
          content: `Hier sehen Sie ihre Benachrichtigungen und Events im System.
          Sie werden beispielsweise Informiert, wenn ein Patient eine Umfrage ausgefüllt hat.`
        },
        {
          title: 'Tutorials Starten',
          content: `Mit diesem Button können Sie die Tutorials jederzeit starten,
            so können Sie sich die wichtigsten Aspekte auf der Seite erklären lassen.`
        },
        {
          title: 'Abmelden',
          content: `Um die Sicherheit des Systems zu gewährleisten sollten Sie sich,
            wenn Sie den Computer verlassen, abmelden.
            Klicken Sie dazu zuerst hier und dann auf "Abmelden".`
        },
        {
          title: 'Abrechnung',
          content: `Wählen Sie diese Option,
            um eine Excel-Datei mit allen über die Kommunikation mit Ihren Patienten
            generierten Abrechnungsereignissen herunterzuladen.
            Sie können die Daten im Export zum Vergleich mit der
            entsprechenden Abrechnung in Ihrem Praxisinformationssystem
            verwenden oder um einen Überblick darüber zu erhalten,
            wie viel abrechenbare Arbeit Sie über docdok erledigt haben.`
        },
        {
          title: 'Kundendienst',
          content: `Haben Sie irgendwelche Probleme mit der Applikation
          zögern Sie nicht unseren Kundendienst zu kontaktieren.
          Klicken Sie hierzu einfach auf diesen Button um ein Email zu senden.
          Falls Sie kein Email-Client installiert haben kopieren Sie diese Email-Adresse:
          supportdesk@docdok.atlassian.net`
        },
        {
          title: 'Dein Profil',
          content: `Ein guter Ort um mit der Applikation zu starten, ist Ihr eigenes Profil.
            Klicken Sie auf den Button und passen Sie das System ihren Bedürfnissen an.`
        }
      ],
      messages: [
        {
          title: 'Konversation Erstellen',
          content: `Benutzen Sie diesen Button um neue Chats für die interne
            Kommunikation zu erstellen.
            Geben Sie dem Chat einen Namen und fügen Sie Teamkollegen hinzu.`
        },
        {
          title: 'Konversation anpassen und verlassen',
          content: `Um eine Konversation zu verlassen oder anzupassen
            klicken Sie auf die 3 Punkte und wählen die entsprechende Option.`
        }
      ],
      profile: [
        {
          title: 'Profil Bild',
          content: `Klicken Sie hier um ihr Profilbild zu ändern,
           damit die Patienten sehen mit wem Sie sich unterhalten.`
        },
        {
          title: 'Verrechnungs Erinnerung',
          content: `Wenn Sie dieses Kontrollkästchen aktivieren,
            wird das System Sie daran erinnern,
            Konsultationen mit Ihren Patienten zu verrechnen sollten Sie vergessen,
            die Verrechnung zu aktivieren.
            Sie können jederzeit hierher zurückkehren um die Einstellung zu ändern.`
        },
        {
          title: 'Verfügbarkeits Nachrichten',
          content: `Klicken Sie hier um die Verfügbarkeit von Ihnen und Ihrer Klinik zu definieren.
            Diese Informationen werden dem Patienten in der App angezeigt.`
        }
      ],
      patients: [
        {
          title: 'Patienten Übersicht',
          content: `Das hier ist die Patienten Übersicht.
            In der Tabelle unten finden Sie alle Patienten in Ihrer Klinik`
        },
        {
          title: 'Suche',
          content: `Klicken Sie auf die Lupe um nach einem Patienten zu suchen.
            In der Suche eingeschlossen sind Anrede, Vorname und Nachname.`
        },
        {
          title: 'App aktiviert',
          content: `In dieser Spalte sehen sie ob der Patient die App installiert
            und sich mindestens einmal eingloggt hat. Ist dies der Fall, sollte er
            alle Ihre Nachrichten, Surveys etc. erhalten.`
        },
        {
          title: 'Einladung erneuern',
          content: `Bewergen Sie den Mauszeiger hier hin um die Aktionen zu sehne.
            Falls ein Patient die App noch nicht aktiviert hat, kann es daran liegen,
            dass die Einladung vergessen gegagnen ist.
            Klicken Sie hier um die Einladung erneut zu verschicken.`
        },
        {
          title: 'Zu Studie hinzufügen',
          content: `Bewergen Sie den Mauszeiger hier hin um die Aktionen zu sehne.
            Wenn Sie Teil einer Studie sind können sie Patienten mit diesem Button einer
            Studie hinzufügen.
            Die selbe Funktionalität finden Sie auch direkt im Studien-Interface.`
        }
      ],
      patient: [
        {
          title: 'Patienten Profil',
          content: `Das ist das Patienten Profil.
            Hier finden Sie alle relevanten Informationen über einen Patienten.
            Klicken Sie auf den Namen um weitere Informationen zu sehen.`
        },
        {
          title: 'Umfragen',
          content: `Unter diesem Umfragen Register finden Sie alle Umfragen,
            welche einem Patienten gesendet wurden.`
        },
        {
          title: 'Integration',
          content: `Über das Register "Integration" ist es Ihnen möglich Geräte,
            wie Fitbit in das Profil zu integrieren. So können Sie beispielsweise
            die täglichen Schritte eines Patient überwachen.`
        }
      ],
      patientSurvey: [
        {
          title: 'Umfrage Senden',
          content: `Klicken Sie auf "Versende eine Umfrage" ein Umfrage direkt an
          Ihren Patienten zu senden oder ein Protokoll, welches der Patient über eine
          längeren Zeitrahmen erhält, zu staten`
        },
        {
          title: 'Umfragen',
          content: `In dieser Tabelle sehen Sie die Liste aller Umfragen,
            welche der Patient erhalten hat.
            Sie haben die Möglichkeit auf einer Zeile ein Umfragen direkt zu starten und
            zusammen mit dem Patienten auszufüllen.
            Oder aber der Patient füllt die Umfrage in Ruhe zuhause via der Docdok-App aus.
            Zusätzlich haben Sie auch die Möglichkeit die Antworten direkt anzuschauen.`
        },

        {
          title: 'Analyse',
          content: `Mit dem Button "Analyse" können Sie Statistiken über bestimmte Surveys einsehen.
            Dies ist vor allem in der Verwendung mit Protokollen sehr nützlich.`
        }
      ],
      surveys: [
        {
          title: 'Umfragen Übersicht',
          content: `Das hier ist die Umfragen Übersicht.
            In der Tabelle unten finden Sie alle Umfragen, welche Sie versenden können`
        },
        {
          title: 'Umfragen',
          content: `Selektieren Sie die Umfragen, welche Sie gerne versenden würden und klicken
            Sie dann auf "Versende eine Umfrage". Alternativ können Sie auch direkt auf den Pfeil
            auf einer Zeile Klicken. `
        },
        {
          title: 'Anzahl versendeter Umfragen',
          content: `Hier sehen Sie wie diese Umfrage verschickt wurde. Klicken Sie auf die Zahl
            um die Teilnehmer der Umfrage zu sehen.`
        }
      ],
      chat: [
        {
          title: 'Chat',
          content: `Über den Kommunikationskanal können Sie und andere in Ihrer
            Klinik in Echtzeit direkt mit dem Patienten kommunizieren.
            Immer wenn der Patient der Klinik in dieser Konversation eine Nachricht sendet,
            sehen alle Konversationsteilnehmer ein rotes Kennzeichen.
            Es wird damit angezeigt, dass noch niemand die Nachricht gelesen hat.
            Sobald jemand dem Patienten antwortet, verschwindet für alle anderen
            Konversationsteilnehmer dieses rote Kennzeichen.`
        },
        {
          title: 'Benachrichtigung versenden',
          content: `Mit diesem Button können sie eine Benachrichtigung
            an einen anderen Kollegen verschicken,
            damit dieser einen Blick in diese Konversation wirft.`
        },
        {
          title: 'Konversation Stumm schalten',
          content: `Mit diesem Button können sie die Konversation stumm schalten.
            Sie werden keine Emails und Benachrichtigungen mehr erhalten,
            wenn etwas in diesem Chat passiert.`
        },
        {
          title: 'Teilnehmer hinzufügen',
          content: 'Mit diesem Button können Sie weitere Teilnehmer dem Chat hinzufügen.'
        },
        {
          title: 'Konversation gesehen',
          content: `Wenn Sie die Nachricht des Patienten bereits gesehen haben
            und keine Antwort an den Patienten erforderlich ist,
            klicken Sie auf die Schaltfläche "Konversation gesehen",
            um das Kennzeichen für ungelesene Nachrichten von Ihren Arbeitskollegen zu entfernen,
            damit diese wissen, dass sie die Nachricht nicht lesen müssen.`
        }
      ],
      timer: [
        {
          title: 'Tarmed-Code',
          content: 'Hier sehen sie auf welchen Tarmed-Code sie die aktuelle Zeit buchen.'
        },
        {
          title: 'Verrechnung starten/stoppen',
          content: `Mit diesem Button können Sie die Verrechnug für einen Patienten starten
            oder stoppen. Wenn Sie die Maus oder die Tastatur für länger als 30
            Sekunden nicht betätigen, wird die Verrechnung automatisch pausiert.`
        }
      ]
    }
  }
}
