/**
 * How to use it from the redux store declaration:
 * 1. let say you have a function called configureStore
 *    which create store with your own middleware, etc.
 * 2. Use PersistStore.persistReducer and PersistStore.init functions
 *    into your configuration of your redux store
 * 3. This should be like this:
 * ```javascript
  const configureStore = async () => {
  const store = createStoreWithMiddleware(
    PersistStore.persistReducer(reducers)
  )
  setKeycloakImpl(getCommonKeycloakClient(store))
  await PersistStore.init({
    version: '1.0.0',
    environment: 'qa',
    dev: __DEV__,
    keys: ['user', 'messaging'], // could be a function as well ({user,messaging})=>({user, messaging})
    storage: AsyncStorage,
    reduxStore: store,
    actions: {
      start: 'GET_ME_SUCCEEDED',
      stop: 'LOGOUT_REQUESTED'
    },
  })
  sagaMiddleware.run(sagas)
  return store
}
```
 * And you should add this to your root saga:
 * ```javascript
   ...PersistStore.persistSagas(),
 ```
 */
// @flow

import { put, call, takeLatest } from 'redux-saga/effects'
import throttle from 'lodash.throttle'
import pick from 'ramda/src/pick'
import propOr from 'ramda/src/propOr'
import pathOr from 'ramda/src/pathOr'
import mergeDeepRight from 'ramda/src/mergeDeepRight'
import invariant from 'invariant'
import Base64 from 'base-64'
import { readable, unreadable } from '../utils/crypto'

import type { LocalStorageType } from '../utils/localStorage'
import { getLocalStorage } from '../utils/localStorage'
import { getKeycloakClient } from '../common/Keycloak/keycloakProvider'
import type { KeycloakClientType } from '../common/Keycloak/keycloakProvider'
import constants from '../utils/constants'

declare var __DEV__: boolean

export type PersistStoreOptionType = {
  name: string, // use into the global key
  version: string, // use into the global key
  environment: 'qa' | 'production' | 'it' | 'staging', // use into the global key
  dev?: boolean, // if true warn logging activated
  secret?: string, // use for encrypted
  select: Array<string> | ((state: Object) => Object), // list of the keys you want to store into local storage
  storage?: LocalStorageType, // ie. AsyncStorage, SecureStore or window.localStorage
  reduxStore: {
    subscribe: Function,
    getState: Function
  },
  actions: {
    start: string, // Action to start it
    stop: string // Action to stop it
  },
  throttleMs?: number // do not update every time but every one second if state change
}
type ReducerType = (state: Object, action: { type: string }) => Object

export const INJECT_ACTION = 'INJECT_STATE_FROM_PERSIST'

// eslint-disable-next-line
const warn = __DEV__ ? console.warn : text => text

let keycloakClient: ?KeycloakClientType
let unsubscribeListener: ?Function

export const makeBlurSecret: string => string = (secret: string) =>
  Base64.encode(
    [...secret]
      .reverse()
      .join('~')
      .toLowerCase()
  )

const DEFAULT_START_ACTION: string = 'START_PERSIST'
const DEFAULT_STOP_ACTION: string = 'STOP_PERSIST'

const options: PersistStoreOptionType = {
  name: 'dcdk',
  version: 'undefined',
  environment: 'qa',
  dev: false,
  secret: makeBlurSecret('docdok.health AG'),
  select: [],
  reduxStore: {
    subscribe: () => invariant(false, 'reduxStore is not set'),
    getState: () => invariant(false, 'reduxStore is not set')
  },
  actions: {
    start: DEFAULT_START_ACTION,
    stop: DEFAULT_STOP_ACTION
  },
  throttleMs: 1000
}

/**
 * Action you can use with default actions configuration
 * @class Actions
 */
export class PersistStoreActions {
  static startPersistStore = () => ({
    type: DEFAULT_START_ACTION
  })

  static stopPersistStore = () => ({
    type: DEFAULT_STOP_ACTION
  })
}

class PersistStore {
  /**
   * WARNING! Call into configureStore function!
   * Initialize PersistStore: option and localStorage.
   * @static
   * @param {PersistStoreOptionType} opts
   * @returns {PersistStoreOptionType}
   * @memberof PersistStore
   */
  static async init(opts: PersistStoreOptionType): Promise<PersistStoreOptionType> {
    invariant(opts, 'options should be defined')
    invariant(typeof opts === 'object', 'options should be an object')
    keycloakClient = getKeycloakClient()
    Object.assign(options, {
      ...opts,
      storage: opts.storage ? opts.storage : getLocalStorage(),
      secret: opts.secret ? makeBlurSecret(opts.secret) : options.secret,
      actions: {
        ...options.actions,
        ...opts.actions
      }
    })
    return options
  }

  /**
   * Give state to be store.
   * It will be store under nested object refer by user ref key.
   * @static
   * @return Promise<void>
   * @param {Object} [state={}]
   * @memberof PersistStore
   */
  static async saveState(state: Object = {}): Promise<Object> {
    const { storage, select, secret } = options
    invariant(typeof state === 'object', 'state should be an object')
    invariant(storage, 'option.storage should be defined')
    invariant(select, 'option.select should be defined')
    invariant(secret, 'option.secret should be defined')
    let data
    if (Array.isArray(select)) {
      data = pick(select, state)
    } else {
      data = select(state)
    }
    const now: Date = new Date()
    const nowString: string = now.toISOString()
    const createdAt: string = nowString
    const updatedAt: string = nowString

    try {
      const allStore = await PersistStore.getAllStore()
      const goodCreatedAt = pathOr(createdAt, [PersistStore.getUserRefKey(), 'createdAt'], allStore)
      const newKey = {
        [PersistStore.getUserRefKey()]: {
          data,
          createdAt: goodCreatedAt,
          updatedAt
        }
      }
      const willPersist = allStore ? { ...allStore, ...newKey } : { ...newKey }
      const serializedState = JSON.stringify(willPersist)
      storage.setItem(PersistStore.getCurrentLocalStorageKey(), unreadable(serializedState, secret))
      return data
    } catch (err) {
      // Ignore write errors.
      warn(`[persist.saveState] catch error: ${err.message} \n ${err.stack}`)
      return {}
    }
  }

  /**
   * Get all store keys and associated objects
   * @static
   * @returns {Promise<?Object>} the entire persisted store with all users in it
   * @memberof PersistStore
   */
  static async getAllStore(): Promise<?Object> {
    const { storage, secret } = options
    invariant(storage, 'no storage impl found: set the implemeation on init or with the setLocalStorage function')
    const persisted = await storage.getItem(PersistStore.getCurrentLocalStorageKey())
    if (!persisted) {
      warn('[persist.loadStore] persisted undefined')
      return {}
    }

    return JSON.parse(readable(persisted, secret))
  }

  /**
   * This async function should be call from configureStore function
   * like this, return only the user state
   * ```javascript
     const store = createStoreWithMiddleware(
       reducers, await PersistStore.loadStore()
    )
   * ```
   * @static
   * @returns {Promise<void>}
   * @memberof PersistStore
   */
  static async loadStore(): Promise<?Object> {
    const { storage } = options
    invariant(storage, 'options.storage should be defined')
    try {
      const allStore = await PersistStore.getAllStore()
      const userRef = PersistStore.getUserRefKey()
      const currentStore = propOr({}, userRef, allStore)

      if (!currentStore.data) {
        warn(`[loadStore] not found ${userRef}`)
        return {}
      }

      return currentStore.data
    } catch (err) {
      warn(`[persist.loadStore] catch error: ${err}`)
      return {}
    }
  }

  /**
   * Return the current user ref
   * @static
   * @returns {string} user ref or 0 uuid
   * @memberof PersistStore
   */
  static getUserRefKey(): string {
    return keycloakClient && keycloakClient.getUserId() ? keycloakClient.getUserId() : constants.UNKNOWN_USER_REF
  }

  /**
   * This function should be call only once on configuration.
   * And just after creation of the store.
   * ```javascript
      const store = createStoreWithMiddleware(
        reducers,
        await PersistStore.loadStore()
      )
      PersistStore.startPersistStore(store)
   * ```
   * @static
   * @returns {string} user ref or 0 uuid
   * @memberof PersistStore
   */
  static startPersistStore(): void {
    const { reduxStore, throttleMs } = options
    invariant(reduxStore, 'reduxStore is not set')
    // not start if already started
    if (!unsubscribeListener) {
      unsubscribeListener = reduxStore.subscribe(
        throttle(() => PersistStore.saveState(reduxStore.getState()), throttleMs)
      )
    }
  }

  /**
   * This function unsubscribe PersistStore listener.
   * @static
   * @memberof PersistStore
   */
  static stopPersistStore() {
    if (unsubscribeListener) {
      unsubscribeListener()
      unsubscribeListener = undefined
    }
  }

  /**
   * Generate the key on local storage
   * @return {string} the key on local storage
   * @static
   * @memberof PersistStore
   */
  static getCurrentLocalStorageKey(): string {
    const { name, environment, version } = options
    return `${name}-${environment}-${version}`
  }

  /**
   * A reducer to change state when needed.
   * Use it when configurate store
   * @param {ReducerType}
   * @return {ReducerType}
   * @static
   * @memberof PersistStore
   */
  static persistReducer = (reducer: ReducerType) => (
    state: Object,
    action: { type: string, payload?: Object }
  ): Object => {
    switch (action.type) {
      // from persisted to memory store
      case INJECT_ACTION: {
        if (action.payload && action.payload.nextState) {
          const { nextState } = action.payload
          return reducer(mergeDeepRight(state, nextState), action)
        }
        return reducer(state, action)
      }

      default:
        return reducer(state, action)
    }
  }

  /**
   * Stop persisting
   * @static
   * @memberof PersistStore
   */
  static stopSaga = function* stopSagaFn(): Generator<*, *, *> {
    try {
      yield call(PersistStore.stopPersistStore)
    } catch (error) {
      yield put({
        type: 'STOP_PERSIST_STORE_FAILED',
        message: error.message,
        error
      })
    }
  }

  /**
   * Start persisting and trigger inject state action
   * @static
   * @memberof PersistStore
   */
  static startSaga = function* startSagaFn(): Generator<*, *, *> {
    try {
      const nextState = yield call(PersistStore.loadStore)
      yield put({
        type: INJECT_ACTION,
        payload: { nextState }
      })
      yield call(PersistStore.startPersistStore)
    } catch (error) {
      yield put({
        type: 'START_PERSIST_STORE_FAILED',
        message: error.message,
        error
      })
    }
  }

  /**
   * Configuration function for redux-sagas
   * @static
   * @return {Array<*>} sagas arrays need to be add on general sagas
   */
  static persistSagas(): Array<*> {
    invariant(options.actions, 'persistSagas: actions should be defined')
    invariant(options.actions.start, 'persistSagas: actions.start should be defined')
    invariant(options.actions.stop, 'persistSagas: actions.stop should be defined')
    const { start, stop } = options.actions
    return [takeLatest(start, PersistStore.startSaga), takeLatest(stop, PersistStore.stopSaga)]
  }
}

export default PersistStore
