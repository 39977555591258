// @flow
import api from 'common-docdok/src/api'
import hopscotch from 'hopscotch/dist/js/hopscotch'
import { messagingActionTypes } from 'common-docdok/src/domain/messaging/actions'
import { I18n } from 'react-redux-i18n'
import { all, call, put, race, select, take, takeEvery } from 'redux-saga/effects'
import type { ExtractReturn, GenType } from 'common-docdok/src/types'
import { userActionTypes } from 'common-docdok/src/domain/user/actions'
import { USER_IS_IDLE, USER_IS_NO_MORE_IDLE } from '../../../store/idleDetector'
import { pullGetParameter, downloadSVC } from '../../utils'

import { billingActions, billingActionTypes } from '../actions'
import { isActionVisible } from '../../Security/containers/action'
import { BILLING } from '../../Security/actionNames'
import { selectedPatientActionTypes } from '../../SelectedPatient/actions'

export const delay: any = (timeout: number) => new Promise(resolve => setTimeout(() => resolve(true), timeout))

const delayToReminder = 5 * 1000
const delayBetweenTicks = 10 * 1000

function* getContext(): GenType {
  return yield select((state: StoreType) => state.billing.context)
}

function* getPhysicianTypes(): GenType {
  return yield select((state: StoreType) => state.billing.physicianTypes)
}

function* getBillingPositions(context: string): GenType {
  return yield select((state: StoreType) => state.billing.billingPositions[context])
}

function* isPaused(): GenType {
  return yield select((state: StoreType) => state.billing.paused)
}

function* isActive(): GenType {
  return yield select((state: StoreType) => state.billing.active)
}

function* startBillingAccepted({ payload }: ExtractReturn<typeof billingActions.startBillingAccepted>): GenType {
  const { position, additionalPos } = payload
  let { code } = position
  if (additionalPos) {
    code += `,${additionalPos.serverCode}`
  }

  yield put(billingActions.tickBillingRequested(code))
  const runTimer = isActionVisible(BILLING)
  if (!runTimer) return
  while (true) {
    const winner = yield race({
      stopped: take(billingActionTypes.STOP_BILLING),
      tick: call(delay, delayBetweenTicks)
    })

    if (winner.stopped) {
      break
    } else {
      const isP = yield isPaused()
      if (!isP) {
        yield put(billingActions.tickBillingRequested(code))
      }
    }
  }
}

function* initShowBillingReminderFromPrefs(action: any): Generator<*, *, *> {
  // this is a url parameter for automated tests to prevent the billing popup
  const isBillingDisabled = Boolean(pullGetParameter('disableAutomatedBilling'))
  if (isBillingDisabled) {
    yield put(billingActions.setBillingReminder(false))
  } else {
    const preferences = action.payload.preferences.values
    const showBillingReminder = preferences ? preferences.showBillingReminder : undefined
    if (showBillingReminder !== undefined) {
      yield put(billingActions.setBillingReminder(showBillingReminder))
    }
  }
}

function* getShowReminder() {
  return yield select((state: StoreType) => state.billing.showReminder)
}

function* setContext(newContext: ?string): GenType {
  const currentContext = yield getContext()

  if (newContext !== currentContext) {
    yield put(billingActions.stopBilling())
    yield put(billingActions.setBillingContext(newContext))
    const winner = yield race({
      stopped: take(billingActionTypes.STOP_BILLING),
      started: take(billingActionTypes.START_BILLING_ACCEPTED),
      reminderTime: call(delay, delayToReminder)
    })

    while (hopscotch.getCurrTour()) {
      // we wait until the tour has finsihed
      yield call(delay, 100)
    }
    const showReminder = yield getShowReminder()
    const isP = yield isPaused()
    if (winner.reminderTime && showReminder && isActionVisible(BILLING) && !isP) {
      yield put(billingActions.startBillingProposed())
    }
  }
}

function* getSelectedPat() {
  return yield select((state: StoreType) => state.selectedPatient)
}
function* getSelectedConv() {
  return yield select((state: StoreType) => state.messaging.selectedConversation)
}

// Start Timer when selecting conversation or patient

function* selectConv({ payload }): GenType {
  const conversationId = payload

  if (conversationId) {
    let conv
    const getConversation = (state: StoreType) => state.messaging.conversations[conversationId]

    while (conv === undefined || conv.meta === undefined) {
      conv = yield select(getConversation)
      if (!conv) {
        yield call(delay, 100)
      }
    }
    const newContext = conv.meta.healthcareSubject

    yield setContext(newContext)
  } else {
    const selectedPatient = yield getSelectedPat()
    if (!selectedPatient) {
      yield setContext(undefined)
    }
  }
}

function* selectPat({ payload }): GenType {
  const pat = payload
  if (pat) {
    yield setContext(pat)
  } else {
    const selectedConv = yield getSelectedConv()
    if (!selectedConv) {
      yield setContext(undefined)
    }
  }
}

function* tickBillingRequested({ payload }: ExtractReturn<typeof billingActions.tickBillingRequested>): GenType {
  try {
    const context = yield getContext()
    if (context) {
      yield call(api.billPatient, context, payload)
      yield put(billingActions.tickBillingSucceded())
    } else {
      yield put(billingActions.tickBillingFailed(new Error('No Context for billing')))
    }
  } catch (error) {
    yield put(billingActions.tickBillingFailed(error))
  }
}

function* checkContext(): GenType {
  yield put(billingActions.resumeBilling())
  const selectedConv = yield getSelectedConv()
  const selectedPat = yield getSelectedPat()
  if (selectedPat) {
    yield selectPat({ payload: selectedPat })
  } else if (selectedConv) {
    yield selectConv({ payload: selectedConv })
  }
}

function* pauseTimer(): GenType {
  const active = yield isActive()
  if (active) yield put(billingActions.pauseBilling())
}

export function* downloadBillingRequested(): GenType {
  try {
    const result = yield call(api.downloadBilling)
    const timeStamp = I18n.l(Date.now(), { dateFormat: 'date.stamp' })
    const fileName = I18n.t('billing.fileName')
    downloadSVC(`${fileName}_${timeStamp}.csv`, result.data)
    yield put(billingActions.downloadBillingSucceeded())
  } catch (error) {
    yield put(billingActions.downloadBillingFailed(error))
  }
}

function* loadDialogData() {
  const billingContext = yield getContext()
  if (billingContext) {
    const physicianTypes = yield getPhysicianTypes()
    if (physicianTypes.length === 0) {
      yield put(billingActions.getPhysicianTypesRequested())
    }
    const tarmedInfo = yield getBillingPositions(billingContext)
    if (!tarmedInfo) {
      yield put(billingActions.getBillingPoisitionsRequested(billingContext))
    }
  }
}

function* getPhysicianTypesRequested() {
  try {
    const result = yield call(api.getPhysicianTypes)
    yield put(billingActions.getPhysicianTypesSucceeded(result.data))
  } catch (error) {
    yield put(billingActions.getPhysicianTypesFailed(error))
  }
}

function* getBillingPositionsRequested({
  payload
}: ExtractReturn<typeof billingActions.getBillingPoisitionsRequested>) {
  try {
    const result = yield call(api.getBillingPositions, payload)
    yield put(billingActions.getBillingPoisitionsSucceeded(payload, result.data))
  } catch (error) {
    yield put(billingActions.getBillingPoisitionsFailed(error))
  }
}

export default function* billingRootSaga(): GenType {
  yield all([
    takeEvery(userActionTypes.PROFILE_FETCH_SUCCEEDED, initShowBillingReminderFromPrefs),
    takeEvery(USER_IS_NO_MORE_IDLE, checkContext),
    takeEvery(USER_IS_IDLE, pauseTimer),
    takeEvery(messagingActionTypes.SELECT_CONVERSATION, selectConv),
    takeEvery(selectedPatientActionTypes.SELECT_PATIENT, selectPat),
    takeEvery(billingActionTypes.START_BILLING_ACCEPTED, startBillingAccepted),
    takeEvery(billingActionTypes.TICK_BILLING_REQUESTED, tickBillingRequested),
    takeEvery(billingActionTypes.DOWNLOAD_BILLING_REQUESTED, downloadBillingRequested),
    takeEvery(billingActionTypes.START_BILLING_PROPOSED, loadDialogData),
    takeEvery(billingActionTypes.GET_PHYSICIAN_TYPES_REQUESTED, getPhysicianTypesRequested),
    takeEvery(billingActionTypes.GET_BILLING_POSITIONS_REQUESTED, getBillingPositionsRequested)
  ])
}
