// @flow
import type MaybeType from 'data.maybe'

import pipe from 'ramda/src/pipe'
import includes from 'ramda/src/includes'
import propOr from 'ramda/src/propOr'
import prop from 'ramda/src/prop'
import Maybe from 'data.maybe'

import type { UserDtoType } from '../types/userDto'
import type { ProfileStateType } from '../reducers/profile'

import { roles } from '../types/userDto'

type CurrentUserType = ({ profile: ProfileStateType }) => MaybeType<UserDtoType>
export const findCurrentUser: CurrentUserType = pipe(prop('profile'), Maybe.fromNullable)

export const isPatient: UserDtoType => Boolean = pipe(
  propOr([], 'authorities'),
  includes(roles.patient)
)

export const getAcceptedTermsVersion: UserDtoType => MaybeType<string> = pipe(
  prop('acceptedTermsVersion'),
  Maybe.fromNullable
)
