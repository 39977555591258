import React, { Component, Suspense, lazy } from 'react'
// import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '../styles/muiTheme'

const MainRoutes = lazy(() => import('./../../src/routes'))

type PropsType = {
  routes: any,
  store: any
}
class AppContainer extends Component<PropsType> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { store } = this.props
    return (
      <ThemeProvider>
        <Provider id="provider" store={store}>
          <div style={{ height: '100%' }}>
            <Suspense fallback="Loading...">
              <MainRoutes />
            </Suspense>
          </div>
        </Provider>
      </ThemeProvider>
    )
  }
}

export default AppContainer
