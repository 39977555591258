// @flow
import moment from 'moment'
import type { ExtractReturn } from '../../../types'
import type { PatientDtoType } from '../types/patientDto'
import type { MassMessagingVmType } from '../types/massMessagingVm'
import {
  healthrelationActionTypes as types,
  healthrelationActions as actions
} from '../actions'
import type { HealthrelationActionType } from '../actions'
import type { AvailabilityDtoType } from '../types/availabilityDto'
import type { ClinicDtoType } from '../types/clinicDto'
import type { AppointmentDtoType } from '../types/appointmentDto'

export type HealthRelationStateType = {
  patients: Array<PatientDtoType>,
  patientsActivities: Array<any>,
  patientsActivitiesUnique: Array<any>,
  availabilities: Array<AvailabilityDtoType>,
  clinics: Array<ClinicDtoType>,
  massSending: ?MassMessagingVmType,
  appointments: Array<AppointmentDtoType>,
  patientProgress: Array<any>,
  customizedDashboardForClinics?: any,
  hycareSolutionEnabledClinics?: any,
  patientTarget?: any,
  patientTargetLoading?: boolean,
  currentPatientDigaDetails: any,
  appoitmentsForCalendar: Array<any>
}

const initialState: HealthRelationStateType = {
  patients: [],
  patientsActivities: [],
  patientsActivitiesUnique: [],
  availabilities: [],
  clinics: [],
  appointments: [],
  massSending: null,
  patientProgress: [],
  customizedDashboardForClinics: [
    'Medizinische Universität Wien - Institut für Spezifische Prophylaxe und Tropenmedizin',
    'Medizinische Universität Wien - Klinik für Thoraxchirurgie',
    'Medizinische Universität Wien - Klinische Abteilung für Infektionen und Tropenmedizin',
    'Medizinische Universität Wien / Allgemeinchirurgie',
    'Klinik Favoriten - 4.Medizinische Abteilung mit Infektions und Tropenmedizin',
    'Evusheld Test Studie ',
    'Evusheld Test Studie - Clinic 2',
    'Evusheld Clinic #1',
    'Evusheld #2',
    'Evusheld #3',
    'AstraZeneca'
  ],
  hycareSolutionEnabledClinics: ['HyCare Clinic'],
  patientTarget: {},
  patientTargetLoading: true,
  currentPatientDigaDetails: {},
  appoitmentsForCalendar: []
}

// Action Handlers
function loadAllAppointmentsSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.upcomingAppointmentsSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    appointments: action.payload.filter(appointment =>
      moment(appointment.appointmentTime).isAfter()
    )
  }
}

function loadAllPatientsSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<any>
): HealthRelationStateType {
  return {
    ...state,
    patients: action.payload
  }
}

function loadAllPatientsActivitiesSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.loadAllPatientsSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    patientsActivities: action.payload
  }
}

function loadAllPatientsUniqueActivitiesSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.loadAllPatientsSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    patientsActivitiesUnique: action.payload
  }
}

function loadPatientsProgressSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.loadAllPatientsSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    patientProgress: action.payload
  }
}

function getAvailabilitiesSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.getAvailabilitiesSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    availabilities: action.payload
  }
}

function setAvailabilitySucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.setAvailabilitySucceeded>
): HealthRelationStateType {
  const newAvailability = action.payload
  const otherAvailabilities = state.availabilities.filter(
    a => a.id !== newAvailability.id
  )

  return {
    ...state,
    availabilities: [...otherAvailabilities, newAvailability]
  }
}

function loadClinicsSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.loadClinicsSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    clinics: action.payload
  }
}

function createClinicSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.createClinicSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    clinics: [...state.clinics, action.payload]
  }
}

function setMassSendingSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.massSendingSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    massSending: action.payload
  }
}

function setMassSendingCountSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.massSendingSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    massSendingCount: action.payload
  }
}

function addOrReplaceClinic(existingClinics, updatedClinic) {
  const clinics = existingClinics.filter(
    clinic => clinic.id !== updatedClinic.id
  )
  return [updatedClinic, ...clinics]
}

function getClinicMineSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.getClinicMineSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    clinicMine: action.payload
  }
}

function updateClinicSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.updateClinicSucceeded>
): HealthRelationStateType {
  return {
    ...state,
    clinics: addOrReplaceClinic(state.clinics, action.payload)
  }
}

function deleteClinicSucceeded(
  state: HealthRelationStateType,
  action: ExtractReturn<typeof actions.deleteClinicSucceeded>
): HealthRelationStateType {
  const {
    payload: { clinicId }
  } = action

  const clinicsLeft = state.clinics.filter(clinic => clinic.id !== clinicId)
  return {
    ...state,
    clinics: [...clinicsLeft]
  }
}

function getClinicOfPatientSucceeded(state, action) {
  return {
    ...state,
    clinicOfPatient: action.payload,
    clinicMine: action.payload
  }
}

function setCurrentPatient(state, action) {
  return {
    ...state,
    currentPatient: action.payload
  }
}

function setPatientTarget(state, action) {
  return {
    ...state,
    patientTarget: action.payload,
    patientTargetLoading: false
  }
}

function setPatientTargetRequest(state) {
  return {
    ...state,
    patientTarget: {},
    patientTargetLoading: true
  }
}

function setPatientTargetFailed(state) {
  return {
    ...state,
    patientTargetLoading: false
  }
}

function setPatientDigaDetails(state, action) {
  return {
    ...state,
    currentPatientDigaDetails: action.payload
  }
}

function setPatientAppointments(state, action) {
  return {
    ...state,
    appoitmentsForCalendar: action.payload
  }
}

const ACTION_HANDLERS: { [key: HealthrelationActionType]: Function } = {
  [types.LOAD_ALL_PATIENTS_SUCCEEDED]: loadAllPatientsSucceeded,
  [types.UPDATE_PATIENT_SUCCEEDED]: loadAllPatientsSucceeded,
  [types.GET_AVAILABILITIES_SUCCEEDED]: getAvailabilitiesSucceeded,
  [types.SET_AVAILABILITY_SUCCEEDED]: setAvailabilitySucceeded,
  [types.LOAD_CLINICS_SUCCEEDED]: loadClinicsSucceeded,
  [types.CREATE_CLINIC_SUCCEEDED]: createClinicSucceeded,
  [types.MASS_SENDING_SUCCEEDED]: setMassSendingSucceeded,
  [types.MASS_SENDING_COUNT_SUCCEEDED]: setMassSendingCountSucceeded,
  [types.GET_CLINIC_MINE_SUCCEEDED]: getClinicMineSucceeded,
  [types.UPDATE_CLINIC_SUCCEEDED]: updateClinicSucceeded,
  [types.UPCOMING_APPOINTMENTS_SUCCEEDED]: loadAllAppointmentsSucceeded,
  [types.DELETE_CLINIC_SUCCEEDED]: deleteClinicSucceeded,
  [types.LOAD_PATIENT_ACTIVITIES_SUCCEEDED]: loadAllPatientsActivitiesSucceeded,
  [types.LOAD_PATIENT_UNIQUE_ACTIVITIES_SUCCEEDED]: loadAllPatientsUniqueActivitiesSucceeded,
  [types.LOAD_PATIENT_PROGRESS_SUCCEEDED]: loadPatientsProgressSucceeded,
  [types.GET_CLINIC_OF_PATIENT_SUCCEEDED]: getClinicOfPatientSucceeded,
  [types.GET_PATIENT_DETAILS_SUCCEEDED]: setCurrentPatient,
  [types.LOAD_PATIENT_TARGET_SUCCEEDED]: setPatientTarget,
  [types.LOAD_PATIENT_TARGET_REQUESTED]: setPatientTargetRequest,
  [types.LOAD_PATIENT_TARGET_FAILED]: setPatientTargetFailed,
  [types.GET_PATIENT_DATA_FROM_DIGA_SUCCEEDED]: setPatientDigaDetails,
  [types.GET_PATIENT_APPOINTMENTS_SUCCEEDED]: setPatientAppointments
}

type ActionType = { type: HealthrelationActionType }
export default function healthrelationReducer(
  state: HealthRelationStateType = initialState,
  action: ActionType
): HealthRelationStateType {
  if (!action) return state
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
