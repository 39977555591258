// @flow

import { takeEvery, put, select, call } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import type { ActionType, GenType } from 'common-docdok/src/types'
import api from 'common-docdok/src/api'
import primaryConversationSelector from 'common-docdok/src/domain/messaging/selectors/primaryConSelector'
import { messagingActions } from 'common-docdok/src/domain/messaging/actions'
import path from 'ramda/src/path'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import moment from 'moment'

import { dynamicFormActionTypes, type SubmitFormType } from '../../DynamicForm/actions'
import { infoPanelActions } from '../../InfoPanel/actions'
import { createPatientButtonActions } from '../actions'
import { addToStudyDialogActions } from '../../AddToStudyDialog/actions'
import { dispatchNavigateEvent } from '../../utils'

export function formToPatientDto(formData: Object) {
  const {
    salutation,
    firstName,
    lastName,
    gender,
    birthdate,
    createNew,
    isDependent,
    proxyEmail,
    proxyMobileNumber,
    proxySalutation,
    proxyFirstName,
    proxyLastName,
    proxyGender,
    lifelongId,
    disease,
    pepIntegration
  } = formData

  let { email, langKey, mobileNumber } = formData

  const type = isDependent ? 'PATIENTPROXIED' : 'PATIENTSELF'
  const userRef = path(['proxy', 0, 'userRef'], formData)

  let proxy

  if (isDependent) {
    if (createNew) {
      proxy = {
        salutation: proxySalutation,
        firstName: proxyFirstName,
        lastName: proxyLastName,
        gender: proxyGender
      }
      email = proxyEmail
      mobileNumber = proxyMobileNumber
    } else {
      // existing person
      email = path(['proxy', 0, 'email'], formData)
      mobileNumber = path(['proxy', 0, 'mobileNumber'], formData)
      langKey = path(['proxy', 0, 'langKey'], formData)
    }
  }

  // date is UTC
  // 12 Dec 1998 -> 1998-11-30T23:00:00.000Z"
  // TODO fix in the server side!
  if (typeof birthdate !== 'string') {
    birthdate.setHours(birthdate.getHours() + 6)
  }

  const patient = {
    firstName,
    lastName,
    birthdate: moment(birthdate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
    email,
    mobileNumber,
    salutation,
    type,
    userRef,
    gender,
    langKey,
    proxy,
    lifelongId,
    icdCode: undefined,
    appName: undefined
  }

  if (pepIntegration) {
    patient.icdCode = disease
    patient.appName = 'DIGA1'
  }
  return patient
}

export function* createPatGen({ payload }: SubmitFormType): GenType {
  if (payload.name === 'createPatient') {
    const formData = payload.data
    const patient = formToPatientDto(formData)
    const winner = yield asyncAction(healthrelationActions.createPatientRequested(patient))
    if (winner.succeeded) {
      const newPatient = winner.succeeded.payload
      yield put(createPatientButtonActions.toggleCreatePatientDialog())
      yield put(infoPanelActions.showMessage('patients.actions.createPatient.success'))
      yield put(stopSubmit(payload.name))
      dispatchNavigateEvent(`/private/app/patients/${newPatient.uuid}`)
      const result = yield call(api.getAllStudies)
      const studies = result.data
      if (studies.length > 0) {
        yield put(addToStudyDialogActions.toggleAddToStudyDialog(newPatient.userRef, newPatient.uuid))
      }
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

export function* selectPatGen({ payload }: ActionType): GenType {
  const uuid = payload
  if (uuid) {
    const winner = yield asyncAction(healthrelationActions.loadPatientRequested(uuid))

    if (winner.succeeded) {
      const conversationId = yield select((state: StoreType) => primaryConversationSelector(state, uuid))
      yield put(messagingActions.selectConversation(Number(conversationId)))
    }
  }
}

export default function patientsSaga(): Array<GenType> {
  return [takeEvery(dynamicFormActionTypes.SUBMIT_FORM, createPatGen)]
}
