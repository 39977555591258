// @flow
import pipe from 'ramda/src/pipe'
import propEq from 'ramda/src/propEq'
import pathOr from 'ramda/src/pathOr'
import reject from 'ramda/src/reject'
import path from 'ramda/src/path'

import type { ExtractReturn } from '../../../types'
import type { MessagingStateType, ConversationStateType } from './messaging'
import type { SubscriptionWithConversationDtoType } from '../types/subscriptionWithConversationDto'
import { getKeycloakClient } from '../../../common/Keycloak/keycloakProvider'
import filters from '../../../utils/filters'
import { messagingActions } from '../actions'
import { type SubscriptionDtoType } from '../types/subscriptionDto'

export function addSubscription(state: MessagingStateType, sub: SubscriptionWithConversationDtoType) {
  const { conversationId } = sub
  const currentSubscriptions = pipe(
    pathOr([], ['conversations', conversationId, 'subscriptions']),
    reject(propEq(sub.id, 'id'))
  )(state)

  const subscriptions = [sub, ...currentSubscriptions]
  let conversationTitle = pathOr('', ['conversations', conversationId, 'title'])(state)
  let usersSubscriptionId = pathOr(undefined, ['conversations', conversationId, 'subscriptionId'])(state)
  let newestMsg = state.checkedMessages[conversationId] !== undefined ? state.checkedMessages[conversationId] : -1
  const { lastReadMessage } = sub

  if (getKeycloakClient().getUserId() === sub.userRef) {
    conversationTitle = sub.title
    usersSubscriptionId = sub.id
    if (lastReadMessage > newestMsg) {
      newestMsg = lastReadMessage
    }
  }

  const conversation = state.conversations[conversationId]
  const currentMessages = conversation && conversation.messages ? conversation.messages : []
  let messages = sub.latestMessage ? [sub.latestMessage] : []
  if (currentMessages.length > messages.length) {
    messages = currentMessages
  }

  const newState = {
    ...state,
    conversations: {
      ...state.conversations,
      [conversationId]: {
        ...state.conversations[conversationId],
        ...sub.conversation,
        lastReadMessage,
        title: conversationTitle,
        messages,
        latestMessage: sub.latestMessage,
        subscriptionId: usersSubscriptionId,
        subscriptions
      }
    },
    checkedMessages: {
      ...state.checkedMessages,
      [conversationId]: newestMsg
    }
  }

  return newState
}

export function createConversationSucceeded(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.createConversationSucceeded>
) {
  const { payload } = action
  const subscriptions = payload.subscriptions.map(sub => ({
    ...sub,
    conversation: payload.conversation,
    latestMessage: undefined
  }))
  let newState = {
    ...state
  }
  subscriptions.forEach((sub) => {
    newState = addSubscription(newState, sub)
  })
  return newState
}

export function updateCaseSucceeded(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.updateCaseSucceeded>
) {
  const conversation = action.payload
  const convId = conversation.id
  return {
    ...state,
    conversations: {
      ...state.conversations,
      [convId]: {
        ...state.conversations[convId],
        ...conversation
      }
    }
  }
}

function toggleMuteOnSub(state: MessagingStateType, convId: number, subscriptionId: number) {
  const subscriptions = state.conversations[convId].subscriptions.map((sub: SubscriptionDtoType) => {
    let result = sub
    if (sub.id === subscriptionId) {
      result = {
        ...sub,
        mute: !sub.mute
      }
    }
    return result
  })

  return {
    ...state,
    conversations: {
      ...state.conversations,
      [convId]: {
        ...state.conversations[convId],
        subscriptions
      }
    }
  }
}

export function toggleMute(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.toggleMuteSucceeded>
) {
  const { subscriptionId } = action.payload
  let newState = {
    ...state
  }

  filters.values(state.conversations).forEach((conv: ConversationStateType) => {
    if (conv.subscriptions.filter(sub => sub.id === subscriptionId)) {
      newState = toggleMuteOnSub(newState, conv.id, subscriptionId)
    }
  })

  return newState
}

export function setLastReadMessageOnSub(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.setLastReadMessageOnSub>
) {
  const { conversationId, subscriptionId, lastReadMessage } = action.payload
  const subscriptions = path(['conversations', conversationId, 'subscriptions'])(state)

  if (!subscriptions) {
    return state
  }

  const subsThatDidNotChange = subscriptions.filter(sub => sub.id !== subscriptionId)
  const subThatChanged = subscriptions
    .filter(sub => sub.id === subscriptionId)
    .map(sub => ({ ...sub, lastReadMessage }))

  const newSubscriptions = [...subThatChanged, ...subsThatDidNotChange]

  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversationId]: {
        ...state.conversations[conversationId],
        subscriptions: newSubscriptions
      }
    }
  }
}

export function setSubscriptions(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.getSubscriptionsSucceeded>
): MessagingStateType {
  const myUserId = getKeycloakClient().getUserId()
  const newConversations = {}
  const newCheckedMessages = {
    ...state.checkedMessages
  }
  action.payload.forEach((sub) => {
    const { conversationId, latestMessage } = sub

    if (!newConversations[conversationId]) {
      newConversations[conversationId] = {
        subscriptions: []
      }
    }

    newConversations[conversationId].subscriptions.push(sub)

    let newestMsg = newCheckedMessages[conversationId] !== undefined ? newCheckedMessages[conversationId] : -1
    if (myUserId === sub.userRef) {
      const { lastReadMessage, conversation } = sub
      if (lastReadMessage > newestMsg) {
        newConversations[conversationId].lastReadMessage = lastReadMessage
        newestMsg = lastReadMessage
      }

      // add subscription proerties
      newConversations[conversationId].messages = [latestMessage].filter(m => m)
      newConversations[conversationId].latestMessage = latestMessage
      newConversations[conversationId].title = sub.title
      newConversations[conversationId].subscriptionId = sub.id

      // add conversation properties
      newConversations[conversationId].conversationPicture = conversation.conversationPicture
      newConversations[conversationId].id = conversation.id
      newConversations[conversationId].meta = conversation.meta
      newConversations[conversationId].newestMessageId = conversation.newestMessageId
      newConversations[conversationId].secret = conversation.secret
      newConversations[conversationId].type = conversation.type
    }
    newCheckedMessages[conversationId] = newestMsg
  })

  return {
    ...state,
    conversations: {
      ...state.conversations,
      ...newConversations
    },
    checkedMessages: newCheckedMessages,
    refreshing: false,
    error: undefined
  }
}

export function getSubscriptions(state: MessagingStateType): MessagingStateType {
  return { ...state, refreshing: true, error: undefined }
}

export function removeSubscription(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.removeSubscriptionSucceeded>
) {
  const subscriptionId = action.payload
  const conversationIds = Object.keys(state.conversations)
  Object.keys(state.conversations).map((key: any) => state.conversations[key])

  let newState = {
    ...state,
    conversations: {}
  }

  conversationIds.forEach((id: any) => {
    const conversation = state.conversations[id]
    if (conversation.subscriptionId !== subscriptionId) {
      const subscriptionsLeft = conversation.subscriptions.filter(sub => sub.id !== subscriptionId)
      let adaptedConversation = conversation
      if (subscriptionsLeft.length !== conversation.subscriptions.length) {
        adaptedConversation = {
          ...conversation,
          subscriptions: subscriptionsLeft
        }
      }

      newState = {
        ...newState,
        conversations: {
          ...newState.conversations,
          [id]: adaptedConversation
        }
      }
    }
  })

  return newState
}

export function setSubscriptionsForConversations(
  state: MessagingStateType,
  action: ExtractReturn<typeof messagingActions.getSubsByConvSucceeded>
): MessagingStateType {
  let newState = { ...state }
  const { subscriptions } = action.payload
  subscriptions.forEach((sub) => {
    if (!newState.conversations[sub.conversationId]) {
      throw new Error('You have to load conversation before adding subscriptions')
    }
    newState = addSubscription(newState, sub)
  })

  return newState
}
