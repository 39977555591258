// @flow
import { put, call, takeEvery, select } from 'redux-saga/effects'
import propOr from 'ramda/src/propOr'

import api from '../../../api'

import { userActions, userActionTypes } from '../actions'
import type { ExtractReturn, GenType, UserForCreationVmType, CommonStateType } from '../../../types'
import { takeEverySync, asyncAction } from '../../../utils/sagaUtils'
import constants from '../../../utils/constants'

// -- APPEND GENERATORS HERE --
function* resendSmsToUser(action: ExtractReturn<typeof userActions.resendInitialPasswordSmsRequested>): GenType {
  try {
    yield call(api.resendInitialSms, action.payload)
    yield put(userActions.resendInitialPasswordSmsSucceeded())
  } catch (error) {
    yield put(userActions.resendInitialPasswordSmsFailed(error))
  }
}

function* disableUser(action: ExtractReturn<typeof userActions.disableUserRequested>): GenType {
  try {
    yield call(api.disableUser, action.payload)
    yield put(userActions.disableUserSucceeded())
  } catch (error) {
    yield put(userActions.disableUserFailed(error))
  }
}

function* loadUsersById(userIds: Array<string>): GenType {
  try {
    const result = yield Promise.all(userIds.map(api.getUser))
    const users = result.map(propOr(undefined, 'data')).filter(u => u !== undefined)
    yield put(userActions.loadUsersSucceeded(users))
  } catch (error) {
    yield put(userActions.loadUsersFailed(error))
  }
}

function* loadMissingUsers(): GenType {
  const idQueue = yield select((state: CommonStateType) => state.userCache.idsToLoad)
  const userIds = [...new Set(idQueue)]
  // yield call(delay, 10) // add small delay to prevent loading users multiple times
  const loadedUserIds = yield select((state: CommonStateType) => Object.keys(state.userCache.users))
  const usersIdsToLoad = userIds
    .filter(id => id !== constants.UNKNOWN_USER_REF)
    .filter(id => id && !loadedUserIds.includes(id))

  if (usersIdsToLoad.length > 0) {
    try {
      const result = yield Promise.all(usersIdsToLoad.map(api.getUser))
      const users = result.map(propOr(undefined, 'data')).filter(u => u !== undefined)
      yield put(userActions.loadMissingUsersSucceeded(users))
    } catch (error) {
      yield put(userActions.loadMissingUsersFailed(error))
    }
  } else {
    yield put(userActions.loadMissingUsersSucceeded([]))
  }
}

function* loadUsers(action: ExtractReturn<typeof userActions.loadUsersRequested>): GenType {
  const userIds = action.payload
  yield loadUsersById(userIds)
}

function normalizeUserRoles(user: UserForCreationVmType): UserForCreationVmType {
  const userData = {
    ...user,
    authorities: ['ROLE_USER'].concat(user.authorities)
  }
  return userData
}

export function* createUser(action: ExtractReturn<typeof userActions.createUsersRequested>): GenType {
  try {
    const user = action.payload
    const response = yield call(api.createUser, normalizeUserRoles(user))
    yield put(userActions.createUserSucceeded(response.data))
  } catch (error) {
    yield put(userActions.createUserFailed(error))
  }
}

export function* renewInvitationRequested(action: ExtractReturn<typeof userActions.renewInvitationRequested>): GenType {
  try {
    const userUid = action.payload
    yield call(api.renewInvitation, userUid)
    yield put(userActions.renewInvitationSucceeded(userUid))
  } catch (error) {
    yield put(userActions.renewInvitationFailed(error))
  }
}

export function* updateUserRequested(action: ExtractReturn<typeof userActions.updateUserRequested>): GenType {
  try {
    const updatedUser = action.payload

    let user = yield select((state: CommonStateType) => state.userCache.users[updatedUser.uid])

    if (!user) {
      const winner = yield asyncAction(userActions.loadMissingUsersRequested([updatedUser.uid]))
      if (winner.succeeded) {
        [user] = winner.succeeded.payload
      }
      user = yield select((state: CommonStateType) => state.userCache.users[updatedUser.uid])
    }
    const { mobileNumber, email, uid } = user
    if (email !== updatedUser.email) {
      yield call(api.updateEmail, uid, updatedUser.email)
    }
    if (mobileNumber !== updatedUser.mobileNumber) {
      yield call(api.updateMobileNumber, uid, updatedUser.mobileNumber)
    }
    const result = yield call(api.updateUser, updatedUser)

    yield put(userActions.updateUserSucceeded(result.data))
    yield put(userActions.loadMissingUsersSucceeded([result.data]))
  } catch (error) {
    yield put(userActions.updateUserFailed(error))
  }
}

function* deleteUser(action: ExtractReturn<typeof userActions.deleteUserRequested>): GenType {
  try {
    yield call(api.deleteUser, action.payload)
    yield put(userActions.deleteUserSucceeded())
  } catch (error) {
    yield put(userActions.deleteUserFailed(error))
  }
}

export default function usersSaga(): Array<GenType> {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(userActionTypes.DISABLE_USER_REQUESTED, disableUser),
    takeEvery(userActionTypes.LOAD_USERS_REQUESTED, loadUsers),
    takeEvery(userActionTypes.CREATE_USER_REQUESTED, createUser),
    takeEverySync(userActionTypes.LOAD_MISSING_USERS_REQUESTED, loadMissingUsers),
    takeEvery(userActionTypes.RENEW_INVITATION_REQUESTED, renewInvitationRequested),
    takeEvery(userActionTypes.UPDATE_USER_REQUESTED, updateUserRequested),
    takeEvery(userActionTypes.RESEND_INIT_SMS_REQUESTED, resendSmsToUser),
    takeEvery(userActionTypes.DELETE_USER_REQUESTED, deleteUser)
  ]
}
