import { v4 as generateUUID } from 'uuid'

import type { FileDescriptionType } from '../types/fileDescription'
import type { FileToSendType } from '../reducers/messaging'
import type { ContentDefinitionVmType } from '../types/contentDefinitionVm'
import type { AccessRightsVmType } from '../types/accessRightsVm'
import type { SubscriptionWithConversationDtoType } from '../types/subscriptionWithConversationDto'
import type { MessageDtoType } from '../types/messageDto'
import type { SubscriptionDtoType } from '../types/subscriptionDto'
import type { ConversationDtoType } from '../types/conversationDto'
import { getKeycloakClient } from '../../../common/Keycloak/keycloakProvider'
import type { CaseStreamDtoType } from '../../healthrelation/types/caseStreamDto'

export const messagingActionTypes = {
  //        -- APPEND ACTION TYPES HERE --
  JOIN_CASESTREAM_REQUESTED: 'JOIN_CASESTREAM_REQUESTED',
  JOIN_CASESTREAM_SUCCEEDED: 'JOIN_CASESTREAM_SUCCEEDED',
  JOIN_CASESTREAM_FAILED: 'JOIN_CASESTREAM_FAILED',
  UPDATE_CASE_REQUESTED: 'UPDATE_CASE_REQUESTED',
  UPDATE_CASE_SUCCEEDED: 'UPDATE_CASE_SUCCEEDED',
  UPDATE_CASE_FAILED: 'UPDATE_CASE_FAILED',
  GET_CONTENT_DEFINITION_TAGS_REQUESTED:
    'GET_CONTENT_DEFINITION_TAGS_REQUESTED',
  GET_CONTENT_DEFINITION_TAGS_SUCCEEDED:
    'GET_CONTENT_DEFINITION_TAGS_SUCCEEDED',
  GET_CONTENT_DEFINITION_TAGS_FAILED: 'GET_CONTENT_DEFINITION_TAGS_FAILED',
  CREATE_CONTENT_MESSAGE_PREVIEW_REQUESTED:
    'CREATE_CONTENT_MESSAGE_PREVIEW_REQUESTED',
  CREATE_CONTENT_MESSAGE_PREVIEW_SUCCEEDED:
    'CREATE_CONTENT_MESSAGE_PREVIEW_SUCCEEDED',
  CREATE_CONTENT_MESSAGE_PREVIEW_FAILED:
    'CREATE_CONTENT_MESSAGE_PREVIEW_FAILED',
  SET_CONTENT_DEFINITION_ACCESS_RIGHTS_REQUESTED:
    'SET_CONTENT_DEFINITION_ACCESS_RIGHTS_REQUESTED',
  SET_CONTENT_DEFINITION_ACCESS_RIGHTS_SUCCEEDED:
    'SET_CONTENT_DEFINITION_ACCESS_RIGHTS_SUCCEEDED',
  SET_CONTENT_DEFINITION_ACCESS_RIGHTS_FAILED:
    'SET_CONTENT_DEFINITION_ACCESS_RIGHTS_FAILED',
  SEND_CONTENT_MESSAGES_REQUESTED: 'SEND_CONTENT_MESSAGES_REQUESTED',
  SEND_CONTENT_MESSAGES_SUCCEEDED: 'SEND_CONTENT_MESSAGES_SUCCEEDED',
  SEND_CONTENT_MESSAGES_FAILED: 'SEND_CONTENT_MESSAGES_FAILED',
  UPDATE_CONTENT_DEFINITION_REQUESTED: 'UPDATE_CONTENT_DEFINITION_REQUESTED',
  UPDATE_CONTENT_DEFINITION_SUCCEEDED: 'UPDATE_CONTENT_DEFINITION_SUCCEEDED',
  UPDATE_CONTENT_DEFINITION_FAILED: 'UPDATE_CONTENT_DEFINITION_FAILED',
  GET_SUBSCRIPTIONS_REQUESTED: 'GET_SUBSCRIPTIONS_REQUESTED',
  GET_SUBSCRIPTIONS_SUCCEEDED: 'GET_SUBSCRIPTIONS_SUCCEEDED',
  GET_SUBSCRIPTIONS_FAILED: 'GET_SUBSCRIPTIONS_FAILED',
  ADD_FILE_REQUESTED: 'ADD_FILE_REQUESTED',
  ADD_FILE_SUCCEEDED: 'ADD_FILE_SUCCEEDED',
  ADD_FILE_FAILED: 'ADD_FILE_FAILED',
  REMOVE_SUBSCRIPTION_REQUESTED: 'REMOVE_SUBSCRIPTION_REQUESTED',
  REMOVE_SUBSCRIPTION_SUCCEEDED: 'REMOVE_SUBSCRIPTION_SUCCEEDED',
  REMOVE_SUBSCRIPTION_FAILED: 'REMOVE_SUBSCRIPTION_FAILED',
  POST_MESSAGE_REQUESTED: 'POST_MESSAGE_REQUESTED',
  POST_MESSAGE_SUCCEEDED: 'POST_MESSAGE_SUCCEEDED',
  POST_MESSAGE_FAILED: 'POST_MESSAGE_FAILED',
  GET_SUBS_BY_CONV_REQUESTED: 'GET_SUBS_BY_CONV_REQUESTED',
  GET_SUBS_BY_CONV_SUCCEEDED: 'GET_SUBS_BY_CONV_SUCCEEDED',
  GET_SUBS_BY_CONV_FAILED: 'GET_SUBS_BY_CONV_FAILED',
  JOIN_PRIMARY_CONVERSATION_REQUESTED: 'JOIN_PRIMARY_CONVERSATION_REQUESTED',
  JOIN_PRIMARY_CONVERSATION_SUCCEEDED: 'JOIN_PRIMARY_CONVERSATION_SUCCEEDED',
  JOIN_PRIMARY_CONVERSATION_FAILED: 'JOIN_PRIMARY_CONVERSATION_FAILED',
  TOGGLE_MUTE_REQUESTED: 'TOGGLE_MUTE_REQUESTED',
  TOGGLE_MUTE_SUCCEEDED: 'TOGGLE_MUTE_SUCCEEDED',
  TOGGLE_MUTE_FAILED: 'TOGGLE_MUTE_FAILED',
  SELECT_CONVERSATION: 'SELECT_CONVERSATION',
  SELECT_CASE_STREAM: 'SELECT_CASE_STREAM',
  LOAD_MESSAGES_REQUESTED: 'LOAD_MESSAGES_REQUESTED',
  LOAD_MESSAGES_SUCCEEDED: 'LOAD_MESSAGES_SUCCEEDED',
  LOAD_MESSAGES_FAILED: 'LOAD_MESSAGES_FAILED',
  LOAD_OLDER_MESSAGES_REQUESTED: 'LOAD_OLDER_MESSAGES_REQUESTED',
  LOAD_OLDER_MESSAGES_SUCCEEDED: 'LOAD_OLDER_MESSAGES_SUCCEEDED',
  LOAD_OLDER_MESSAGES_FAILED: 'LOAD_OLDER_MESSAGES_FAILED',
  MARK_AS_CACHED: 'MARK_AS_CACHED',
  MARK_AS_CHECKED_LOCAL: 'MARK_AS_CHECKED_LOCAL',
  MARK_AS_CHECKED_REQUESTED: 'MARK_AS_CHECKED_REQUESTED',
  MARK_AS_CHECKED_SUCCEEDED: 'MARK_AS_CHECKED_SUCCEEDED',
  MARK_AS_CHECKED_FAILED: 'MARK_AS_CHECKED_FAILED',
  REMOVE_FILE: 'REMOVE_FILE',
  RESET_ATTACHMENTS: 'RESET_ATTACHMENTS',
  SET_FILE_DESCRIPTION: 'SET_FILE_DESCRIPTION',
  CREATE_CONVERSATION_REQUESTED: 'CREATE_CONVERSATION_REQUESTED',
  CREATE_CONVERSATION_SUCCEEDED: 'CREATE_CONVERSATION_SUCCEEDED',
  CREATE_CONVERSATION_FAILED: 'CREATE_CONVERSATION_FAILED',
  EDIT_CONVERSATION_REQUESTED: 'EDIT_CONVERSATION_REQUESTED',
  EDIT_CONVERSATION_SUCCEEDED: 'EDIT_CONVERSATION_SUCCEEDED',
  EDIT_CONVERSATION_FAILED: 'EDIT_CONVERSATION_FAILED',
  ASSIGN_CONVERSATION_TO_ME_REQUESTED: 'ASSIGN_CONVERSATION_TO_ME_REQUESTED',
  ASSIGN_CONVERSATION_TO_ME_SUCCEEDED: 'ASSIGN_CONVERSATION_TO_ME_SUCCEEDED',
  ASSIGN_CONVERSATION_TO_ME_FAILED: 'ASSIGN_CONVERSATION_TO_ME_FAILED',
  GET_MEDIA_RESOURCE_REQUESTED_DIRECT: 'GET_MEDIA_RESOURCE_REQUESTED_DIRECT',
  GET_MEDIA_RESOURCE_REQUESTED: 'GET_MEDIA_RESOURCE_REQUESTED',
  GET_MEDIA_RESOURCE_SUCCEEDED: 'GET_MEDIA_RESOURCE_SUCCEEDED',
  CLEAR_MEDIA_RESOURCE: 'CLEAR_MEDIA_RESOURCE',
  GET_MEDIA_RESOURCE_FAILED: 'GET_MEDIA_RESOURCE_FAILED',
  SET_LAST_READ_MESSAGE_ON_SUB: 'SET_LAST_READ_MESSAGE_ON_SUB',

  UPLOAD_CONTENT_DEFINITION_FILES_REQUESTED:
    'UPLOAD_CONTENT_DEFINITION_FILES_REQUESTED',
  UPLOAD_CONTENT_DEFINITION_FILES_SUCCEEDED:
    'UPLOAD_CONTENT_DEFINITION_FILES_SUCCEEDED',
  UPLOAD_CONTENT_DEFINITION_FILES_FAILED:
    'UPLOAD_CONTENT_DEFINITION_FILES_FAILED',
  UPDATE_CONTENT_DEFINITION_FILES_SUCCEEDED:
    'UPLOAD_CONTENT_DEFINITION_FILES_SUCCEEDED',

  UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_REQUESTED:
    'UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_REQUESTED',
  UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_SUCCEEDED:
    'UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_SUCCEEDED',
  UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_FAILED:
    'UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_FAILED',

  DELETE_CONTENT_DEFINITION_REQUESTED: 'DELETE_CONTENT_DEFINITION_REQUESTED',
  DELETE_CONTENT_DEFINITION_SUCCEEDED: 'DELETE_CONTENT_DEFINITION_SUCCEEDED',
  DELETE_CONTENT_DEFINITION_FAILED: 'DELETE_CONTENT_DEFINITION_FAILED'
}

export type MessagingActionType = $Keys<typeof messagingActionTypes>

type PostFileType = {
  mediaResourceId: string,
  mimeType: string,
  preview: string,
  originalSize: number
}

// TODO: think to a better solution than a var here for increment
let fileOrder = 0

export const messagingActions = {
  //        -- APPEND ACTIONS HERE --
  joinCaseStreamRequested(
    userRef: string,
    healthcareSubject: string,
    caseStreamId: string
  ) {
    return {
      type: messagingActionTypes.JOIN_CASESTREAM_REQUESTED,
      payload: { userRef, healthcareSubject, caseStreamId }
    }
  },
  joinCaseStreamSucceeded(conversationId: number) {
    return {
      type: messagingActionTypes.JOIN_CASESTREAM_SUCCEEDED,
      payload: conversationId
    }
  },
  joinCaseStreamFailed(error: Error) {
    return {
      type: messagingActionTypes.JOIN_CASESTREAM_FAILED,
      payload: { error }
    }
  },
  updateCaseRequested(conversationId: number, meta: Object) {
    return {
      type: messagingActionTypes.UPDATE_CASE_REQUESTED,
      payload: { conversationId, meta }
    }
  },
  updateCaseSucceeded(conversation: ConversationDtoType) {
    return {
      type: messagingActionTypes.UPDATE_CASE_SUCCEEDED,
      payload: conversation
    }
  },
  updateCaseFailed(error: Error) {
    return {
      type: messagingActionTypes.UPDATE_CASE_FAILED,
      payload: { error }
    }
  },

  getContentDefinitionTagsRequested() {
    return { type: messagingActionTypes.GET_CONTENT_DEFINITION_TAGS_REQUESTED }
  },
  getContentDefinitionTagsSucceeded(payload: Array<string>) {
    return {
      type: messagingActionTypes.GET_CONTENT_DEFINITION_TAGS_SUCCEEDED,
      payload
    }
  },
  getContentDefinitionTagsFailed(error: Error) {
    return {
      type: messagingActionTypes.GET_CONTENT_DEFINITION_TAGS_FAILED,
      payload: { error }
    }
  },

  createContentMessagePreviewRequested(definitionId: number) {
    return {
      type: messagingActionTypes.CREATE_CONTENT_MESSAGE_PREVIEW_REQUESTED,
      payload: definitionId
    }
  },
  createContentMessagePreviewSucceeded(
    definitionId: number,
    message: MessageDtoType
  ) {
    return {
      type: messagingActionTypes.CREATE_CONTENT_MESSAGE_PREVIEW_SUCCEEDED,
      payload: { definitionId, message }
    }
  },
  createContentMessagePreviewFailed(error: Error) {
    return {
      type: messagingActionTypes.CREATE_CONTENT_MESSAGE_PREVIEW_FAILED,
      payload: { error }
    }
  },

  setContentDefinitionAccessRightsRequested(
    definitionId: number,
    accessRights: AccessRightsVmType
  ) {
    return {
      type: messagingActionTypes.SET_CONTENT_DEFINITION_ACCESS_RIGHTS_REQUESTED,
      payload: {
        definitionId,
        accessRights
      }
    }
  },
  setContentDefinitionAccessRightsSucceeded(payload: ContentDefinitionVmType) {
    return {
      type: messagingActionTypes.SET_CONTENT_DEFINITION_ACCESS_RIGHTS_SUCCEEDED,
      payload
    }
  },
  setContentDefinitionAccessRightsFailed(error: Error) {
    return {
      type: messagingActionTypes.SET_CONTENT_DEFINITION_ACCESS_RIGHTS_FAILED,
      payload: { error }
    }
  },

  sendContentMessagesRequested(
    contentDefinitionIds: Array<number>,
    conversationIds: Array<number>
  ) {
    return {
      type: messagingActionTypes.SEND_CONTENT_MESSAGES_REQUESTED,
      payload: { contentDefinitionIds, conversationIds }
    }
  },
  sendContentMessagesSucceeded(payload: Array<MessageDtoType>) {
    return {
      type: messagingActionTypes.SEND_CONTENT_MESSAGES_SUCCEEDED,
      payload
    }
  },
  sendContentMessagesFailed(error: Error) {
    return {
      type: messagingActionTypes.SEND_CONTENT_MESSAGES_FAILED,
      payload: { error }
    }
  },

  updateContentDefinitionRequested(revision?: string) {
    return {
      type: messagingActionTypes.UPDATE_CONTENT_DEFINITION_REQUESTED,
      payload: revision
    }
  },
  updateContentDefinitionSucceeded(branch?: string) {
    return {
      type: messagingActionTypes.UPDATE_CONTENT_DEFINITION_SUCCEEDED,
      payload: branch
    }
  },
  updateContentDefinitionFailed(error: Error) {
    return {
      type: messagingActionTypes.UPDATE_CONTENT_DEFINITION_FAILED,
      payload: { error }
    }
  },

  getSubscriptionsRequested(
    filterSilentConversations?: boolean = true,
    userRef?: string,
    conversationId?: string
  ) {
    return {
      type: messagingActionTypes.GET_SUBSCRIPTIONS_REQUESTED,
      payload: { filterSilentConversations, userRef, conversationId }
    }
  },
  getSubscriptionsSucceeded(
    subscriptions: Array<SubscriptionWithConversationDtoType>
  ) {
    return {
      type: messagingActionTypes.GET_SUBSCRIPTIONS_SUCCEEDED,
      payload: subscriptions
    }
  },
  getSubscriptionsFailed(error: Error) {
    return {
      type: messagingActionTypes.GET_SUBSCRIPTIONS_FAILED,
      payload: { error }
    }
  },

  addFileRequested(conversationId: number, file: FileDescriptionType) {
    fileOrder += 1
    return {
      type: messagingActionTypes.ADD_FILE_REQUESTED,
      payload: {
        conversationId,
        file: {
          order: fileOrder,
          id: generateUUID(),
          mediaResourceId: -1,
          uploaded: false,
          url: undefined,
          description: undefined,
          ...file
        }
      }
    }
  },
  addFileSucceeded(conversationId: number, file: FileToSendType) {
    return {
      type: messagingActionTypes.ADD_FILE_SUCCEEDED,
      payload: {
        conversationId,
        file
      }
    }
  },

  removeSubscriptionRequested(convId: number) {
    return {
      type: messagingActionTypes.REMOVE_SUBSCRIPTION_REQUESTED,
      payload: convId
    }
  },
  removeSubscriptionSucceeded(convId: number) {
    return {
      type: messagingActionTypes.REMOVE_SUBSCRIPTION_SUCCEEDED,
      payload: convId
    }
  },
  removeSubscriptionFailed(error: Error) {
    return {
      type: messagingActionTypes.REMOVE_SUBSCRIPTION_FAILED,
      payload: { error }
    }
  },
  // this is an alternative action creater POST_MESSAGE_REQUESTED
  resendMessage(message: MessageDtoType) {
    return {
      type: messagingActionTypes.POST_MESSAGE_REQUESTED,
      payload: { ...message, failed: undefined }
    }
  },
  postMessageRequested(
    conversationId: number,
    message: string,
    file?: ?PostFileType,
    meta?: Object = {}
  ) {
    const post: MessageDtoType = {
      conversationId,
      userRef: getKeycloakClient().getUserId(),
      text: message,
      uuid: generateUUID(),
      postedAt: new Date(Date.now()),
      sequenceNo: -1,
      meta
    }
    if (file) {
      post.mediaResource = {
        uuid: file.mediaResourceId,
        mimeType: file.mimeType,
        previewUrl: file.preview,
        originalUrl: file.preview,
        meta: {
          originalSize: file.originalSize
        }
      }
      post.meta = {
        originalSize: file.originalSize
      }
    }

    return {
      type: messagingActionTypes.POST_MESSAGE_REQUESTED,
      payload: post
    }
  },
  postMessageSucceeded(payload: MessageDtoType) {
    return {
      type: messagingActionTypes.POST_MESSAGE_SUCCEEDED,
      payload
    }
  },
  postMessageFailed(payload: MessageDtoType) {
    return {
      type: messagingActionTypes.POST_MESSAGE_FAILED,
      payload: { ...payload, failed: true }
    }
  },

  getSubsByConvRequested(convId: number) {
    return {
      type: messagingActionTypes.GET_SUBS_BY_CONV_REQUESTED,
      payload: convId
    }
  },

  getSubsByConvSucceeded(
    conversationId: number,
    subscriptions: Array<SubscriptionWithConversationDtoType>
  ) {
    return {
      type: messagingActionTypes.GET_SUBS_BY_CONV_SUCCEEDED,
      payload: {
        conversationId,
        subscriptions
      }
    }
  },
  getSubsByConvFailed(error: Error) {
    return {
      type: messagingActionTypes.GET_SUBS_BY_CONV_FAILED,
      payload: { error }
    }
  },

  joinPrimaryConversationRequested(userRef: string, healthcareSubject: string) {
    return {
      type: messagingActionTypes.JOIN_PRIMARY_CONVERSATION_REQUESTED,
      payload: {
        userRef,
        healthcareSubject
      }
    }
  },
  joinPrimaryConversationSucceeded() {
    return {
      type: messagingActionTypes.JOIN_PRIMARY_CONVERSATION_SUCCEEDED
    }
  },
  joinPrimaryConversationFailed(error: Error) {
    return {
      type: messagingActionTypes.JOIN_PRIMARY_CONVERSATION_FAILED,
      payload: { error }
    }
  },
  toggleMuteRequested(subscriptionId: number, isMute: boolean) {
    return {
      type: messagingActionTypes.TOGGLE_MUTE_REQUESTED,
      payload: { subscriptionId, isMute }
    }
  },
  toggleMuteSucceeded(subscriptionId: number, isMute: boolean) {
    return {
      type: messagingActionTypes.TOGGLE_MUTE_SUCCEEDED,
      payload: { subscriptionId, isMute }
    }
  },
  toggleMuteFaield(error: Error) {
    return {
      type: messagingActionTypes.TOGGLE_MUTE_FAILED,
      payload: { error }
    }
  },

  selectConversation(conversationId: ?number) {
    return {
      type: messagingActionTypes.SELECT_CONVERSATION,
      payload: conversationId
    }
  },

  loadMessagesRequested(conversationId: number) {
    return {
      type: messagingActionTypes.LOAD_MESSAGES_REQUESTED,
      payload: conversationId
    }
  },
  loadMessagesSucceeded(messages: Array<MessageDtoType>) {
    return {
      type: messagingActionTypes.LOAD_MESSAGES_SUCCEEDED,
      payload: messages
    }
  },
  loadMessagesFailed(error: Error) {
    return {
      type: messagingActionTypes.LOAD_MESSAGES_FAILED,
      payload: { error }
    }
  },
  loadOlderMessagesRequested(conversationId: number = 0) {
    return {
      type: messagingActionTypes.LOAD_OLDER_MESSAGES_REQUESTED,
      payload: conversationId
    }
  },
  loadOlderMessagesSucceeded(messages: Array<MessageDtoType>) {
    return {
      type: messagingActionTypes.LOAD_OLDER_MESSAGES_SUCCEEDED,
      payload: messages
    }
  },
  loadOlderMessagesFailed(error: Error) {
    return {
      type: messagingActionTypes.LOAD_OLDER_MESSAGES_FAILED,
      payload: { error }
    }
  },
  markAsCached(url: string) {
    return {
      type: messagingActionTypes.MARK_AS_CACHED,
      payload: url
    }
  },
  markAsCheckedLocal(messages: Array<MessageDtoType>) {
    return {
      type: messagingActionTypes.MARK_AS_CHECKED_LOCAL,
      payload: messages
    }
  },
  markAsCheckedRequested(
    messages: Array<MessageDtoType>,
    forced: boolean = false
  ) {
    return {
      type: messagingActionTypes.MARK_AS_CHECKED_REQUESTED,
      payload: { forced, messages }
    }
  },
  markAsCheckedSucceeded(messages: Array<MessageDtoType>) {
    return {
      type: messagingActionTypes.MARK_AS_CHECKED_SUCCEEDED,
      payload: messages
    }
  },
  markAsCheckedFailed(error: Error) {
    return {
      type: messagingActionTypes.MARK_AS_CHECKED_FAILED,
      payload: { error }
    }
  },
  removeFile(conversationId: number, fileId: string) {
    return {
      type: messagingActionTypes.REMOVE_FILE,
      payload: {
        conversationId,
        fileId
      }
    }
  },
  resetAttachments(conversationId: number) {
    return {
      type: messagingActionTypes.RESET_ATTACHMENTS,
      payload: conversationId
    }
  },
  setFileDescription(
    conversationId: number,
    fileId: string,
    description: string
  ) {
    return {
      type: messagingActionTypes.SET_FILE_DESCRIPTION,
      payload: {
        conversationId,
        fileId,
        description
      }
    }
  },
  createConversationRequested(
    title: string,
    userRefs: Array<string>,
    meta?: {} = {}
  ) {
    return {
      type: messagingActionTypes.CREATE_CONVERSATION_REQUESTED,
      payload: {
        title,
        userRefs,
        meta
      }
    }
  },
  createConversationSucceeded(
    conversation: ConversationDtoType,
    subscriptions: Array<SubscriptionDtoType>
  ) {
    return {
      type: messagingActionTypes.CREATE_CONVERSATION_SUCCEEDED,
      payload: {
        conversation,
        subscriptions
      }
    }
  },
  createConversationFailed(error: Error) {
    return {
      type: messagingActionTypes.CREATE_CONVERSATION_FAILED,
      payload: { error }
    }
  },
  editConversationRequested(
    conversationId: number,
    title: string,
    userRefs: Array<string>
  ) {
    return {
      type: messagingActionTypes.EDIT_CONVERSATION_REQUESTED,
      payload: {
        conversationId,
        title,
        userRefs
      }
    }
  },
  editConversationSucceeded(
    conversation: ConversationDtoType,
    newSubscriptions: Array<SubscriptionDtoType>
  ) {
    return {
      type: messagingActionTypes.EDIT_CONVERSATION_SUCCEEDED,
      payload: {
        conversation,
        newSubscriptions
      }
    }
  },
  editConversationFailed(error: Error) {
    return {
      type: messagingActionTypes.EDIT_CONVERSATION_FAILED,
      payload: { error }
    }
  },

  assignConversationToMeRequested(conversationId: number) {
    return {
      type: messagingActionTypes.ASSIGN_CONVERSATION_TO_ME_REQUESTED,
      payload: {
        conversationId
      }
    }
  },
  assignConversationToMeSucceeded(conversationId: number) {
    return {
      type: messagingActionTypes.ASSIGN_CONVERSATION_TO_ME_SUCCEEDED,
      payload: {
        conversationId
      }
    }
  },
  assignConversationToMeFailed(error: Error) {
    return {
      type: messagingActionTypes.ASSIGN_CONVERSATION_TO_ME_FAILED,
      payload: { error }
    }
  },

  getMediaResourceRequested(
    uuid: string,
    preview?: boolean = true,
    mimeType?: string = 'application/pdf',
    message?: any = {}
  ) {
    return {
      type: messagingActionTypes.GET_MEDIA_RESOURCE_REQUESTED,
      payload: {
        uuid,
        preview,
        mimeType,
        message
      }
    }
  },
  getMediaResourceRequestedDirect(contentMessage: MessageDtoType) {
    return {
      type: messagingActionTypes.GET_MEDIA_RESOURCE_REQUESTED_DIRECT,
      payload: contentMessage
    }
  },
  getMediaResourceSucceeded(
    dataUrl: string,
    uuid: string,
    preview: boolean,
    mimeType: string
  ) {
    return {
      type: messagingActionTypes.GET_MEDIA_RESOURCE_SUCCEEDED,
      payload: {
        dataUrl,
        uuid,
        preview,
        mimeType
      }
    }
  },
  clearMediaResource(
    uuid: string
  ) {
    return {
      type: messagingActionTypes.CLEAR_MEDIA_RESOURCE,
      payload: {
        uuid
      }
    }
  },
  getMediaResourceFailed(error: Error) {
    return {
      type: messagingActionTypes.GET_MEDIA_RESOURCE_FAILED,
      payload: { error }
    }
  },
  setLastReadMessageOnSub(
    conversationId: number,
    subscriptionId: number,
    lastReadMessage: number
  ) {
    return {
      type: messagingActionTypes.SET_LAST_READ_MESSAGE_ON_SUB,
      payload: { conversationId, subscriptionId, lastReadMessage }
    }
  },
  selectCaseStream(selectedCaseStream: CaseStreamDtoType) {
    return {
      type: messagingActionTypes.SELECT_CASE_STREAM,
      payload: { selectedCaseStream }
    }
  },

  uploadContentDefinitionFileRequested(data: any) {
    return {
      type: messagingActionTypes.UPLOAD_CONTENT_DEFINITION_FILES_REQUESTED,
      payload: data
    }
  },
  uploadContentDefinitionFileSucceeded(payload: any) {
    return {
      type: messagingActionTypes.UPLOAD_CONTENT_DEFINITION_FILES_SUCCEEDED,
      payload
    }
  },
  updateContentDefinitionFileSucceeded(payload: any) {
    return {
      type: messagingActionTypes.UPDATE_CONTENT_DEFINITION_FILES_SUCCEEDED,
      payload
    }
  },

  uploadContentDefinitionFileFailed(error: Error) {
    return {
      type: messagingActionTypes.UPLOAD_CONTENT_DEFINITION_FILES_FAILED,
      payload: { error }
    }
  },

  uploadContentDefinitionGetFileURLRequested(data: any) {
    return {
      type:
        messagingActionTypes.UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_REQUESTED,
      payload: data
    }
  },
  uploadContentDefinitionGetFileURLSucceeded(payload: any) {
    return {
      type:
        messagingActionTypes.UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_SUCCEEDED,
      payload
    }
  },
  uploadContentDefinitionGetFileURLFailed(error: Error) {
    return {
      type: messagingActionTypes.UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_FAILED,
      payload: { error }
    }
  },

  deleteContentDefinitionRequested(data: any) {
    return {
      type: messagingActionTypes.DELETE_CONTENT_DEFINITION_REQUESTED,
      payload: data
    }
  },
  deleteContentDefinitionSucceeded(payload: any) {
    return {
      type: messagingActionTypes.DELETE_CONTENT_DEFINITION_SUCCEEDED,
      payload
    }
  },
  deleteContentDefinitionFailed(error: Error) {
    return {
      type: messagingActionTypes.DELETE_CONTENT_DEFINITION_FAILED,
      payload: { error }
    }
  }
}
