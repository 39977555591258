// @flow
import { put, takeEvery, all, call } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import type { ExtractReturn, GenType } from 'common-docdok/src/types'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import type { StudyDtoType } from 'common-docdok/src/domain/survey/types/studyDto'
import { surveyActions, surveyActionTypes } from 'common-docdok/src/domain/survey/actions'
import { getKeycloakClient } from 'common-docdok/src/common/Keycloak/keycloakProvider'
import toString from 'common-docdok/src/utils/toString'
import path from 'ramda/src/path'
import { api } from 'common-docdok/src'
import { I18n } from 'react-redux-i18n'

import { infoPanelActions } from '../../../../../common/InfoPanel/actions'
import { studiesActions, studiesActionTypes } from '../actions'
import { dynamicFormActionTypes } from '../../../../../common/DynamicForm/actions'
import { MessageTypes } from '../../../../../common/InfoPanel/components/infoPanel'
import { dispatchNavigateEvent, downloadSVC } from '../../../../../common/utils'

function* createStudy({ payload }: Object): GenType {
  if (payload.name === 'createStudy') {
    const winner = yield asyncAction(surveyActions.createStudyRequested(payload.data.name, payload.data.key))
    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))
      yield put(studiesActions.toggleCreateStudyDialog())

      const study: StudyDtoType = winner.succeeded.payload

      yield put(
        infoPanelActions.showMessage('studies.actions.createStudy.success', {
          study: study.name
        })
      )
      dispatchNavigateEvent(`/private/app/studies/${study.id}`)
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

function* addStudyParticipant({ payload }: Object): GenType {
  if (payload.name === 'addStudyParticipant') {
    const { patients, studyId, participantId, participantSubId } = payload.data
    const [patient] = patients
    const studyParticipant = {
      addedBy: getKeycloakClient().getUserId(),
      participantId,
      participantSubId,
      patientUuid: patient.uuid,
      studyId,
      userRef: patient.userRef
    }
    const winner = yield asyncAction(surveyActions.addStudyParticipantRequested(studyParticipant))
    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))

      const study: StudyDtoType = payload.data

      yield put(
        infoPanelActions.showMessage('studies.actions.addStudyParticipant.success', {
          study: study.name
        })
      )
      yield put(studiesActions.setAddStudyParticipantDialog(false))
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}

function* addStudyPhysican({ payload }: Object): GenType {
  if (payload.name === 'addStudyPhysicians') {
    const { physicians, studyId, currentPhysicians } = payload.data

    const currentPhysiciansIds = currentPhysicians.map(c => c.uid)
    const physiciansToAdd = physicians.filter(p => !currentPhysiciansIds.includes(p.uid))

    const winners = yield all(
      physiciansToAdd.map(p => asyncAction(surveyActions.addStudyPhysicianRequested(studyId, p.uid)))
    )
    const [failed] = winners.filter(w => w.failed)
    if (failed) {
      yield put(stopSubmit(payload.name, failed.failed.payload.error))
    } else {
      yield put(stopSubmit(payload.name))
      yield put(studiesActions.toggleAddPhysicianDialog())

      const userNames = physiciansToAdd.map(toString.person).join(', ')

      yield put(
        infoPanelActions.showMessage('studies.actions.addStudyPhysicians.success', {
          userNames
        })
      )
    }
  }
}

function* addStudyCoordinator({ payload }: Object): GenType {
  if (payload.name === 'addStudyCoordinator') {
    const { coordinators, study } = payload.data
    const [coordinator] = coordinators
    const winner = yield asyncAction(
      surveyActions.updateStudyRequested({
        ...study,
        coordinatorRef: coordinator.uid
      })
    )

    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))
      yield put(studiesActions.toggleAddStudyCoordinatorDialog())

      const userName = toString.person(coordinator)

      yield put(
        infoPanelActions.showMessage('studies.actions.addStudyCoordinator.success', {
          userName
        })
      )
    } else {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    }
  }
}
function* addStudyDevice({ payload }: Object): GenType {
  if (payload.name === 'addStudyDevice') {
    const { devices, study } = payload.data

    const winner = yield asyncAction(
      surveyActions.updateStudyRequested({
        ...study,
        devices
      })
    )

    if (winner.failed) {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    } else {
      yield put(stopSubmit(payload.name))
      yield put(studiesActions.setAddStudyDeviceDialog(false))

      const names = devices.map(d => d.name).join(', ')

      yield put(
        infoPanelActions.showMessage('studies.actions.addStudyDevice.success', {
          names
        })
      )
    }
  }
}

function* addParticipationCriteria({ payload }: Object): GenType {
  if (payload.name === 'addParticipationCriterias') {
    const { inclusions = '', exclusions = '', study } = payload.data

    const inclusionCriterias = inclusions
      .split(';')
      .map(item => item.trim())
      .filter(s => s.length > 0)
    const exclusionCriterias = exclusions
      .split(';')
      .map(item => item.trim())
      .filter(s => s.length > 0)

    study.studyParameters = { inclusionCriterias, exclusionCriterias }

    const winner = yield asyncAction(
      surveyActions.updateStudyRequested({
        ...study
      })
    )

    if (winner.failed) {
      yield put(stopSubmit(payload.name, winner.failed.payload.error))
    } else {
      yield put(stopSubmit(payload.name))
      yield put(studiesActions.setAddParticipationCriteriaDialog(false))

      yield put(infoPanelActions.showMessage('studies.actions.addParticipationCriterias.success'))
    }
  }
}

function getErrorData(action: Object) {
  const messageKey = path(['payload', 'error', 'response', 'data', 'message'], action)
  const params = path(['payload', 'error', 'response', 'data', 'params'], action)
  return { messageKey, params }
}

function* removeStudyPhysicianFailed(action: ExtractReturn<typeof surveyActions.removeStudyPhysicianFailed>): GenType {
  const { messageKey, params } = getErrorData(action)
  yield put(infoPanelActions.showMessage(messageKey, params, MessageTypes.error))
}

export function* downloadStudyData(action: ExtractReturn<typeof studiesActions.downloadStudyDataRequested>): GenType {
  try {
    const { id, name } = action.payload
    const result = yield call(api.downloadSurveyAnswers, id)
    const timeStamp = I18n.l(Date.now(), { dateFormat: 'date.stamp' })

    downloadSVC(`${name}_${timeStamp}.csv`, result.data)
    yield put(studiesActions.downloadStudyDataSucceeded(action.payload))
  } catch (error) {
    yield put(studiesActions.downloadStudyDataFailed(error))
  }
}

function* downloadSurveyAnswersRequested(
  action: ExtractReturn<typeof studiesActions.downloadSurveyAnswersRequested>
): GenType {
  try {
    const { studyId, surveyId } = action.payload
    const result = yield call(api.getSurveyAnswers, studyId, surveyId)
    const timeStamp = I18n.l(Date.now(), { dateFormat: 'date.stamp' })
    const fileName = I18n.t('surveys.actions.export.filename')
    downloadSVC(`${fileName}_${studyId}-${surveyId}-${timeStamp}.csv`, result.data)
    yield put(studiesActions.downloadSurveyAnswersSucceeded())
  } catch (error) {
    yield put(studiesActions.downloadSurveyAnswersFailed(error))
  }
}

function* deleteStudySucceeded(): GenType {
  yield put(infoPanelActions.showMessage('studies.actions.deleteStudy.success'))
  dispatchNavigateEvent('/private/app/studies')
}

export default function studiesSaga(): Array<GenType> {
  return [
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, createStudy),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, addStudyParticipant),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, addStudyPhysican),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, addStudyCoordinator),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, addStudyDevice),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, addParticipationCriteria),
    takeEvery(surveyActionTypes.REMOVE_STUDY_PHYSICIAN_FAILED, removeStudyPhysicianFailed),
    takeEvery(surveyActionTypes.DELETE_STUDY_SUCCEEDED, deleteStudySucceeded),
    takeEvery(studiesActionTypes.DOWNLOAD_STUDY_DATA_REQUESTED, downloadStudyData),
    takeEvery(studiesActionTypes.DOWNLOAD_SURVEYS_ANSWERS_REQUESTED, downloadSurveyAnswersRequested)
  ]
}
