// @flow
import { put, takeEvery, call, select, all } from 'redux-saga/effects'
import api from '../../../api'
import type { ExtractReturn, GenType, CommonStateType } from '../../../types'
import type { SubscriptionWithConversationDtoType } from '../types/subscriptionWithConversationDto'
import type { ConversationDtoType } from '../types/conversationDto'
import { messagingActions, messagingActionTypes } from '../actions'
import { getKeycloakClient } from '../../../common/Keycloak/keycloakProvider'
import { userActions } from '../../user/actions'
import { asyncAction } from '../../../utils/sagaUtils'

function* getSubscriptionsByConversation(
  action: ExtractReturn<typeof messagingActions.getSubsByConvRequested>
): GenType {
  try {
    const result = yield call(api.getSubscriptionsOfConversation, action.payload)
    const subscribtions = result.data
    yield put(messagingActions.getSubsByConvSucceeded(action.payload, subscribtions))
  } catch (error) {
    yield put(messagingActions.getSubsByConvFailed(error))
  }
}

type SubScriptionsType = Array<SubscriptionWithConversationDtoType>

function* getSubscriptions(action: ExtractReturn<typeof messagingActions.getSubscriptionsRequested>): GenType {
  try {
    const { filterSilentConversations, userRef, conversationId } = action.payload
    const result = yield call(api.getAllSubsWhereUserHasASub, filterSilentConversations, userRef)
    const loadedSubscriptions: SubScriptionsType = result.data
    yield put(messagingActions.getSubscriptionsSucceeded(loadedSubscriptions))
    if (conversationId) {
      yield put(messagingActions.selectConversation(Number(conversationId)))
    }
  } catch (error) {
    yield put(messagingActions.getSubscriptionsFailed(error))
  }
}

function* removeSubscription(action: ExtractReturn<typeof messagingActions.removeSubscriptionRequested>): GenType {
  try {
    const subId = action.payload
    yield call(api.deleteSubscription, subId)
    yield put(messagingActions.removeSubscriptionSucceeded(subId))
  } catch (error) {
    yield put(messagingActions.removeSubscriptionFailed(error))
  }
}

function* createConversation(action: ExtractReturn<typeof messagingActions.createConversationRequested>): GenType {
  try {
    const { title, userRefs, meta } = action.payload
    const result = yield call(api.createConversation, title, meta)
    const conversationDto: ConversationDtoType = result.data

    const ownSubscriptiondata = yield call(api.getSubscriptionsOfConversation, conversationDto.id)
    const [ownSubscription] = ownSubscriptiondata.data
    const responses = yield all(
      userRefs
        .filter(u => u !== getKeycloakClient().getUserId())
        .map(u => call(api.createSubscription, title, conversationDto.id, u))
    )

    const subscribtions = responses.map(r => r.data)
    subscribtions.push(ownSubscription)
    yield put(messagingActions.createConversationSucceeded(conversationDto, subscribtions))
    yield put(messagingActions.getSubsByConvRequested(conversationDto.id))
  } catch (error) {
    yield put(messagingActions.createConversationFailed(error))
  }
}

function* editConversation(action: ExtractReturn<typeof messagingActions.editConversationRequested>): GenType {
  try {
    const { conversationId, title, userRefs } = action.payload
    const conversation = yield select((state: CommonStateType) => state.messaging.conversations[conversationId])

    const subs = conversation.subscriptions

    const newUserIds = userRefs
    const currentUserIds = subs.map(s => s.userRef)
    const me = getKeycloakClient().getUserId()
    // remove subscriptions all except of the one patients one
    const subsToRemove = subs.filter(s => !newUserIds.includes(s.userRef) && s.userRef !== conversation.meta.userRef)
    yield all(subsToRemove.map(s => call(api.deleteSubscription, s.id)))

    // Add new subscriptions
    const userIdsWithoutSubscription = newUserIds.filter(id => !currentUserIds.includes(id) && id !== me)
    const responses = yield all(
      userIdsWithoutSubscription.map(userRef => call(api.createSubscription, title, conversationId, userRef))
    )

    yield put(userActions.loadMissingUsersRequested(newUserIds))

    const newSubs = responses.map(r => r.data)
    // update title of my subscription

    const mySub = subs.filter(s => s.userRef === me)[0]
    const currentTitle = mySub.title
    if (currentTitle !== title) {
      if (newUserIds.includes(me)) {
        yield call(api.updateSubscription, { ...mySub, title })
      }
    }

    if (window) {
      window.removedConversationId = conversationId
    }
    yield put(messagingActions.editConversationSucceeded(conversation, newSubs))
    yield put(messagingActions.getSubscriptionsRequested(undefined, undefined, conversationId))
  } catch (error) {
    yield put(messagingActions.editConversationFailed(error))
  }
}

function* updateCaseConversation(action: ExtractReturn<typeof messagingActions.updateCaseRequested>): GenType {
  try {
    const { conversationId, meta } = action.payload
    const conversation = yield select((state: CommonStateType) => state.messaging.conversations[conversationId])
    // const me = getKeycloakClient().getUserId()
    const { title } = conversation
    const updatedMeta = { ...conversation.meta, ...meta }

    const result = yield call(api.updateConversation, conversationId, title, updatedMeta)
    yield put(messagingActions.updateCaseSucceeded(result.data))
  } catch (error) {
    yield put(messagingActions.updateCaseFailed(error))
  }
}

type JoinPrimaryConvType = typeof messagingActions.joinPrimaryConversationRequested
function* joinPrimaryConversation(action: ExtractReturn<JoinPrimaryConvType>): GenType {
  try {
    const { userRef, healthcareSubject } = action.payload
    const result = yield call(api.joinPrimaryConversation, userRef, healthcareSubject)
    const { conversationId } = result.data

    if (window?.removedConversationId === conversationId) {
      window.removedConversationId = 0
    }
    // need to update the subscriptions for the joined conversation
    yield asyncAction(messagingActions.getSubscriptionsRequested(false, userRef))
    yield put(messagingActions.selectConversation(conversationId))
    yield put(messagingActions.joinPrimaryConversationSucceeded())
  } catch (error) {
    yield put(messagingActions.joinPrimaryConversationFailed(error))
  }
}

function* toggleMute(action: ExtractReturn<typeof messagingActions.toggleMuteRequested>): GenType {
  try {
    const { subscriptionId } = action.payload
    const result = yield call(api.getSubscription, subscriptionId)
    const { access, mute, id, owner, startWithMessage, title, userRef, conversation } = result.data
    yield call(api.updateSubscription, {
      conversationId: conversation.id,
      mute: !mute,
      access,
      id,
      owner,
      startWithMessage,
      title,
      userRef
    })
    yield put(messagingActions.toggleMuteSucceeded(subscriptionId, !mute))
  } catch (error) {
    yield put(messagingActions.toggleMuteFaield(error))
  }
}

function* assignConversationToMe(
  action: ExtractReturn<typeof messagingActions.assignConversationToMeRequested>
): GenType {
  try {
    const { conversationId } = action.payload
    yield call(api.assignConversationToMe, conversationId)
    yield put(messagingActions.assignConversationToMeSucceeded(conversationId))
  } catch (error) {
    yield put(messagingActions.assignConversationToMeFailed(error))
  }
}

type JoinCaseStreamType = typeof messagingActions.joinCaseStreamRequested
function* joinCaseStream(action: ExtractReturn<JoinCaseStreamType>): GenType {
  try {
    const { userRef, healthcareSubject, caseStreamId } = action.payload
    const result = yield call(api.joinCaseStreamConversation, userRef, healthcareSubject, caseStreamId)
    const { conversationId } = result.data
    // need to update the subscriptions for the joined conversation
    yield asyncAction(messagingActions.getSubscriptionsRequested(false, userRef))
    yield put(messagingActions.selectConversation(conversationId))
    yield put(messagingActions.joinCaseStreamSucceeded(conversationId))
  } catch (error) {
    yield put(messagingActions.joinCaseStreamFailed(error))
  }
}

export default function subscriptionsSaga(): Array<GenType> {
  return [
    takeEvery(messagingActionTypes.GET_SUBS_BY_CONV_REQUESTED, getSubscriptionsByConversation),
    takeEvery(messagingActionTypes.REMOVE_SUBSCRIPTION_REQUESTED, removeSubscription),
    takeEvery(messagingActionTypes.GET_SUBSCRIPTIONS_REQUESTED, getSubscriptions),
    takeEvery(messagingActionTypes.JOIN_PRIMARY_CONVERSATION_REQUESTED, joinPrimaryConversation),
    takeEvery(messagingActionTypes.TOGGLE_MUTE_REQUESTED, toggleMute),
    takeEvery(messagingActionTypes.CREATE_CONVERSATION_REQUESTED, createConversation),
    takeEvery(messagingActionTypes.EDIT_CONVERSATION_REQUESTED, editConversation),
    takeEvery(messagingActionTypes.ASSIGN_CONVERSATION_TO_ME_REQUESTED, assignConversationToMe),
    takeEvery(messagingActionTypes.UPDATE_CASE_REQUESTED, updateCaseConversation),
    takeEvery(messagingActionTypes.JOIN_CASESTREAM_REQUESTED, joinCaseStream)
  ]
}
