// @flow
import { put, takeEvery } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import type { GenType } from 'common-docdok/src/types'
import {
  messagingActionTypes,
  messagingActions
} from 'common-docdok/src/domain/messaging/actions'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import { isEmpty } from 'lodash'

import { infoPanelActions } from '../../../../../common/InfoPanel/actions'
import {
  dynamicFormActionTypes,
  type SubmitFormType
} from '../../../../../common/DynamicForm/actions'
import { catalogActions } from '../actions'

// -- APPEND GENERATORS HERE --

function* updateContentDefinitionSucceeded(): GenType {
  yield put(
    infoPanelActions.showMessage('catalog.updateContentDefinition.success')
  )
}

function* submitForm({ payload }: SubmitFormType): GenType {
  if (payload.name === 'sendContent') {
    const { content, conversations } = payload.data
    const contentIds = content.map((c) => c.contentDefinitionId)
    const conversationIds = conversations.map((c) => c.id)
    const winner = yield asyncAction(
      messagingActions.sendContentMessagesRequested(contentIds, conversationIds)
    )
    if (winner.succeeded) {
      yield put(stopSubmit(payload.name))
      yield put(catalogActions.setSendContentDialog([]))
      yield put(infoPanelActions.showMessage('catalog.sendContent.success'))
    } else {
      yield put(stopSubmit(payload.name, winner.failed.error))
    }
  }

  if (payload.name === 'createCatalog') {
    const { data } = payload
    const reqPayload = {
      title: data.title,
      language:
        Array.isArray(data.languages) && data.languages.length > 0
          ? data.languages[0]
          : data.languages || '',
      license: data.license || '',
      text: data.text,
      mimeType: data.fileType,
      originalSize: !isEmpty(data.files)
        ? data.files.file.size
        : data.originalSize,
      tags: data.tags
        ? Array.isArray(data.tags)
          ? data.tags
          : data.tags.split(';')
        : [],
      clinics: data.clinicId,
      studies: data.studyId,
      contentDefinitionId: data.contentDefinitionId
    }

    const winner = yield asyncAction(
      messagingActions.uploadContentDefinitionFileRequested(reqPayload)
    )

    if (winner.succeeded) {
      if (winner.succeeded.payload.id) {
        if (data.files) {
          yield put(
            messagingActions.uploadContentDefinitionGetFileURLRequested({
              contentDefinitionId: winner.succeeded.payload.id,
              file: {
                mimeType: data.files.file.type,
                size: data.files.file.size,
                arrayBuffer: data.files.bytes
              }
            })
          )
        }

        yield put(
          messagingActions.setContentDefinitionAccessRightsRequested(
            winner.succeeded.payload.id,
            {
              studies: reqPayload.studies || [],
              clinics: reqPayload.clinics || []
            }
          )
        )
      }

      if (!data.files) {
        yield put(stopSubmit(payload.name))
        yield put(catalogActions.toggleCreateCatalogDialog(false))
        yield put(
          infoPanelActions.showMessage('catalog.uploadContentDefinition.success')
        )
      }
    } else {
      yield put(stopSubmit(payload.name, winner.failed.error))
    }
  }
}

function* afterUploadDone(): GenType {
  yield put(stopSubmit('createCatalog'))
  yield put(catalogActions.toggleCreateCatalogDialog(false))
  yield put(
    infoPanelActions.showMessage('catalog.uploadContentDefinition.success')
  )
}

export default function catalogSaga() {
  return [
    // -- APPEND TAKES HERE --
    takeEvery(
      messagingActionTypes.UPDATE_CONTENT_DEFINITION_SUCCEEDED,
      updateContentDefinitionSucceeded
    ),
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, submitForm),
    takeEvery(
      messagingActionTypes.UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_SUCCEEDED,
      afterUploadDone
    ),
    takeEvery(
      messagingActionTypes.UPLOAD_CONTENT_DEFINITION_GET_FILE_URL_FAILED,
      afterUploadDone
    )
  ]
}
